import '../../styles/Settings.scss';

import {Button, TextField} from '@mui/material';
import {useContext, useEffect, useState} from 'react';
import {FileUploader} from 'react-drag-drop-files';
import {toast} from 'react-toastify';

import {
    getAdminUserRequest,
    updateUserInfoRequest,
    updateUserPasswordRequest,
} from '../../api/user.api';
import {UpdateUserPasswordRequestDTO} from '../../common/dtos/user.interface.dto';
import {LoaderContext} from "../../store/LoaderContex";
import {loaderHandler} from "../../utility/Helpers";
import {LoadingTypes} from "../../common/enums/common.enums";
import Loader from "../Loader/Loader";

const Settings = () => {
    const saveButtonText = "Sačuvaj";
    const loadingNewPasswordTitle = "Promena šifre...";
    const allFieldsMustBeFilledTitle = 'Sva polja moraju biti popunjena.';
    const updatingProfileTitle = 'Ažuriranje profila...';
    const fileTypes = ["JPG", "PNG"];
    const [file, setFile] = useState<File>();
    const [image, setImage] = useState<string>();
    const [changePasswordForm, toggleChangePasswordForm] = useState(false);
    const [fullName, setFullName] = useState<string | undefined>();
    const [email, setEmail] = useState<string | undefined>();
    const [currentPassword, setCurrentPassword] = useState<string>();
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
    const loadingCtx = useContext(LoaderContext);
    const handleChange = (selectedFile: File) => {
        setFile(selectedFile);
        setImage(URL.createObjectURL(selectedFile));
    };

    const getUser = async () => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
            const userId = await localStorage.getItem("userId");
            if (userId) {
                const response = await getAdminUserRequest(parseInt(userId));
                setFullName(response.fullName);
                setEmail(response.email);
                setImage(response.imageURL);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
        }
    };

    const updateUserPassword = async () => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.UPLOAD, true);
            const dto: UpdateUserPasswordRequestDTO = {
                oldPassword: currentPassword!,
                newPassword: newPassword!,
                confirmedPassword: confirmNewPassword!,
            };
            if (dto.newPassword.trim().length! < 1 && dto.confirmedPassword.trim().length! < 1) {
                toast.error(allFieldsMustBeFilledTitle);
                return;
            }
            if (dto.newPassword !== dto.confirmedPassword) {
                toast.error('Unešena lozinka i ponovljena lozinka se ne podudaraju');
                return;
            }
            await updateUserPasswordRequest(dto);
            toast.success('Lozinka je uspešno izmenjena.');
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.UPLOAD, false);
        }
    };

    const updateProfile = async () => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.UPLOAD, true);
            const userId = await localStorage.getItem('userId');
            const formData = new FormData();
            formData.append('fileTypes', '[{"type":"image"}]');
            formData.append('fullName', fullName!);
            if (fullName?.length! < 1) {
                toast.error(allFieldsMustBeFilledTitle);
                return;
            }
            if (file) {
                formData.append('files', file!);
            }
            if (userId) {
                await updateUserInfoRequest(parseInt(userId), formData);
                toast.success('Profil korisnika je uspešno ažuriran.');
                if (file) {
                    localStorage.setItem('avatarUrl', URL.createObjectURL(file));
                }
            }
        } catch (error: any) {
            toast.error(error.response.data.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.UPLOAD, false);
        }
    };

    const onClickSaveAction = async () => {
        if (changePasswordForm) {
            await updateUserPassword();
        } else {
            await updateProfile();
        }
    };
    const uploadingButtonText = changePasswordForm ? loadingNewPasswordTitle : updatingProfileTitle;

    useEffect(() => {
        getUser();
    }, []);

    return (!loadingCtx.isLoading.common ?
            (<div className={'add-settings-card-container'}>
                <div className={'header-container'}>
                    <h1 className={'add-settings-name'}>Profil</h1>
                </div>
                <div className={'card-container'}>
                    <h2 className={'add-settings-subtitle'}>Osnovni podaci</h2>
                    <div className={'form-container'}>
                        <div className={'profile-image-container'}>
                            {!loadingCtx.isLoading.upload ?
                                <img src={image ? image : '/assets/avatar-img.png'} alt={fullName}
                                /> : <Loader moreMessageLoader={true}/>}
                            <FileUploader
                                handleChange={handleChange}
                                name="file"
                                types={fileTypes}
                                children={<h3 className={'link'}>Promeni sliku</h3>}
                            />
                        </div>
                        <TextField
                            label={'Ime i prezime'}
                            className={'form-text-input'}
                            value={fullName ? fullName : ''}
                            onChange={event => setFullName(event.target.value)}
                            required={true}
                        />
                        <TextField
                            label={'Email'}
                            className={'form-text-input'}
                            value={email ? email : ''}
                            disabled={true}
                            required={true}
                        />
                        <h3
                            className={'link'}
                            onClick={toggleChangePasswordForm.bind(
                                this,
                                prevState => !prevState,
                            )}>
                            Promeni šifru
                        </h3>
                    </div>
                </div>
                {/* change password form */}
                {changePasswordForm && (
                    <div className={'card-container'}>
                        <h2 className={'add-settings-subtitle'}>Promeni šifru</h2>
                        <div className={'form-container'}>
                            <TextField
                                label={'Trenutna šifra'}
                                type={'password'}
                                className={'form-text-input'}
                                value={currentPassword}
                                onChange={event => setCurrentPassword(event.target.value)}
                                required={true}
                            />
                            <TextField
                                label={'Nova šifra'}
                                type={'password'}
                                className={'form-text-input'}
                                value={newPassword}
                                onChange={event => setNewPassword(event.target.value)}
                                required={true}
                            />
                            <TextField
                                label={'Ponovo unesi novu šifru'}
                                type={'password'}
                                className={'form-text-input'}
                                value={confirmNewPassword}
                                onChange={event => setConfirmNewPassword(event.target.value)}
                                required={true}
                            />
                        </div>
                    </div>
                )}
                <div className={'button-container'}>
                    <Button className={'button-primary'} onClick={onClickSaveAction}
                            disabled={loadingCtx.isLoading.upload}>
                        {!loadingCtx.isLoading.upload ? saveButtonText : uploadingButtonText}
                    </Button>
                </div>
            </div>) : <Loader/>
    );
};


export default Settings;
