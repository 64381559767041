import '../../styles/NotificationStyle.scss';

import React from 'react';

import {Notification} from '../../common/models/notification.interface';

type NotificationProps = {
    notification: Notification;
    isRead: boolean;
    onClick: () => void;
};

const NotificationItem: React.FC<NotificationProps> = (
    props: NotificationProps
) => {
    return (
        <div className={"notification"} onClick={props.onClick}>
            <div className={"row"}>
                <div className={"notification-container"}>
                    <div>
                        <img
                            src={
                                props.notification.imageUrl
                                    ? props.notification.imageUrl
                                    : "/assets/avatar-img.png"
                            }
                            alt={""}
                        />
                    </div>
                    <div className={"column"}>
                        <h1>{props.notification.fullName}</h1>
                        <h2 className={"notification-text"}>{props.notification.text}</h2>
                    </div>
                </div>
                {!props.isRead && <div className={"is-read"}/>}
            </div>
            <div className={"borderBottom"}></div>
        </div>
    );
};

export default NotificationItem;
