import React, {ChangeEvent, Dispatch, SetStateAction, useState} from 'react';
import type {Identifier, XYCoord} from 'dnd-core';
import {useRef} from 'react';
import {DropTargetMonitor, useDrag, useDrop} from 'react-dnd';
import '../../styles/DraggableComponentStyle.scss';

import {Autocomplete, TextField} from '@mui/material';
import {isMobile} from 'react-device-detect';

import {MealType} from '../../common/models/food.interface';
import {SelectedExercises} from '../../common/models/training.interface';
import {DraggableComponentInterface} from './DraggableContainer';
import {Types} from '../../utility/Helpers';

const style = {
  width: isMobile ? '100%' : '30vw',
  margin: isMobile ? '10px 0 15px 0' : '1vw 0',
};

export interface DraggableComponentProps {
  id: number;
  text: string;
  children?: JSX.Element;
  index: number;
  moveComponent: (dragIndex: number, hoverIndex: number) => void;
  removeItem: (value: DraggableComponentInterface) => void;
  isExercise?: boolean;
  handleForm?: (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      param: string,
      index: number,
  ) => void;
  setSelectedExercise?: Dispatch<SetStateAction<SelectedExercises[]>>;
  draggableComponent: DraggableComponentInterface;
  type?: string;
  mealTypes?: MealType[];
  setDraggableComponent?: Dispatch<
      SetStateAction<DraggableComponentInterface[]>
  >;
  showModal?: boolean;
  days?: string[];
  placeholder?: string;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

interface Days {
  id: number;
  label: string;
  type: string
}

const DraggableComponent: React.FC<DraggableComponentProps> = (
    props: DraggableComponentProps,
) => {
  const days: Days[] = [
    {id: 1, label: 'Dan 1', type: 'recipe'},
    {id: 2, label: 'Dan 2', type: 'recipe'},
    {id: 3, label: 'Dan 3', type: 'recipe'},
    {id: 4, label: 'Dan 4', type: 'recipe'},
    {id: 5, label: 'Dan 5', type: 'recipe'},
    {id: 6, label: 'Dan 6', type: 'recipe'},
    {id: 7, label: 'Dan 7', type: 'recipe'},
  ];

  const ref = useRef<HTMLDivElement>(null);

  const [showModal, toggleModal] = useState(true);

  const dragItemObject = {
    accept: 'draggable-component',
    collect(monitor: DropTargetMonitor<DragItem, void>) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor<DragItem, void>) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = props.index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      props.moveComponent(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
      toggleModal(false);
    },
  };

  const isDraggingObject = {
    type: 'draggable-component',
    item: () => {
      return {id: props.id, index: props.index};
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  };

  const [{handlerId}, drop] = useDrop<
      DragItem,
      void,
      { handlerId: Identifier | null }
  >(dragItemObject);

  const [{isDragging}, drag] = useDrag(isDraggingObject);
  const initialMealTypeValue = props.mealTypes?.find(
      mealType => mealType.id === props.draggableComponent.mealTypeId,
  );

  const initialDay = days.find(
      day => day.label === props.draggableComponent.day,
  );

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
      <div
          className={
            props.mealTypes && props.type === Types.RECIPE && !props.placeholder
                ? 'autocomplete-wrapper'
                : ''
          }
          ref={ref}
          data-handler-id={handlerId}>
        <div
            style={{...style, opacity}}
            className={'draggable-component-container'}
            onClick={toggleModal.bind(this, prevState => !prevState)}
            onDrag={toggleModal.bind(this, false)}
            draggable={true}>
          <div className={'draggable-component-wrapper'}>
            <div className={'text-container'}>
              <img className={'icon'} src={'/assets/burger-icon.svg'} alt={''}/>
              <p className={'text'}>{props.text}</p>
            </div>
            <img
                className={'icon'}
                src={'/assets/close-icon.svg'}
                alt={''}
                onClick={props.removeItem.bind(this, {
                  id: props.id,
                  label: props.text,
                  type: props.type,
                })}
            />
          </div>
        </div>
        {props.children}
        {showModal && props.isExercise && (
            <div className={'training-row-wrapper'}>
              <TextField
                  label={'Ponavljanja'}
                  type={'number'}
                  onChange={event => {
                    props.draggableComponent!.repeats = event.target.value;
                  }}
                  inputProps={{min: 0}}
                  required={true}
                  defaultValue={props.draggableComponent?.repeats}
                  className={'form-text-input-sm-3'}
              />
              <TextField
                  label={'Serije'}
                  defaultValue={props.draggableComponent?.sets}
                  type={'number'}
                  onChange={event => {
                    props.draggableComponent!.sets = event.target.value;
                  }}
                  inputProps={{min: 0}}
                  required={true}
                  className={'form-text-input-sm-3'}
              />
              <TextField
                  label={'Odmor (sek)'}
                  type={'number'}
                  defaultValue={props.draggableComponent?.timeBreak}
                  onChange={event => {
                    props.draggableComponent!.timeBreak! = event.target.value;
                  }}
                  required={true}
                  inputProps={{min: 0}}
                  className={'form-text-input-sm-3'}
              />
            </div>
        )}
        {showModal &&
            props.mealTypes &&
            props.mealTypes.length > 0 &&
            props.type === 'recipe' && (
                <div className={'autocomplete-wrapper-detail'}>
                  <Autocomplete
                      defaultValue={initialMealTypeValue}
                      disablePortal
                      className={'autocomplete-box'}
                      id="combo-box-demo"
                      options={props.mealTypes}
                      getOptionLabel={option => option.label}
                      onChange={(event, value) =>
                          props.setDraggableComponent &&
                          props.setDraggableComponent(prevState => {
                            prevState[props.index].mealTypeId = value ? value.id : -1;
                            return prevState;
                          })
                      }
                      sx={{width: '14.7vw'}}
                      renderInput={params => (
                          <TextField {...params} label="Tip obroka"/>
                      )}
                  />
                  <Autocomplete
                      disablePortal
                      options={days}
                      getOptionLabel={option => option.label}
                      onChange={(event, value) =>
                          props.setDraggableComponent &&
                          props.setDraggableComponent(prevState => {
                            prevState[props.index].day = value ? value.label : '';
                            return prevState;
                          })
                      }
                      defaultValue={initialDay}
                      sx={{width: '15vw'}}
                      renderInput={params => <TextField {...params} label="Dan"/>}
                  />
                </div>
            )}
      </div>
  );
};

export default DraggableComponent;
