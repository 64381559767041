import {Dispatch, SetStateAction} from 'react';
import {FoodPlan, FoodRecipe} from '../common/models/food.interface';
import {
    Exercise,
    Training,
    TrainingPlan,
    TrainingPlanCategory,
} from '../common/models/training.interface';
import {ClientInterface} from '../common/models/user.interface';
import {FilterCategory} from '../components/Food/Food';
import {RecipeInterface} from '../components/Food/Recipes';
import {TrainingPlanInterface} from '../components/Trainings/TrainingPlans';
import {PackageFormInterface} from "../common/models/subscription.interface";
import {toast} from "react-toastify";
import {
    allFieldsAreRequiredAndValueError,
    allFieldsAreRequiredError
} from "../common/constants/Constants";
import {LoaderContextInterface} from "../common/models/context.interface";

export enum Types {
    TRAINING = 'training',
    TRAINING_TYPE = 'trainingType',
    FOOD = 'food',
    EXERCISE = 'exercie',
    RECIPE = 'recipe',
    PACKAGE = 'package',
    MEAL_TYPE = 'mealType',
    CATEGORY = 'category',
    STATUS = 'status',
    ISUNIVERSAL = 'isUniversal',
    SUBSCRIPTIONS = 'subscriptions',
    TRAINING_HISTORY = 'trainingHistory',
    WEIGHT_HISTORY = 'weightHistory',
    FOOD_PLAN = 'foodPLan',
    TRAINING_PLAN = 'trainingPlan'
}

const getColumn = (
    element:
        | FoodPlan
        | TrainingPlanInterface
        | FoodRecipe
        | Training
        | Exercise
        | TrainingPlanCategory
        | TrainingPlan
        | PackageFormInterface,
    filterType: string,
) => {
    switch (filterType) {
        case Types.TRAINING_TYPE:
            if (Types.TRAINING_TYPE in element) {
                return element.trainingType?.name;
            }
            break;
        case Types.MEAL_TYPE:
            if (Types.MEAL_TYPE in element) return element.mealType?.label;
            break;
        case Types.PACKAGE:
            if (Types.PACKAGE in element) return element.package;
            break;
        case Types.ISUNIVERSAL:
            if (Types.ISUNIVERSAL in element) {
                return element.isUniversal;
            }
            break;
        case Types.CATEGORY:
            if (Types.CATEGORY in element) return element.category;
            break;
        case Types.TRAINING:
            if (Types.CATEGORY in element && 'numOfTrainings' in element) {
                return element.category.label;
            }
            break;
        case Types.STATUS:
            if (Types.STATUS in element) return element.status;
            break;
        default:
            return;
    }
};

// TODO - find a way to remove any and add type to the SetStateAction and rows params

export const addFilters = async (
    filters: FilterCategory[],
    setStateAction: Dispatch<SetStateAction<any[]>>,
    immutableArray: (
        | FoodPlan
        | TrainingPlanInterface
        | RecipeInterface
        | Training
        | Exercise
        | ClientInterface
        | TrainingPlanCategory | PackageFormInterface
        )[],
    rows: any[],
) => {
    const filterTypes: string[] = [];
    if (filters.length > 0) {
        await setStateAction([]);
        for (const filter of filters) {
            filterTypes.push(filter.type);
            if (
                filterTypes.some(type => {
                    return type !== filter.type;
                })
            ) {
                await setStateAction(prevState => [
                    ...prevState.filter(
                        (element: FoodPlan | TrainingPlanInterface) =>
                            getColumn(element, filter.type) === filter.name,
                    ),
                ]);
            } else {
                await setStateAction(prevState => [
                    ...prevState,
                    ...immutableArray.filter(
                        (
                            element:
                                | FoodPlan
                                | TrainingPlanInterface
                                | RecipeInterface
                                | Training
                                | Exercise
                                | TrainingPlanCategory
                                | PackageFormInterface
                        ) => getColumn(element, filter.type) === filter.name,
                    ),
                ]);
            }
        }
    } else {
        setStateAction(immutableArray);
    }
};

export const loaderHandler = (loadingCtx: LoaderContextInterface, param: string, isLoading: boolean) => {
    loadingCtx.toggleIsLoading(prevState => {
        return {
            ...prevState,
            [param]: isLoading,
        }
    });
}

export const isFormValid = (isValidForm: boolean) => {
    if (!isValidForm) {
        toast.error(allFieldsAreRequiredError);
        return;
    }
    return isValidForm;
}

export const isPositiveValueForm = (isValidForm: boolean) => {
    if (!isValidForm) {
        toast.error(allFieldsAreRequiredAndValueError);
        return
    }
    return isValidForm;
}

export const dateTransformer = (date: string | undefined): string => {
    if (date) {
        const newDate = new Date(date);
        return (
            newDate.getDate() +
            '.' +
            (newDate.getMonth() + 1) +
            '.' +
            newDate.getFullYear()
        );
    }
    return '/';
};

export const dateTransformerForChat = (date: string | undefined): string => {
    if (date) {
        const newDate = new Date(date);
        return (
            newDate.getDate() +
            '.' +
            (newDate.getMonth() + 1) +
            '.' +
            newDate.getFullYear() +
            ', ' +
            newDate.getHours() +
            ':' +
            ((newDate.getMinutes() < 10 ? '0' : '') + newDate.getMinutes())
        );
    }
    return '/';
};

export const dateTransformerForTrainingHistory = (date: string | undefined): string => {
    if (date) {
        const newDate = new Date(date);
        return (
            newDate.getDate() +
            '.' +
            (newDate.getMonth() + 1) +
            '.' +
            newDate.getFullYear() + ". " +
            newDate.getHours() + ":" + newDate.getMinutes()
        );
    }
    return '/';
}

export enum Mode {
    ADD = 'ADD',
    EDIT = 'EDIT',
}

export enum IsUniversal {
    UNIVERSAL = 'Univerzalan',
    NOT_UNIVERSAL = 'Nije univerzalan',
}

export const dateTransformerForCreatingSubscription = (
    date: string,
): string => {
    const currentTime =
        new Date()
            .toLocaleTimeString('sr-RS', {
                timeZone: 'Etc/GMT+1',
                timeZoneName: 'short',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            })
            .split(' ')[0] + '.000Z';
    return date + `T${currentTime}`;
};

export const LoggedIn = 'loggedIn';

export const MealTypeString = 'mealType';
export const VERIFIED = 'VERIFIED';

export enum isVerified {
    VERIFIED = 'Verifikovan',
    UNVERIFIED = 'Nije verifikovan',
}

export enum ScreenNames {
    EXERCISE = 'exercise',
    CHAT = 'chat',
    SETTINGS = 'settings',
    FOOD = 'food',
    DASHBOARD = 'dashboard',
    CLIENTS = 'clients',
    RECIPES = 'recipes',
    RECIPE = 'recipe',
    EXERCISES = 'exercises',
    TRAINING = 'training',
    TRAININGS = 'trainings',
    TRAINING_PLAN = 'training-plan',
    TRAINING_PLANS = 'training-plans',
    PACKAGES = 'packages',
    PACKAGE = 'package',
    FOOD_PLAN = 'food-plan',
}

export function isWithinLastSevenDays(date: Date): boolean {
    const today = new Date();
    const sevenDaysAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6);
    return date >= sevenDaysAgo && date <= today;
}

export function dateTransformerForSubscription(date: string) {
    return date ? date.split('T')[0] : '/';
}


export function setItemToDeleteHelper(setOpenModal: Dispatch<SetStateAction<boolean>>, setItemToDeleteID: Dispatch<SetStateAction<number>>, itemId: number) {
    setOpenModal(true);
    setItemToDeleteID(itemId)
}

export const capitalizeLetters = (value: string): string => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
