import {Button, TableContainer, TextField} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, {Dispatch, SetStateAction, useEffect, useState, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

import {getSubscriptionRequest} from '../../api/subscription.api';
import {getFinishedTrainingsRequest} from '../../api/training.api';
import {getUserRequest, getWeightProgressRequest} from '../../api/user.api';
import {
    fromDailyActivityResponseDTOToDailyActivity,
} from '../../common/data-tansformers/input/dailyActivityInputDataTransformer';
import {
    fromSubscriptionResponseDTOToSubscriptionTable,
} from '../../common/data-tansformers/input/subscriptionInputDataTransformer';
import {
    fromTrainingDiaryResponseDTOToTrainingDiary,
} from '../../common/data-tansformers/input/trainingInputDataTransformer';
import {
    fromUserResponseDTOToUserDetail,
} from '../../common/data-tansformers/input/userInputDataTransformer';
import {DailyActivity} from '../../common/models/dailyActivityInterface';
import {Subscription} from '../../common/models/subscription.interface';
import {TrainingDiary} from '../../common/models/training.interface';
import {ClientInterface, User} from '../../common/models/user.interface';
import {dateTransformer, dateTransformerForTrainingHistory, loaderHandler, Mode, Types} from '../../utility/Helpers';
import BackArrow from '../UI/BackArrow';
import {LoaderContext} from "../../store/LoaderContex";
import {LoadingTypes} from "../../common/enums/common.enums";
import Loader from "../Loader/Loader";

type Props = {
    clients?: ClientInterface[];
    setClientSection: Dispatch<SetStateAction<string>>;
    clientId: number | undefined;
    setClientFullName: Dispatch<SetStateAction<string | undefined>>;
    setSubscriptionId: Dispatch<SetStateAction<number>>;
};

const EditClient: React.FC<Props> = (props: Props) => {
    enum subscriptionStatusEnum {
        PROCESSING = "obrada",
        REGISTRED = "proknjižena",
    }

    const loadingCtx = useContext(LoaderContext);
    const navigateToChatPath = "/chat";
    const sendMessageLabel = "Pošalji poruku";
    const basicInfoLabel = "Osnovni podaci";
    const [user, setUser] = useState<User>({} as User);
    const [subscription, setSubscription] = useState<Subscription[]>([]);
    const [trainingHistory, setTrainingHistory] = useState<TrainingDiary[]>();
    const [weightProgress, setWeightProgress] = useState<DailyActivity[]>();
    const [activeMenu, setActiveMenu] = useState<string>(Types.PACKAGE);
    const navigate = useNavigate();

    const getClient = async () => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
            if (!props.clientId) {
                return;
            }
            const user = await getUserRequest(props.clientId);
            setUser(fromUserResponseDTOToUserDetail(user));
            const subscriptionResponse = await getSubscriptionRequest(props.clientId);
            setSubscription(
                fromSubscriptionResponseDTOToSubscriptionTable(subscriptionResponse)
            );
            props.setClientFullName(fromUserResponseDTOToUserDetail(user).fullName);
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
        }
    };

    const getClientFinishedTrainings = async () => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.CLIENT_PROGRESS, true);
            const response = await getFinishedTrainingsRequest(props.clientId!);
            setTrainingHistory(fromTrainingDiaryResponseDTOToTrainingDiary(response));
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.CLIENT_PROGRESS, false);
        }
    };

    const getClientWeightProgress = async () => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.CLIENT_PROGRESS, true);
            const response = await getWeightProgressRequest(props.clientId!);
            setWeightProgress(fromDailyActivityResponseDTOToDailyActivity(response));
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.CLIENT_PROGRESS, false);
        }
    };

    const packageTableTabClass =
        activeMenu === Types.PACKAGE ? "table-tab-active" : "table-tab-inactive";
    const subscriptionTableTabClass =
        activeMenu === Types.SUBSCRIPTIONS
            ? "table-tab-active"
            : "table-tab-inactive";
    const trainingHistoryTableTabClass =
        activeMenu === Types.TRAINING_HISTORY
            ? "table-tab-active"
            : "table-tab-inactive";
    const weightHistoryTableTabClass =
        activeMenu === Types.WEIGHT_HISTORY
            ? "table-tab-active"
            : "table-tab-inactive";
    const getPackageStatusLabelColor = (subscriptionStatus: string) => {
        if (subscriptionStatus)
            return subscriptionStatus.toLowerCase() ===
            subscriptionStatusEnum.REGISTRED
                ? "#00BB83"
                : subscriptionStatus.toLowerCase() === subscriptionStatusEnum.PROCESSING
                    ? "#111726 "
                    : "#D34747";
    };

    useEffect(() => {
        getClient();
        getClientWeightProgress();
    }, [props.clientId]); // eslint-disable-line react-hooks/exhaustive-deps

    return (!loadingCtx.isLoading.common ?
            (<div className={'client-card-container'}>
                {user && (
                    <>
                        <div className={'header-container'}>
                            <div className={'page-name-container'}>
                                <BackArrow
                                    onClick={() => {
                                        props.setClientSection('clients-table');
                                    }}
                                />
                                <h1 className={'client-name'}>{user.fullName}</h1>
                            </div>
                            <Button className={'open-chat-button'}
                                    onClick={() => navigate(navigateToChatPath, {state: {chatId: user.chatId}})}>{sendMessageLabel}</Button>
                        </div>
                        <div className={'card-container'}>
                            <h2 className={'client-subtitle'}>{basicInfoLabel}</h2>
                            <div className={'form-container'}>
                                <div className={'form-container-row'}>
                                    {user.fullName && (
                                        <TextField
                                            label={'Ime i prezime'}
                                            disabled={true}
                                            value={user.fullName}
                                            className={'full-name-form-text-input'}
                                        />
                                    )}
                                    {user.email && (
                                        <TextField
                                            label={'Email'}
                                            disabled={true}
                                            value={user.email}
                                            className={'form-text-input-medium'}
                                        />
                                    )}
                                </div>
                                <div className={'form-container-row'}>
                                    {user.dateOfBirth && (
                                        <TextField
                                            label={'Datum rođenja'}
                                            disabled={true}
                                            value={dateTransformer(
                                                new Date(user.dateOfBirth).toISOString(),
                                            )}
                                            className={'form-text-input-small'}
                                        />
                                    )}
                                    {user.registrationDate && (
                                        <TextField
                                            label={'Datum registracije'}
                                            disabled={true}
                                            value={dateTransformer(
                                                new Date(user.registrationDate).toISOString(),
                                            )}
                                            className={'form-text-input-small'}
                                        />
                                    )}
                                    <TextField
                                        label={'Visina (cm)'}
                                        disabled={true}
                                        value={user.height ? user.height : '/'}
                                        className={'form-height-text-input'}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* second form */}
                        <div className={'small-form-container'}>
                            <h2 className={'client-subtitle'}>Težina</h2>
                            <div className={'weight-text-fields-container'}>
                                <TextField
                                    label={'Početna (kg)'}
                                    value={user.startingWeight ? user.startingWeight : '/'}
                                    className={'weight-text-field'}
                                    disabled={true}
                                />

                                <TextField
                                    label={"Trenutna (kg)"}
                                    value={weightProgress ? weightProgress[weightProgress?.length - 1]?.dailyWeight : '/'}
                                    className={"weight-text-field"}
                                    disabled={true}
                                />

                                <TextField
                                    label={"Ciljna (kg)"}
                                    value={user.goalWeight ? user.goalWeight : "/"}
                                    className={"weight-text-field"}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div className={"table-container"}>
                            <div className={"table-tab-container"}>
                                <h3
                                    className={packageTableTabClass}
                                    onClick={() => setActiveMenu(Types.PACKAGE)}
                                >
                                    Paketi
                                </h3>
                                <h3
                                    className={subscriptionTableTabClass}
                                    onClick={() => setActiveMenu(Types.SUBSCRIPTIONS)}
                                >
                                    Istorija plaćanja
                                </h3>
                                <h3
                                    className={trainingHistoryTableTabClass}
                                    onClick={async () => {
                                        setActiveMenu(Types.TRAINING_HISTORY);
                                        await getClientFinishedTrainings();
                                    }}
                                >
                                    Istorija treninga
                                </h3>
                                <h3
                                    className={weightHistoryTableTabClass}
                                    onClick={async () => {
                                        setActiveMenu(Types.WEIGHT_HISTORY);
                                    }}
                                >
                                    Unos težine
                                </h3>
                            </div>
                            <TableContainer className={"table-wrapper"}>
                                {!loadingCtx.isLoading.clientProgress ? <Table size="small" className={"table"}>
                                    <TableHead>
                                        <TableRow className={"table-row"}>
                                            {activeMenu === Types.PACKAGE && (
                                                <>
                                                    <TableCell className={"table-cell-header"}>
                                                        NAZIV PAKETA
                                                    </TableCell>
                                                    <TableCell className={"table-cell-header"}>
                                                        DATUM OD
                                                    </TableCell>
                                                    <TableCell className={"table-cell-header"}>
                                                        DATUM DO
                                                    </TableCell>
                                                    <TableCell className={"table-cell-header"}>
                                                        STATUS
                                                    </TableCell>
                                                </>
                                            )}
                                            {activeMenu === Types.SUBSCRIPTIONS && (
                                                <>
                                                    <TableCell className={"table-cell-header"}>
                                                        NAZIV PAKETA
                                                    </TableCell>
                                                    <TableCell className={"table-cell-header"}>
                                                        DATUM UPLATE
                                                    </TableCell>
                                                    <TableCell className={"table-cell-header"}>
                                                        IZNOS
                                                    </TableCell>
                                                    <TableCell className={"table-cell-header"}>
                                                        KOMENTAR
                                                    </TableCell>
                                                </>
                                            )}
                                            {activeMenu === Types.TRAINING_HISTORY && (
                                                <>
                                                    <TableCell className={"table-cell-header"}>
                                                        NAZIV TRENINGA
                                                    </TableCell>
                                                    <TableCell className={"table-cell-header"}>
                                                        DATUM I VREME
                                                    </TableCell>
                                                    <TableCell className={"table-cell-header"}>
                                                        OCENA
                                                    </TableCell>
                                                    <TableCell className={"table-cell-header"}>
                                                        KOMENTAR
                                                    </TableCell>
                                                </>
                                            )}
                                            {activeMenu === Types.WEIGHT_HISTORY && (
                                                <>
                                                    <TableCell className={"table-cell-header"}>
                                                        DATUM
                                                    </TableCell>
                                                    <TableCell className={"table-cell-header"}>
                                                        VREME
                                                    </TableCell>
                                                    <TableCell className={"table-cell-header"}>
                                                        TEŽINA
                                                    </TableCell>
                                                </>
                                            )}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {activeMenu === Types.SUBSCRIPTIONS &&
                                            subscription &&
                                            subscription.map((subscription) => (
                                                subscription.status?.toLowerCase() === subscriptionStatusEnum.REGISTRED && (
                                                    <TableRow className={"table-row"} key={subscription.id}>
                                                        <TableCell className={"table-cell-data"}>
                                                            {subscription.subscriptionPackage?.name}
                                                        </TableCell>
                                                        <TableCell className={"table-cell-data"}>
                                                            {subscription.paymentDate
                                                                ? dateTransformer(
                                                                    new Date(subscription.paymentDate).toISOString()
                                                                )
                                                                : "/"}
                                                        </TableCell>
                                                        <TableCell className={"table-cell-data"}>
                                                            {subscription.amount} RSD
                                                        </TableCell>
                                                        <TableCell className={"table-cell-data"}>
                                                            {subscription.additionalComment}
                                                        </TableCell>
                                                    </TableRow>)
                                            ))}
                                        {activeMenu === Types.PACKAGE &&
                                            subscription &&
                                            subscription.map((subscription) => subscription?.subscriptionPackage && (
                                                <TableRow
                                                    className={"table-row"}
                                                    key={subscription.id}
                                                    onClick={() => {
                                                        props.setClientSection("edit-package");
                                                        props.setSubscriptionId(subscription.id!);
                                                    }}
                                                >
                                                    <TableCell className={"table-cell-data"}>
                                                        {subscription.subscriptionPackage?.name}
                                                    </TableCell>
                                                    <TableCell className={"table-cell-data"}>
                                                        {dateTransformer(
                                                            new Date(subscription.dateFrom).toISOString()
                                                        )}
                                                    </TableCell>
                                                    <TableCell className={"table-cell-data"}>
                                                        {dateTransformer(
                                                            new Date(subscription.dateTo).toISOString()
                                                        )}
                                                    </TableCell>
                                                    <TableCell
                                                        className={"table-cell-data"}
                                                        style={{
                                                            color: getPackageStatusLabelColor(
                                                                subscription.status!
                                                            ),
                                                        }}
                                                    >
                                                        {subscription.status?.toUpperCase()}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        {activeMenu === Types.TRAINING_HISTORY &&
                                            trainingHistory &&
                                            trainingHistory.map((training) => (
                                                <TableRow className={"table-row"} key={training.id}>
                                                    <TableCell className={"table-cell-data"}>
                                                        {training.name}
                                                    </TableCell>
                                                    <TableCell className={"table-cell-data"}>
                                                        {dateTransformerForTrainingHistory(
                                                            new Date(training.date).toISOString()
                                                        )}
                                                    </TableCell>
                                                    <TableCell className={"table-cell-data"}>
                                                        {training.rating}
                                                    </TableCell>
                                                    <TableCell className={"table-cell-data"}>
                                                        {training.comment}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        {activeMenu === Types.WEIGHT_HISTORY &&
                                            weightProgress &&
                                            weightProgress.map((weight) => (
                                                <TableRow className={"table-row"} key={weight.id}>
                                                    <TableCell className={"table-cell-data"}>
                                                        {dateTransformer(new Date(weight.date).toISOString())}
                                                    </TableCell>
                                                    <TableCell className={"table-cell-data"}>
                                                        {new Date(weight.date)
                                                            .toISOString()
                                                            ?.split("T")[1]
                                                            .substring(0, 5)}
                                                    </TableCell>
                                                    <TableCell className={"table-cell-data"}>
                                                        {weight.dailyWeight}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table> : (activeMenu === Types.WEIGHT_HISTORY || activeMenu === Types.TRAINING_HISTORY) &&
                                    <Loader clientInfoTable={true}/>}
                            </TableContainer>
                        </div>
                        <div className={"button-container"}>
                            <Button
                                className={"button-primary"}
                                onClick={props.setClientSection.bind(this, "add-new-package")}
                            >
                                Dodaj novu pretplatu
                            </Button>
                        </div>
                    </>
                )}
            </div>) : <Loader/>
    );
};

export default EditClient;
