import '../../styles/TrainingStyle.scss';
import 'react-toastify/dist/ReactToastify.css';

import {Autocomplete, Button, TextareaAutosize, TextField} from '@mui/material';
import React, {ChangeEvent, useContext, useEffect, useState} from 'react';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {FileUploader} from 'react-drag-drop-files';
import {useLocation, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

import {
  getAllTrainingPlanCategories,
  getAllTrainingsRequest,
  getTrainingPlanDetailsRequest,
  insertTrainingPlan,
  updateTrainingPlan,
} from '../../api/training.api';
import {
  fromSingleTrainingPlanResponseDTOToTrainingPlan,
  fromTrainingPlanCategoryDtoToTrainingPlanCategory,
  fromTrainingResponseDTOToTraining,
} from '../../common/data-tansformers/input/trainingInputDataTransformer';
import {
  TrainingPlan,
  TrainingPlanCategory,
} from '../../common/models/training.interface';
import {isFormValid, loaderHandler, Mode} from '../../utility/Helpers';
import {CategoryInterface} from '../Food/AddFoodPlan';
import BackArrow from '../UI/BackArrow';
import DraggableContainer, {
  DraggableComponentInterface,
} from '../UI/DraggableContainer';
import {LoaderContext} from '../../store/LoaderContex';
import {
  ConfirmationModalMode,
  LoadingTypes,
} from '../../common/enums/common.enums';
import ConfirmationModal from '../UI/ConfirmationModal';
import Loader from '../Loader/Loader';

const AddTrainingPlan = () => {
  const loadingCtx = useContext(LoaderContext);
  const location = useLocation();
  const addTrainingPlanTitle = 'Dodaj novi plan treninga';
  const editTrainingPlanTitle = 'Izmeni plan treninga';
  const saveTrainingPlanTitle = 'Sačuvaj';
  const addNewTrainingPlanTitle = 'Dodavanje plana treninga...';
  const uploadOrDragPicture = 'Dodaj ili prevuci sliku';
  const fileTypes = ['JPG', 'PNG'];

  const navigate = useNavigate();

  const [file, setFile] = useState<File>();
  const [trainingPlan, setTrainingPlan] = useState<TrainingPlan>(
    {} as TrainingPlan,
  );
  const [trainingPlanCategories, setTrainingPlanCategories] = useState<
    TrainingPlanCategory[]
  >([]);
  const [draggableComponents, setDraggableComponents] = useState<
    DraggableComponentInterface[]
  >([]);
  const [selectedCategory, setSelectedCategory] = useState<CategoryInterface>();
  const [trainings, setTrainings] = useState<CategoryInterface[]>();
  const [error, setError] = useState<string>();
  const [openModal, setOpenModal] = useState(false);

  const handleChange = (file: File) => {
    setFile(file);
    setError(undefined);
    setTrainingPlan(prevState => {
      return (
        prevState && {
          ...prevState,
          pictureUrl: URL.createObjectURL(file),
        }
      );
    });
  };

  const isValidForm =
    !!trainingPlan.name &&
    trainingPlan.name.trim().length > 0 &&
    !!trainingPlan.description &&
    trainingPlan.description.trim().length > 0 &&
    !!(file || trainingPlan.pictureUrl) &&
    draggableComponents.length > 0 &&
    !!selectedCategory &&
    selectedCategory.id !== -1 &&
    !!trainingPlanCategories;

  const submitTrainingPlan = async () => {
    if (!isFormValid(isValidForm)) return;

    const selectedTrainings: any = [];

    draggableComponents.forEach((component, index) => {
      selectedTrainings.push({
        [component.id]: index + 1,
      });
    });
    const formData = new FormData();
    try {
      loaderHandler(loadingCtx, LoadingTypes.UPLOAD, true);
      formData.append('name', trainingPlan.name!);
      formData.append('description', trainingPlan.description);
      formData.append('fileTypes', '[{"type":"image"}]');
      formData.append('selectedTrainings', JSON.stringify(selectedTrainings));
      formData.append(
        'selectedTrainingPlanCategoryId',
        JSON.stringify(selectedCategory?.id),
      );
      formData.append(
        'files',
        location.state.mode === Mode.EDIT && !file
          ? trainingPlan.pictureUrl
          : file!,
      );

      if (location.state) {
        switch (location.state.mode) {
          case 'ADD':
            await insertTrainingPlan(formData);
            toast.success('Trening plan je uspešno dodat.');
            navigate('/training-plans');
            break;
          case 'EDIT':
            await updateTrainingPlan(location.state.trainingPlanId, formData);
            toast.success('Trening plan je uspešno izmenjen.');
            navigate('/training-plans');
            setOpenModal(false);
            break;
          default:
            break;
        }
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      loaderHandler(loadingCtx, LoadingTypes.UPLOAD, false);
    }
  };

  const onSaveClickHandler = () => {
    if (!location.state) {
      return;
    }
    location.state.mode === 'ADD' ? submitTrainingPlan() : setOpenModal(true);
  };

  const handleFormChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    param: string,
  ) => {
    setTrainingPlan(prevState => {
      return (
        prevState && {
          ...prevState,
          [param]: event.target.value,
        }
      );
    });
  };

  const getAllTrainings = async (chosenTrainings: CategoryInterface[]) => {
    const optionsHelper: {label: string; id: number; type: string}[] = [];
    try {
      const response = await getAllTrainingsRequest();
      const trainings = fromTrainingResponseDTOToTraining(response);
      trainings.forEach(item => {
        if (!chosenTrainings.find(component => component.id === item.id))
          optionsHelper.push({
            label: item.name!,
            id: item.id,
            type: 'training',
          });
      });
      setTrainings(optionsHelper);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  const prepareForm = async (chosenTrainings: CategoryInterface[]) => {
    try {
      const res = fromTrainingPlanCategoryDtoToTrainingPlanCategory(
        await getAllTrainingPlanCategories(),
      );
      setTrainingPlanCategories(res);
      await getAllTrainings(chosenTrainings);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };
  const getTrainingPlan = async () => {
    try {
      loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
      const response = await getTrainingPlanDetailsRequest(
        location.state.trainingPlanId,
      );
      const trainingPlan =
        fromSingleTrainingPlanResponseDTOToTrainingPlan(response);
      setTrainingPlan(trainingPlan);
      setSelectedCategory({
        id: trainingPlan.category.id,
        type: 'training',
        label: trainingPlan.category?.label!,
      });
      const trainingsHelper: CategoryInterface[] = [];
      if (location.state.mode === Mode.EDIT) {
        for (const item of response.selectedTrainings) {
          trainingsHelper.push({
            id: item.training.id,
            label: item.training.name,
            type: 'training',
          });
        }
      }
      await prepareForm(trainingsHelper);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
    }
  };
  const handleSelectedCategoryChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: CategoryInterface | null,
  ) => {
    if (!value) {
      setSelectedCategory({id: -1, label: '', type: 'training'});

      return;
    }
    setSelectedCategory(value!);
  };

  useEffect(() => {
    if (location.state?.trainingPlanId) {
      getTrainingPlan();
    } else {
      prepareForm([]);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return !loadingCtx.isLoading.common ? (
    <div className={'add-training-plan-card-container'}>
      {openModal && (
        <ConfirmationModal
          dispatchFunction={submitTrainingPlan}
          closeModal={setOpenModal}
          mode={ConfirmationModalMode.EDIT}
        />
      )}
      <div className={'header-container'}>
        <BackArrow onClick={() => navigate('/training-plans')} />
        <h1 className={'add-training-plan-name'}>
          {location.state.mode === Mode.EDIT
            ? editTrainingPlanTitle
            : addTrainingPlanTitle}
        </h1>
      </div>
      <div className={'card-container'}>
        <h2 className={'add-training-plan-subtitle'}>Osnovni podaci</h2>
        <div className={'form-container'}>
          <TextField
            label={'Naziv'}
            className={'form-text-input'}
            value={trainingPlan?.name ? trainingPlan.name : ''}
            onChange={event => handleFormChange(event, 'name')}
          />
          <TextareaAutosize
            className={'text-area'}
            placeholder={'Opis'}
            value={trainingPlan?.description ? trainingPlan.description : ''}
            onChange={event => handleFormChange(event, 'description')}
          />
          {((selectedCategory && location.state.mode === 'EDIT') ||
            location.state.mode === 'ADD') && (
            <Autocomplete
              clearIcon={null}
              disablePortal
              defaultValue={selectedCategory}
              id="combo-box-demo"
              options={trainingPlanCategories}
              onChange={handleSelectedCategoryChange}
              sx={{width: '30vw'}}
              renderInput={params => (
                <TextField {...params} label="Kategorija" />
              )}
            />
          )}
        </div>
      </div>
      {/* second form */}
      <div className={'upload-image-container'}>
        <div className={'small-form-container'}>
          <h2 className={'add-training-plan-subtitle'}>Slika</h2>
          <div className={'file-uploader-container'}>
            <FileUploader
              handleChange={handleChange}
              name="file"
              label={uploadOrDragPicture}
              types={fileTypes}
              maxSize={10}
              onSizeError={(file: any) =>
                setError('Slika ne sme biti veća od 10mb.')
              }
            />
            {error && <p style={{color: 'red'}}>{error}</p>}
          </div>
        </div>
        {trainingPlan?.pictureUrl && (
          <img
            className={'cover-image'}
            alt={''}
            src={trainingPlan.pictureUrl}
          />
        )}
      </div>
      {/* third form */}
      <div className={'small-form-container'}>
        <h2 className={'add-training-plan-subtitle'}>Treninzi</h2>
        {trainings && (
          <DndProvider context={window} backend={HTML5Backend}>
            <DraggableContainer
              type={'training'}
              categories={trainingPlan?.selectedTrainings!}
              availableOptions={trainings}
              draggableComponents={draggableComponents}
              setDraggableComponents={setDraggableComponents}
            />
          </DndProvider>
        )}
      </div>
      <div className={'button-container'}>
        <Button
          onClick={onSaveClickHandler}
          className={'button-primary'}
          disabled={isValidForm && loadingCtx.isLoading.upload}>
          {!loadingCtx.isLoading.upload
            ? saveTrainingPlanTitle
            : addNewTrainingPlanTitle}
        </Button>
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default AddTrainingPlan;
