import '../../styles/Modal.scss';
import {createPortal} from "react-dom";
import React from "react";

type ModalOverlayProps = {
    children: JSX.Element;
    onClick?: () => void
}

type BackdropProps = {
    onClick?: () => void
}
const Backdrop: React.FC<BackdropProps> = (props: BackdropProps) => {
    return <div className={'backdrop'} onClick={props.onClick}/>
};
const ModalOverlay = ({children}: ModalOverlayProps) => {
    return <div className={'modal'}>
        <div className={'content'}>{children}</div>
    </div>
}

const portalElement = document.getElementById('overlays')!;

const Modal = (props: ModalOverlayProps) => {
    return <>
        {createPortal(<Backdrop onClick={props.onClick}/>, portalElement)}
        {createPortal(<ModalOverlay>{props.children}</ModalOverlay>, portalElement)}
    </>
};

export default Modal;