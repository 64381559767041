import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {get} from '../api/utilities/http';
import {verifyUserRequest} from '../api/user.api';
import {parseInt} from 'lodash';

const AccountConfirmation = () => {
  const [html, setHtml] = useState('');
  const verifyUser = async () => {
    let token = location.search.split('?token=')[1];
    let userId = parseInt(location.search.split('&userId=')[1]);
    try {
      if (token && userId) {
        const response = await verifyUserRequest(token, userId);
        setHtml(response);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    verifyUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <div dangerouslySetInnerHTML={{__html: html}} />;
};
export default AccountConfirmation;
