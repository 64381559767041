import {Oval} from "react-loader-spinner";
import * as React from "react";
import '../../styles/LoadingOvalStyle.scss'

type LoaderProps = {
    chatLoader?: boolean;
    isNotificationsLoader?: boolean;
    clientInfoTable?: boolean;
    moreMessageLoader?: boolean;
}
const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
    function getWrapperClassName() {
        switch (true) {
            case props.chatLoader:
                return 'oval-default oval-chat';
            case props.isNotificationsLoader:
                return 'loader-no-margin';
            case props.clientInfoTable:
                return 'loader-training-weight-table';
            case props.moreMessageLoader:
                return 'chat-loader';
            default:
                return 'oval-default';
        }
    }

    const wrapperClassName = getWrapperClassName();

    return (
        <div className={wrapperClassName}>
            <Oval
                height={50}
                width={50}
                color="#111726"
                wrapperClass="oval"
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#9DA2AF"
                strokeWidth={5}
                strokeWidthSecondary={5}
            /></div>
    )
};
export default Loader;