import {Autocomplete, Button, TextareaAutosize, TextField} from '@mui/material';
import {ChangeEvent, useContext, useEffect, useState} from 'react';
import {isMobile} from 'react-device-detect';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {FileUploader} from 'react-drag-drop-files';
import {useLocation, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

import {
  getExercisesRequest,
  getTrainingDetailsRequest,
  postTrainingRequest,
  updateTrainingRequest,
} from '../../api/training.api';
import {
  fromExerciseResponseDTOToExercise,
  fromTrainingResponseDTOToTraining,
} from '../../common/data-tansformers/input/trainingInputDataTransformer';
import {
  SelectedExercises,
  Training,
  TrainingType,
} from '../../common/models/training.interface';
import {
  isFormValid,
  isPositiveValueForm,
  loaderHandler,
  Mode,
  Types,
} from '../../utility/Helpers';
import {CategoryInterface} from '../Food/AddFoodPlan';
import BackArrow from '../UI/BackArrow';
import DraggableContainer, {
  DraggableComponentInterface,
} from '../UI/DraggableContainer';
import ConfirmationModal from '../UI/ConfirmationModal';
import {LoaderContext} from '../../store/LoaderContex';
import {
  ConfirmationModalMode,
  LoadingTypes,
} from '../../common/enums/common.enums';
import Loader from '../Loader/Loader';

const AddTraining = () => {
  const loadingCtx = useContext(LoaderContext);
  const location = useLocation();
  const addTrainingTitle = 'Dodaj novi trening';
  const editTrainingTitle = 'Izmeni trening';
  const addTrainingLoaderTitle = 'Dodavanje treninga...';
  const errorMessage =
    'Morate uneti broj serija, ponavljanja i odmor za svaki trening';
  const saveTraining = 'Sačuvaj';
  const fileTypes = ['JPG', 'PNG'];
  const uploadOrDragPicture = 'Dodaj ili prevuci sliku';

  const navigate = useNavigate();
  const [error, setError] = useState<string>();
  const [options, setOptions] = useState<CategoryInterface[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<CategoryInterface>();
  const [file, setFile] = useState<File>();
  const [training, setTraining] = useState<Training>({} as Training);
  const [draggableComponents, setDraggableComponents] = useState<
    DraggableComponentInterface[]
  >([]);
  const [availableExercises, setAvailableExercises] =
    useState<CategoryInterface[]>();
  const [openModal, setOpenModal] = useState(false);

  const getAllExercises = async (chosenExercises: CategoryInterface[]) => {
    const optionsHelper: CategoryInterface[] = [];

    try {
      loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
      const response = await getExercisesRequest();
      const exercises = fromExerciseResponseDTOToExercise(response);
      exercises.forEach(item => {
        if (!chosenExercises.find(component => component.id === item.id))
          optionsHelper.push({
            label: item?.name!,
            id: item?.id!,
            type: Types.EXERCISE,
          });
      });
      setAvailableExercises(optionsHelper);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
    }
  };

  const handleChange = (file: File) => {
    setFile(file);
    setError(undefined);
    setTraining(prevState => {
      return (
        prevState && {
          ...prevState,
          pictureUrl: URL.createObjectURL(file),
        }
      );
    });
  };

  const getCategories = () => {
    if (location?.state?.trainingTypes) {
      const categoriesHelper: CategoryInterface[] = [];
      location?.state?.trainingTypes.forEach((trainingType: TrainingType) => {
        categoriesHelper.push({
          id: trainingType.id,
          label: trainingType.name,
          type: trainingType.type,
        });
      });
      setOptions(categoriesHelper);
      setSelectedCategory(categoriesHelper[0]);
    }
  };

  const isValidForm =
    !!training.name &&
    !!training.description &&
    !!training.approxTime &&
    training.approxTime > 0 &&
    (!!file || !!training.pictureUrl) &&
    !!selectedCategory?.id;

  const insertTraining = async () => {
    if (!isPositiveValueForm(isValidForm)) return;

    const selectedExercises: SelectedExercises[] = [];
    let isValid = false;
    draggableComponents.forEach((component, index) => {
      selectedExercises.push({
        [component.id]: index + 1,
        sets: component.sets,
        repetitions: component.repeats,
        timeBreak: component.timeBreak,
      });
    });

    selectedExercises.forEach(item => {
      isValid =
        !!item.repetitions &&
        parseInt(item.repetitions) > 0 &&
        !!item.timeBreak &&
        parseInt(item.timeBreak) > 0 &&
        !!item.sets &&
        parseInt(item.sets) > 0;
    });

    if (!isValid) {
      toast.error(errorMessage);
      return;
    }

    const formData = new FormData();
    formData.append('name', training?.name!);
    formData.append('description', training?.description!);
    formData.append('approxTime', JSON.stringify(training?.approxTime!));
    formData.append('selectedExercises', JSON.stringify(selectedExercises));
    formData.append(
      'selectedTrainingTypeId',
      JSON.stringify(selectedCategory?.id!),
    );
    formData.append('files', file!);
    formData.append('fileTypes', '[{"type":"image"}]');

    try {
      if (location.state) {
        loaderHandler(loadingCtx, LoadingTypes.UPLOAD, true);
        switch (location.state.mode) {
          case 'ADD':
            await postTrainingRequest(formData);
            toast.success('Trening je uspešno dodat.');
            navigate('/trainings');
            break;
          case 'EDIT':
            await updateTrainingRequest(formData, location.state.trainingId);
            toast.success('Trening je uspešno izmenjen.');
            navigate('/trainings');
            setOpenModal(false);
            break;
          default:
            break;
        }
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      loaderHandler(loadingCtx, LoadingTypes.UPLOAD, false);
    }
  };

  const onSaveClickHandler = () => {
    if (!location.state) {
      return;
    }
    location.state.mode === 'ADD' ? insertTraining() : setOpenModal(true);
  };

  const getTraining = async () => {
    try {
      loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
      if (location.state.trainingId) {
        const response = await getTrainingDetailsRequest(
          location.state.trainingId,
        );
        const training = fromTrainingResponseDTOToTraining([response])[0];
        setTraining(training);
        setSelectedCategory({
          type: 'trainingType',
          id: training.trainingType?.id!,
          label: training.trainingType?.name!,
        });
        const exercisesHelper: CategoryInterface[] = [];
        if (location.state.mode === Mode.EDIT) {
          for (const item of response.selectedExercises) {
            exercisesHelper.push({
              id: item.exercise?.id!,
              label: item.exercise?.name!,
              type: 'exercise',
            });
          }
        }
        prepareForm(exercisesHelper);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
    }
  };

  const prepareForm = async (chosenExercises: CategoryInterface[]) => {
    await getCategories();
    await getAllExercises(chosenExercises);
  };

  const handleFormChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    param: string,
  ) => {
    setTraining((prevState: Training) => {
      return {
        ...prevState,
        [param]: event.target.value,
      };
    });
  };

  useEffect(() => {
    if (location.state?.trainingId) {
      getTraining();
    } else {
      prepareForm([]);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return !loadingCtx.isLoading.common ? (
    <div className={'add-training-plan-card-container'}>
      {openModal && (
        <ConfirmationModal
          dispatchFunction={insertTraining}
          closeModal={setOpenModal}
          mode={ConfirmationModalMode.EDIT}
        />
      )}
      <div className={'header-container'}>
        <BackArrow onClick={() => navigate('/trainings')} />
        <h1 className={'add-training-plan-name'}>
          {location.state.mode === Mode.EDIT
            ? editTrainingTitle
            : addTrainingTitle}
        </h1>
      </div>
      <div className={'card-container'}>
        <h2 className={'add-training-plan-subtitle'}>Osnovni podaci</h2>
        <div className={'form-container'}>
          <TextField
            label={'Naziv'}
            className={'form-text-input'}
            value={training?.name ? training.name : ''}
            onChange={event => handleFormChange(event, 'name')}
          />
          <TextareaAutosize
            className={'text-area'}
            placeholder={'Opis'}
            value={training?.description ? training.description : ''}
            onChange={event => handleFormChange(event, 'description')}
          />

          <div className={'training-row-wrapper'}>
            <TextField
              label={'Vreme trajanja (minuti)'}
              type={'number'}
              className={'form-text-input-small'}
              value={
                training?.approxTime !== undefined ? training.approxTime : ''
              }
              onChange={event => handleFormChange(event, 'approxTime')}
              inputProps={{min: 0}}
            />
            {((location.state.mode === 'EDIT' && selectedCategory) ||
              location.state.mode === 'ADD') && (
              <Autocomplete
                disablePortal
                clearIcon={null}
                id="combo-box-demo"
                options={options}
                getOptionLabel={option => (option.label ? option.label : '')}
                value={selectedCategory ? selectedCategory : options[0]}
                onChange={(event, value) =>
                  setSelectedCategory({
                    label: value?.label!,
                    id: value?.id!,
                    type: 'trainingType',
                  })
                }
                sx={{width: isMobile ? 'unset' : '14vw'}}
                renderInput={params => (
                  <TextField {...params} label="Tip treninga" />
                )}
              />
            )}
          </div>
        </div>
      </div>
      {/* second form */}
      <div className={'small-form-container'}>
        <h2 className={'add-training-plan-subtitle'}>Slika</h2>
        <div
          style={{flexDirection: 'column'}}
          className={'file-uploader-container'}>
          <FileUploader
            handleChange={handleChange}
            name="file"
            label={uploadOrDragPicture}
            types={fileTypes}
            maxSize={10}
            onSizeError={(file: any) =>
              setError('Slika ne sme biti veća od 10mb.')
            }
          />
          {error && <p style={{color: 'red'}}>{error}</p>}
        </div>
      </div>
      {training?.pictureUrl && (
        <img className={'cover-image'} src={training.pictureUrl} alt={''} />
      )}
      {/* thrid form */}

      <div className={'small-form-container'}>
        <h2 className={'add-training-plan-subtitle'}>Vežbe</h2>
        <div>
          {availableExercises && (
            <DndProvider context={window} backend={HTML5Backend}>
              <DraggableContainer
                availableOptions={availableExercises}
                type={Types.EXERCISE}
                categories={training?.selectedExercises!}
                setDraggableComponents={setDraggableComponents}
                draggableComponents={draggableComponents}
                isExercise={true}
              />
            </DndProvider>
          )}
        </div>
      </div>
      <div className={'button-container'}>
        <Button
          onClick={onSaveClickHandler}
          className={'button-primary'}
          disabled={isValidForm && loadingCtx.isLoading.upload}>
          {!loadingCtx.isLoading.upload ? saveTraining : addTrainingLoaderTitle}
        </Button>
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default AddTraining;
