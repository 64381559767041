import {ChatResponseDTO} from '../common/dtos/chat.interface.dto';
import {get, put} from './utilities/http';

export async function getChatByIdRequest(
    chatId: number,
    offset?: number
): Promise<ChatResponseDTO> {
    try {
        return await get(`chat/${chatId}${offset ? `?offset=${offset}` : ""}`);
    } catch (error: any) {
        throw error;
    }
}

export async function getAllChatsRequest(
    name?: string
): Promise<ChatResponseDTO[]> {
    try {
        return await get(`chat${name ? `?name=${name}` : ""}`);
    } catch (error: any) {
        throw error;
    }
}

export async function setMessagesReadAtRequest(data: {
    chatId: number;
}): Promise<ChatResponseDTO> {
    return await put("chat", data);
}
