import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import {useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {loginUserRequest} from '../api/auth.api';
import {LoginRequestDTO} from '../common/dtos/auth.interface.dto';
import {Login} from '../common/models/user.interface';
import {AuthContext} from '../store/AuthContext';
import {loaderHandler, LoggedIn} from '../utility/Helpers';
import {NotificationsAndSocketContext} from "../store/NotificationsAndSocketContext";
import {LoaderContext} from "../store/LoaderContex";
import {LoadingTypes} from "../common/enums/common.enums";

const theme = createTheme();

export default function SignIn() {
    const navigate = useNavigate();
    const notificationAndSocketCtx = useContext(NotificationsAndSocketContext);
    const signInTittle = "Prijavi se";
    const loadingSignInTitle = "Prijavljivanje...";
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const authContext = useContext(AuthContext);
    const loadingCtx = useContext(LoaderContext);
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (email.trim().length < 1 || password.trim().length < 1) {
            toast.error('Sva polja moraju biti popunjena.');
            return;
        }
        const loginDto: LoginRequestDTO = {
            password: password,
            email: email,
        };

        try {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
            const response = await loginUserRequest(loginDto);
            await localStorage.setItem(LoggedIn, 'true');
            await localStorage.setItem('userId', response?.userId?.toString());
            await localStorage.setItem('email', response?.email);
            await localStorage.setItem('fullName', response?.fullName);
            await localStorage.setItem('avatarUrl', response.pictureUrl);

            const loginObject: Login = {
                accessToken: response.accessToken,
                refreshToken: '',
                authenticated: true,
            };
            await notificationAndSocketCtx.setupChatSocket();
            authContext.setAuth(loginObject);
            navigate('/');
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                    <Typography component="h1" variant="h5">
                        Prijavi se
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Adresa"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={event => setEmail(event.currentTarget.value)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Lozinka"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={event => setPassword(event.currentTarget.value)}
                            value={password}
                        />
                        <Button
                            disabled={loadingCtx.isLoading.common}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}>
                            {!loadingCtx.isLoading.common ? signInTittle : loadingSignInTitle}
                        </Button>
                        <Grid container textAlign={'center'}>
                            <Grid item xs>
                                <Link href="/forgot-password" variant="body2" color={'#000000'}>
                                    Zaboravljena lozinka?
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <ToastContainer position={'bottom-right'} autoClose={2000}/>
            </Container>
        </ThemeProvider>
    );
}
