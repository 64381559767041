import '../../styles/MessageStyle.scss';

import React, {Dispatch, SetStateAction} from 'react';
import {useNavigate} from 'react-router-dom';

import {MessageInterface} from '../../common/models/chat.interface';
import {dateTransformerForChat} from "../../utility/Helpers";

type MessageProps = {
    message: MessageInterface;
    isSender: boolean;
    userImage?: string | null;
    adminAvatar?: string;
    messageRef: any;
    deleteMessage: (id: number, clientId: number) => void;
    clientId?: number;
    deleteMessageModal?: boolean;
    toggleDeleteMessageModal: Dispatch<SetStateAction<boolean>>;
    selectedMessage?: MessageInterface;
    openDeleteModalHandler: () => void;

};

const Message: React.FC<MessageProps> = (props: MessageProps) => {
    const defaultAvatarImageUrl = "/assets/avatar-img.png";
    const deleteMessageDotsUrl = "/assets/delete-message-dots.svg";
    const deleteMobileTrashIconUrl = "/assets/trash-icon.svg";
    const deleteMessageTitle = "Opozovi slanje";

    const adminAvatar = localStorage.getItem("avatarUrl");
    const navigate = useNavigate();
    const handleDeleteMessage = () => {
        props.deleteMessage(props.message.id!, props.clientId!);
    };
    const checkIsModalOpened = () => {
        props.deleteMessageModal && props.selectedMessage?.id === props.message.id ? props.toggleDeleteMessageModal(false) : props.openDeleteModalHandler()
    }
    const closeMessageModal = () => {
        props.deleteMessageModal && props.toggleDeleteMessageModal(false)
    }
    return (
        <>
            <div onClick={closeMessageModal}
                 className={
                     props.isSender
                         ? "sender-message-container"
                         : "receiver-message-container"
                 }>
                {!props.isSender && (
                    <img
                        onClick={() => {
                            navigate("/clients", {
                                state: {clientId: props.message.user?.id},
                            });
                        }}
                        className={"receiver-image"}
                        src={props.userImage ? props.userImage : defaultAvatarImageUrl}
                        alt={"user"}
                    />
                )}
                <div className={"message-container"}>
                    <div className={"message-delete-container"}>
                        {props.deleteMessageModal && props.message.id === props.selectedMessage?.id && (
                            <div className={"delete-message-modal"} onClick={handleDeleteMessage}>
                                <img
                                    src={deleteMobileTrashIconUrl}
                                    className={"trash-icon"}
                                    alt={""}
                                />
                                <p>{deleteMessageTitle}</p>
                            </div>)}
                        {props.isSender && (<div className={"delete-message-dots"} onClick={checkIsModalOpened}>
                            <img src={deleteMessageDotsUrl}/>
                        </div>)}
                        <div className={"message-wrapper"}>
                            <div
                                className={props.isSender ? "sender-message" : "receiver-message"}
                            >
                                {props.message.payload && !props.message.isImage && (
                                    <p className={"text"}>{props.message.payload}</p>
                                )}
                                {props.message.isImage && props.message.imageUrl && (
                                    <img
                                        className={"message-with-image"}
                                        src={props.message.imageUrl}
                                        alt={"Random"}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className={"date"}>{dateTransformerForChat(props.message.createdAt)}</p>
                    </div>
                </div>
                {props.isSender && (
                    <img
                        className={"sender-image"}
                        src={
                            adminAvatar && adminAvatar !== "null"
                                ? adminAvatar
                                : defaultAvatarImageUrl
                        }
                        alt={"user"}
                    />
                )}
            </div>
        </>
    );
};

export default Message;
