import {Button, Card} from '@mui/material';
import {DataGrid, GridColDef, GridRowParams} from '@mui/x-data-grid';
import React, {useEffect, useState, useContext} from 'react';
import {isMobile} from 'react-device-detect';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

import {
    deletePackageRequest,
    getAllPackagesRequest,
    getPackageCategoriesRequest,
} from '../../api/package.api';
import {
    fromPackageResponseDTOToPackageForm,
} from '../../common/data-tansformers/input/subscriptionInputDataTransformer';
import {
    PackageFormInterface,
    PackagePricesInterface,
} from '../../common/models/subscription.interface';
import {addFilters, loaderHandler, setItemToDeleteHelper} from '../../utility/Helpers';
import {FilterCategory} from '../Food/Food';
import ActionMenu from '../UI/ActionMenu';
import Filter from '../UI/Filter';
import {noRowsLabel} from "../../common/constants/Constants";
import ConfirmationModal from "../UI/ConfirmationModal";
import {LoaderContext} from "../../store/LoaderContex";
import Loader from "../Loader/Loader";
import {ConfirmationModalMode, LoadingTypes} from "../../common/enums/common.enums";

const Packages = () => {
    const navigate = useNavigate();
    const loadingCtx = useContext(LoaderContext);
    const convertToActionMenu = (params: any) => {
        return <ActionMenu
            onClick={setItemToDeleteHelper.bind(this, setOpenModal, setPackageToDeleteID, params.row.id)}
            params={params}/>;
    };

    const columns: GridColDef[] = [
        {field: "name", headerName: "NAZIV PAKETA", flex: 0.5},
        {field: "category", headerName: "KATEGORIJA", flex: 0.5},
        {field: "createdAt", headerName: "DATUM KREIRANJA PAKETA", flex: 0.6},
        {
            field: "numOfFoodAndTrainingPlans",
            headerName: "BROJ PLANOVA ISHRANE / TRENINGA",
            type: "number",
            flex: 0.7,
            align: "center",
            headerAlign: "left",
            renderCell: (params) => {
                return (
                    <h4>{`${params.row.numOfFoodPlans}/${params.row.numOfTrainingPlans}`}</h4>
                );
            },
        },
        {
            field: "price",
            headerName: "CENA PAKETA",
            flex: isMobile ? 0.7 : 0.6,
            renderCell: (params) => {
                return (
                    <div className={"packages-container"}>
                        {params.row.packagePrices?.map(
                            (packagePrice: PackagePricesInterface) => (
                                <h4 key={packagePrice.id + "_package_price"}>
                                    {packagePrice.price} RSD / €{packagePrice.priceEur}{" "}
                                </h4>
                            )
                        )}
                    </div>
                );
            },
        },
        {
            field: "available",
            headerName: "DOSTUPAN",
            flex: 0.5,
            renderCell: (params) => {
                return <h4>{params.row.available ? "DA" : "NE"}</h4>;
            },
        },
        {
            field: "action",
            headerName: "AKCIJE",
            flex: 0.4,
            sortable: false,
            renderCell: (params) => convertToActionMenu(params),
        },
    ];

    const [filters, setFilter] = useState<FilterCategory[]>([]);
    const [immutableFoodState, setImmutableFoodState] = useState<PackageFormInterface[]>([]);
    const [packages, setPackages] = useState<PackageFormInterface[]>([]);
    const [filterCategories, setFilterCategories] = useState<{
        name: string;
        filterType: string;
        filters: { id: number; name: string; type: string }[];
    }[]>();
    const [areAllItemsRequested, alreadyRequestedAllItems] = useState(true);
    const [timer, setTimer] = useState<any>();
    const [openModal, setOpenModal] = useState(false);
    const [packageToDeleteID, setPackageToDeleteID] = useState(-1);

    const controlFilters = async () => {
        await setPackages([]);
        await addFilters(filters, setPackages, immutableFoodState, packages);
    };

    const getAllPackages = async () => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
            const response = await getAllPackagesRequest();
            setPackages(fromPackageResponseDTOToPackageForm(response));
            setImmutableFoodState(fromPackageResponseDTOToPackageForm(response));

            const packageCategoriesResponse = await getPackageCategoriesRequest();

            let filtersArray: { id: number; name: string; type: string }[] = [];

            packageCategoriesResponse.map((category) => {
                filtersArray.push({
                    id: category.id,
                    name: category.name,
                    type: "category",
                });
                return null;
            });

            setFilterCategories([
                {
                    name: "Kategorija",
                    filterType: "category",
                    filters: filtersArray,
                },
            ]);
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
        }
    };

    const getPackagesByName = async (name: string) => {
        clearTimeout(timer);
        if (name.trim().length < 3 && !areAllItemsRequested) {
            alreadyRequestedAllItems(true);
            await getAllPackages();
        }
        const newTimer = setTimeout(async () => {
            if (name.trim().length > 2) {
                try {
                    loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
                    alreadyRequestedAllItems(false);
                    const response = await getAllPackagesRequest(name);
                    setPackages(fromPackageResponseDTOToPackageForm(response));
                } catch (error: any) {
                    toast.error(error?.response?.data?.message);
                } finally {
                    loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
                }
            }

        }, 500);
        setTimer(newTimer);
    };

    const handleRowClick = (event: GridRowParams) => {
        navigate("/package", {
            state: {
                packageId: event.row.id,
                mode: "EDIT",
            },
        });
    };

    const deletePackage = async (id: number) => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
            const packagesHelper = packages.slice();
            setOpenModal(false);
            await deletePackageRequest(id);
            setPackages(
                packagesHelper.filter((localPackage) => localPackage.id !== id)
            );
            toast.success("Paket je uspešno obrisan.");
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
        }
    };

    useEffect(() => {
        getAllPackages();
    }, []);

    useEffect(() => {
        controlFilters();
    }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {openModal && packageToDeleteID > -1 &&
                <ConfirmationModal dispatchFunction={deletePackage.bind(this, packageToDeleteID)}
                                   closeModal={setOpenModal} mode={ConfirmationModalMode.DELETE}/>}
            <Card className={"item-table-wrapper"}>
                <div className={"page-header-with-table-container"}>
                    <h1 className={"client-table-header"}>Dodati paketi</h1>
                    <Button
                        className={"top-button-primary"}
                        onClick={() => navigate("/package", {state: {mode: "ADD"}})}
                    >
                        + Dodaj
                    </Button>
                </div>
                <div className={"search-wrapper"}>
                    <img
                        src={"/assets/search-icon.svg"}
                        className={"search-icon"}
                        alt={""}
                    />
                    <input
                        className={"search-input"}
                        placeholder={"Pretraži po nazivu"}
                        onChange={(event) => getPackagesByName(event.currentTarget.value)}
                    />
                </div>
                {filterCategories && (
                    <Filter filterCategories={filterCategories} setFilter={setFilter}/>
                )}
                {!loadingCtx.isLoading.common ? (<div className={"data-grid-container"}>
                    <DataGrid
                        localeText={{
                            noRowsLabel: noRowsLabel
                        }}
                        disableColumnMenu={true}
                        onRowClick={(row) => handleRowClick(row)}
                        rows={packages}
                        columns={columns}
                        disableSelectionOnClick={true}
                        rowHeight={120}
                        pageSize={15}
                        checkboxSelection={false}
                    />
                </div>) : <Loader/>}
            </Card>
        </>
    );
};

export default Packages;
