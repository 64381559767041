import '../../styles/ChatStyle.scss';

import React from 'react';

import {ChatInterface} from '../../common/models/chat.interface';

type ChatProps = {
    chat: ChatInterface;
    selectedChat: number;
    setSelectedChat: (chatId: number) => void;
};

const ChatItem: React.FC<ChatProps> = (props: ChatProps) => {
    const defaultAvatarImageUrl = "/assets/avatar-img.png";
    return (
        <div
            className={
                props.chat.id !== props.selectedChat
                    ? "chat-item-container-active"
                    : "chat-item-container-inactive"
            }
            onClick={props.setSelectedChat.bind(this, props.chat.id)}
        >
            <div className={"user-image"}>
                <div>
                    <img
                        src={
                            props.chat?.user?.imageURL
                                ? props.chat.user.imageURL
                                : defaultAvatarImageUrl
                        }
                        alt={props.chat?.user?.fullName}

                    />
                </div>
                <h2>{props.chat.user?.fullName}</h2>
            </div>

            {props.chat.haveUnreadMessages && (
                <div className={"unread-message-indicator"}/>
            )}
        </div>
    );
};

export default ChatItem;
