import {Button, Card, Typography} from '@mui/material';
import {DataGrid, GridColDef, GridRowParams} from '@mui/x-data-grid';
import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

import {deleteRecipeRequest, getRecipesRequest} from '../../api/food.api';
import {
    fromFoodRecipeResponseDTOToFoodRecipe,
} from '../../common/data-tansformers/input/foodInputDataTransformer';
import {FoodRecipe} from '../../common/models/food.interface';
import {addFilters, setItemToDeleteHelper, loaderHandler} from '../../utility/Helpers';
import ActionMenu from '../UI/ActionMenu';
import {FilterCategory} from './Food';
import {noRowsLabel} from "../../common/constants/Constants";
import ConfirmationModal from "../UI/ConfirmationModal";
import {LoaderContext} from "../../store/LoaderContex";
import Loader from "../Loader/Loader";
import {ConfirmationModalMode, LoadingTypes} from "../../common/enums/common.enums";

export type RecipeInterface = {
    id: number;
    image: string;
    mealType: string;
    category: string;
    status: string;
};

const Recipes = () => {
    const loadingCtx = useContext(LoaderContext);
    const navigate = useNavigate();
    const [filters] = useState<FilterCategory[]>([]);
    const [immutableRecipesState, setImmutableRecipesState] = useState<FoodRecipe[]>([]);
    const [recipes, setRecipes] = useState<FoodRecipe[]>([]);
    const [areAllItemsRequested, alreadyRequestedAllItems] = useState(true);
    const [timer, setTimer] = useState<any>();
    const [openModal, setOpenModal] = useState(false);
    const [recipeToDeleteID, setRecipeToDeleteID] = useState(-1);
    const convertToActionMenu = (params: any) => {
        return <ActionMenu
            onClick={setItemToDeleteHelper.bind(this, setOpenModal, setRecipeToDeleteID, params.row.id)}
            params={params}/>;
    };

    const getDescription = (params: any) => {
        return (
            <div className="name-description-wrapper">
                <Typography className="header">{params.row.name}</Typography>
                <Typography className="text">{params.row.guide}</Typography>
            </div>
        );
    };

    const getImage = (params: any) => {
        return (
            <div className={"table-image"}>
                <img src={params.row.pictureUrl} alt={""}/>
            </div>
        );
    };

    const columns: GridColDef[] = [
        {
            field: "image",
            headerName: "SLIKA",
            flex: 0.6,
            renderCell: (params) => getImage(params),
        },
        {
            field: "name",
            headerName: "NAZIV I OPIS",
            flex: 2.8,
            renderCell: (params) => getDescription(params),
        },
        {
            field: "action",
            headerName: "AKCIJE",
            flex: 0.3,
            sortable: false,
            renderCell: (params) => convertToActionMenu(params),
        },
    ];

    const controlFilters = async () => {
        await setRecipes([]);
        await addFilters(filters, setRecipes, immutableRecipesState, recipes);
    };

    const handleRowClick = (event: GridRowParams) => {
        navigate("/recipe", {
            state: {
                recipe: event.row,
                recipeId: event.row.id,
                mode: "EDIT",
            },
        });
    };

    const getAllRecipes = async () => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
            const response = await getRecipesRequest();
            setRecipes(fromFoodRecipeResponseDTOToFoodRecipe(response));
            setImmutableRecipesState(fromFoodRecipeResponseDTOToFoodRecipe(response));
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
        }
    };

    const getRecipesByName = async (name: string) => {
        clearTimeout(timer);
        if (name.trim().length < 3 && !areAllItemsRequested) {
            alreadyRequestedAllItems(true);
            await getAllRecipes();
        }
        const newTimer = setTimeout(async () => {
            if (name.trim().length > 2) {
                try {
                    loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
                    alreadyRequestedAllItems(false);
                    const response = await getRecipesRequest(name);
                    setRecipes(fromFoodRecipeResponseDTOToFoodRecipe(response));
                } catch (error: any) {
                    toast.error(error?.response?.data?.message);
                } finally {
                    loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
                }
            }
        }, 500);
        setTimer(newTimer);
    };

    const deleteRecipe = async (recipeId: number) => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
            const recipesHelper = recipes.slice();
            setOpenModal(false);
            await deleteRecipeRequest(recipeId);
            setRecipes(recipesHelper.filter((recipe) => recipe.id !== recipeId));
            toast.success("Recept je uspešno obrisan.");
        } catch (error: any) {
            toast.error(error.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
        }
    };

    useEffect(() => {
        getAllRecipes();
    }, []);

    useEffect(() => {
        controlFilters();
    }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {openModal && recipeToDeleteID > -1 &&
                <ConfirmationModal dispatchFunction={deleteRecipe.bind(this, recipeToDeleteID)}
                                   closeModal={setOpenModal} mode={ConfirmationModalMode.DELETE}/>}
            <Card className={"item-table-wrapper"}>
                <div className={"page-header-with-table-container"}>
                    <h1 className={"client-table-header"}>Recepti</h1>
                    <Button
                        className={"top-button-primary"}
                        onClick={() => navigate("/recipe", {state: {mode: "ADD"}})}
                    >
                        + Dodaj
                    </Button>
                </div>
                <div className={"search-wrapper"}>
                    <img
                        src={"/assets/search-icon.svg"}
                        className={"search-icon"}
                        alt={""}
                    />
                    <input
                        className={"search-input"}
                        onChange={(event) => getRecipesByName(event.currentTarget.value)}
                        placeholder={"Pretraži po nazivu"}
                    />
                </div>
                {/*//TODO if filter categories exist uncomment this*/}
                {/*<Filter filterCategories={filterCategories} setFilter={setFilter}/>*/}
                {!loadingCtx.isLoading.common ? (<div className={"data-grid-container"}>
                    <DataGrid
                        localeText={{
                            noRowsLabel: noRowsLabel
                        }}
                        disableColumnMenu={true}
                        onRowClick={(row) => handleRowClick(row)}
                        rows={recipes}
                        columns={columns}
                        disableSelectionOnClick={true}
                        rowHeight={80}
                        pageSize={15}
                        checkboxSelection={false}
                    />
                </div>) : <Loader/>}
            </Card>
        </>
    );
};

export default Recipes;
