import '../../styles/ClientsStyle.scss';
// import '../../styles/LoadingOvalStyle.scss'

import {Card} from '@mui/material';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {Dispatch, SetStateAction, useEffect, useState, useContext} from 'react';
import {useLocation} from 'react-router-dom';
import {toast} from 'react-toastify';

import {getPackagesRequest} from '../../api/package.api';
import {deleteUserRequest, getAllUsersRequest} from '../../api/user.api';
import {
    fromPackageResponseDTOToPackage,
} from '../../common/data-tansformers/input/subscriptionInputDataTransformer';
import {
    fromClientResponseDTOtoClientInterface,
} from '../../common/data-tansformers/input/userInputDataTransformer';
import {ClientSubscriptionInterface, PackageInterface} from '../../common/models/subscription.interface';
import {ClientInterface} from '../../common/models/user.interface';
import {
    addFilters,
    dateTransformer,
    setItemToDeleteHelper,
    isVerified,
    loaderHandler,
    Types
} from '../../utility/Helpers';
import {FilterCategory} from '../Food/Food';
import PackageForm from '../Package/PackageForm';
import PaySlip from '../PaySlip/PaySlip';
import ActionMenu from '../UI/ActionMenu';
import Filter from '../UI/Filter';
import EditClient from './EditClient';
import {noRowsLabel} from "../../common/constants/Constants";
import {LoaderContext} from "../../store/LoaderContex";
import Loader from "../Loader/Loader";
import {ConfirmationModalMode, LoadingTypes} from "../../common/enums/common.enums";
import ConfirmationModal from "../UI/ConfirmationModal";

type ClientsTableProps = {
    setClientSection: Dispatch<SetStateAction<string>>;
    setClientId: Dispatch<SetStateAction<number | undefined>>;
    setPackages: Dispatch<SetStateAction<PackageInterface[] | undefined>>;
    packages: PackageInterface[] | undefined;
};

const ClientsTable = (props: ClientsTableProps) => {
    const loadingCtx = useContext(LoaderContext);
    const searchPlaceholderText = "Pretraži po nazivu";
    const searchIconUrl = "/assets/search-icon.svg";
    const [areAllItemsRequested, alreadyRequestedAllItems] = useState(true);
    const defaultImageURL = "/assets/avatar-img.png";

    const convertToActionMenu = (params: any) => {
        return <ActionMenu onClick={setItemToDeleteHelper.bind(this, setOpenModal, setUserToDeleteID, params.row.id)}
                           params={params}/>;
    };

    const getImage = (params: any) => {
        return (
            <div className={"table-image"}>
                <img
                    className={"client"}
                    src={params?.row?.imageURL ? params.row.imageURL : defaultImageURL}
                    alt={"avatar"}
                />
            </div>
        );
    };

    const getAllPackages = async () => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
            const packages = await getPackagesRequest();
            props.setPackages(fromPackageResponseDTOToPackage(packages));
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
        }
    };
    const filterCategories = [
        {
            name: "Status",
            filterType: "status",
            filters: [
                {id: 1, name: isVerified.VERIFIED, type: "status"},
                {id: 2, name: isVerified.UNVERIFIED, type: "status"},
            ],
        },
    ];

    const columns: GridColDef[] = [
        {
            field: "image",
            headerName: "SLIKA",
            flex: 0.4,
            renderCell: (params) => getImage(params),
            sortable: false
        },
        {field: "name", headerName: "IME I PREZIME", flex: 1},
        {field: "email", headerName: "EMAIL", flex: 1},
        {
            field: "status",
            headerName: "STATUS",
            flex: 0.5,
        },
        {
            field: Types.PACKAGE,
            headerName: "TRENUTNI PAKET",
            flex: 0.7,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "dateOfExpiration",
            headerName: "DATUM ISTEKA",
            flex: 0.7,
            align: "left",
            headerAlign: "left",
            renderCell: (param) => {
                return <h4>{dateTransformer(param.row.dateOfExpiration)}</h4>;
            },
        },
        {
            field: "action",
            headerName: "AKCIJE",
            flex: 0.5,
            sortable: false,
            renderCell: (params) => convertToActionMenu(params),
        },
    ];

    const [filters, setFilter] = useState<FilterCategory[]>([]);
    const [immutableClientsState, setImmutableClientsState] = useState<ClientInterface[]>([]);
    const [clients, setClients] = useState<ClientInterface[]>([]);
    const [timer, setTimer] = useState<any>();
    const [openModal, setOpenModal] = useState(false);
    const [userToDeleteID, setUserToDeleteID] = useState(-1);


    const controlFilters = async () => {
        await setClients([]);
        await addFilters(filters, setClients, immutableClientsState, clients);
    };

    const getAllClients = async () => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
            const clientsResponse = await getAllUsersRequest();
            setClients(fromClientResponseDTOtoClientInterface(clientsResponse));
            setImmutableClientsState(fromClientResponseDTOtoClientInterface(clientsResponse));
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
        }
    };

    const getClientsByName = async (name: string) => {
        clearTimeout(timer);
        if (name.trim().length < 3 && !areAllItemsRequested) {
            alreadyRequestedAllItems(true);
            await getAllClients();
        }
        const newTimer = setTimeout(async () => {
            if (name.trim().length > 2) {
                try {
                    loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
                    alreadyRequestedAllItems(false);
                    setClients(
                        fromClientResponseDTOtoClientInterface(await getAllUsersRequest(name))
                    );
                } catch (error: any) {
                    toast.error(error?.response?.data?.message);
                } finally {
                    loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
                }
            }
        }, 500)
        setTimer(newTimer);
    };

    const deleteUser = async (userId: number) => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
            const clientsHelper = clients.slice();
            await deleteUserRequest(userId);
            setClients(clientsHelper.filter((client) => client.id !== userId));
            setOpenModal(false);
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
        }
    };

    useEffect(() => {
        getAllPackages();
        getAllClients();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        controlFilters();
    }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {openModal &&
                <ConfirmationModal dispatchFunction={deleteUser.bind(this, userToDeleteID)} closeModal={setOpenModal}
                                   mode={ConfirmationModalMode.DELETE}/>}
            <Card className={"item-table-wrapper"}>
                <div className={"page-header-with-table-container"}>
                    <h1 className={"client-table-header"}>Klijenti</h1>
                </div>
                <div className={"search-wrapper"}>
                    <img src={searchIconUrl} className={"search-icon"} alt={"Search"}/>
                    <input
                        className={"search-input"}
                        onChange={(event) => getClientsByName(event.currentTarget.value)}
                        placeholder={searchPlaceholderText}
                    />
                </div>
                <Filter filterCategories={filterCategories} setFilter={setFilter}/>
                {!loadingCtx.isLoading.common ? (<div className={"data-grid-container"}>
                        <DataGrid
                            localeText={{
                                noRowsLabel: noRowsLabel
                            }}
                            disableColumnMenu={true}
                            onRowClick={(row) => {
                                props.setClientSection("edit-client");
                                props.setClientId(row.row.id);
                            }}
                            style={{margin: 'auto'}}
                            rows={clients}
                            columns={columns}
                            disableSelectionOnClick={true}
                            rowHeight={80}
                            pageSize={15}
                            checkboxSelection={false}
                        />
                    </div>) :
                    <Loader/>}
            </Card>
        </>
    );
};

type ClientsProps = {
    clientSection: string;
    clientId?: number;
};

export default function Clients(props: ClientsProps) {
    enum clientSections {
        CLIENTS_TABLE = "clients-table",
        EDIT_CLIENT = "edit-client",
        ADD_NEW_PACKAGE = "add-new-package",
        EDIT_PACKAGE = "edit-package",
        PAY_SLIP = "pay-slip",
    }

    const [clientSection, setClientSection] = useState("clients-table");
    const [subscriptionId, setSubscriptionId] = useState(-1);
    const [clientId, setClientId] = useState<number | undefined>(-1);
    const [clientFullName, setClientFullName] = useState<string | undefined>();
    const [packages, setPackages] = useState<PackageInterface[] | undefined>([]);
    const [subPackage, setSubPackage] = useState<ClientSubscriptionInterface>({} as ClientSubscriptionInterface)
    const location = useLocation();

    useEffect(() => {
        setClientSection(
            location.state?.clientId ? "edit-client" : props.clientSection
        );
        setClientId(
            location.state?.clientId ? location.state.clientId : props.clientId
        );
    }, [props.clientSection, props.clientId, location.state]);

    return (
        <>
            {clientSection === clientSections.CLIENTS_TABLE && (
                <ClientsTable
                    packages={packages}
                    setPackages={setPackages}
                    setClientId={setClientId}
                    setClientSection={setClientSection}
                />
            )}
            {clientSection === clientSections.EDIT_CLIENT && clientId && (
                <EditClient
                    setClientFullName={setClientFullName}
                    clientId={clientId}
                    setClientSection={setClientSection}
                    setSubscriptionId={setSubscriptionId}
                />
            )}
            {clientSection === clientSections.ADD_NEW_PACKAGE && (
                <PackageForm
                    subPackage={subPackage}
                    setSubPackage={setSubPackage}
                    packages={packages}
                    clientFullName={clientFullName}
                    clientId={clientId}
                    setClientSection={setClientSection}
                />
            )}
            {clientSection === clientSections.EDIT_PACKAGE && (
                <PackageForm
                    subPackage={subPackage}
                    setSubPackage={setSubPackage}
                    packages={packages}
                    clientFullName={clientFullName}
                    clientId={clientId}
                    subscriptionId={subscriptionId}
                    setClientSection={setClientSection}
                />
            )}
            {clientSection === clientSections.PAY_SLIP && (
                <PaySlip
                    subscriptionId={subscriptionId}
                    setSubPackage={setSubPackage}
                    subPackage={subPackage}
                    clientId={clientId}
                    clientFullName={clientFullName}
                    setClientSection={setClientSection}
                />
            )}
        </>
    );
}
