import {IsUniversal, MealTypeString} from '../../../utility/Helpers';
import {
  FoodPlanResponseDTO,
  FoodRecipeResponseDTO,
  MealTypeResponseDTO,
} from '../../dtos/food.interface.dto';
import {FoodPlan, FoodRecipe, MealType} from '../../models/food.interface';

export function fromFoodPlanResponseDTOToFoodPlan(
  foodPlanResponseDTO: FoodPlanResponseDTO[],
): FoodPlan[] {
  return foodPlanResponseDTO.map(foodPlan => ({
    id: foodPlan.id,
    name: foodPlan.name,
    description: foodPlan.description,
    pictureUrl: foodPlan.pictureUrl,
    isUniversal: foodPlan.isUniversal
      ? IsUniversal.UNIVERSAL
      : IsUniversal.NOT_UNIVERSAL,
    selectedFoodRecipes: foodPlan.selectedFoodRecipes,
    numOfRecipes: foodPlan.selectedFoodRecipes?.length,
  }));
}

export function fromFoodPlanResponseDTOToFoodPlanDetail(
  foodPlanResponseDTO: FoodPlanResponseDTO,
): FoodPlan {
  return {
    id: foodPlanResponseDTO.id,
    name: foodPlanResponseDTO.name,
    description: foodPlanResponseDTO.description,
    pictureUrl: foodPlanResponseDTO.pictureUrl,
    isUniversal: foodPlanResponseDTO.isUniversal
      ? IsUniversal.UNIVERSAL
      : IsUniversal.NOT_UNIVERSAL,
    selectedFoodRecipes: foodPlanResponseDTO.selectedFoodRecipes,
    numOfRecipes: foodPlanResponseDTO.selectedFoodRecipes?.length,
  };
}

export function fromFoodRecipeResponseDTOToFoodRecipe(
  foodRecipeResponseDTO: FoodRecipeResponseDTO[],
): FoodRecipe[] {
  return foodRecipeResponseDTO.map(foodRecipe => ({
    name: foodRecipe.name,
    id: foodRecipe.id,
    calories: foodRecipe.calories,
    fats: foodRecipe.fats,
    listOfFood: foodRecipe.listOfFood,
    guide: foodRecipe.guide,
    carbohydrates: foodRecipe.carbohydrates,
    cookingTime: foodRecipe.cookingTime,
    proteins: foodRecipe.proteins,
    pictureUrl: foodRecipe.pictureUrl,
  }));
}

export function fromFoodRecipeResponseDTOToFoodRecipeDetails(
  foodRecipeResponseDTO: FoodRecipeResponseDTO,
): FoodRecipe {
  return {
    name: foodRecipeResponseDTO.name,
    id: foodRecipeResponseDTO.id,
    calories: foodRecipeResponseDTO.calories,
    fats: foodRecipeResponseDTO.fats,
    listOfFood: foodRecipeResponseDTO.listOfFood,
    guide: foodRecipeResponseDTO.guide,
    carbohydrates: foodRecipeResponseDTO.carbohydrates,
    cookingTime: foodRecipeResponseDTO.cookingTime,
    proteins: foodRecipeResponseDTO.proteins,
    pictureUrl: foodRecipeResponseDTO.pictureUrl,
  };
}

export function fromMealTypeResponseDTOToMealType(
  mealTypeResponseDTO: MealTypeResponseDTO[],
): MealType[] {
  return mealTypeResponseDTO.map(mealType => ({
    label: mealType.name,
    id: mealType.id,
    type: MealTypeString,
  }));
}
