import {Button, TextareaAutosize, TextField} from '@mui/material';
import React, {ChangeEvent, useContext, useEffect, useState} from 'react';
import {FileUploader} from 'react-drag-drop-files';
import {useLocation, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

import {
  getExerciseByIdRequest,
  insertExercise,
  updateExercise,
} from '../../api/training.api';
import {Exercise} from '../../common/models/training.interface';
import {isFormValid, loaderHandler, Mode} from '../../utility/Helpers';
import BackArrow from '../UI/BackArrow';
import ConfirmationModal from '../UI/ConfirmationModal';
import {LoaderContext} from '../../store/LoaderContex';
import {
  ConfirmationModalMode,
  LoadingTypes,
} from '../../common/enums/common.enums';
import Loader from '../Loader/Loader';
import TrainingVideoSelect from '../Trainings/TrainingVideoSelect';

const AddExercise = () => {
  const loadingCtx = useContext(LoaderContext);
  const location = useLocation();
  const addExerciseTitle = 'Dodaj novu vežbu';
  const editExerciseTitle = 'Izmeni vežbu';
  const saveExcerciseTitle = 'Sačuvaj';
  const addExcerciseTitle = 'Dodavanje vežbe...';
  const imageTypes = ['JPG', 'PNG'];
  const videoTypes = ['MP4', 'MOV'];
  const setVideoImage = process.env.REACT_APP_SET_VIDEO_IMAGE === 'true';

  const navigate = useNavigate();

  const [imageFile, setImageFile] = useState<File>();
  const [videoFile, setVideoFile] = useState<File>();
  const [exercise, setExercise] = useState<Exercise>({} as Exercise);
  const [imageError, setImageError] = useState<string>();
  const [videoError, setVideoError] = useState<string>();
  const [openModal, setOpenModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState('');
  const [modal, toggleModal] = useState(false);
  const [isVideoChosen, toggleIsVideoChosen] = useState(true);

  const toggleModalHandler = () => {
    toggleModal(true);
    toggleIsVideoChosen(true);
  };

  const handleChange = (file: File, type: string) => {
    let field = '';
    if (type === 'image') {
      setImageFile(file);
      setImageError(undefined);
      field = 'pictureUrl';
    } else {
      toggleIsVideoChosen(false);
      setVideoFile(file);
      setVideoError(undefined);
      field = 'videoUrl';
    }
    setExercise(prevState => {
      return (
        prevState && {
          ...prevState,
          [field]: URL.createObjectURL(file),
        }
      );
    });
  };

  const handleFormChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    param: string,
  ) => {
    setExercise(prevState => {
      return (
        prevState && {
          ...prevState,
          [param]: event.target.value,
        }
      );
    });
  };

  const getExerciseById = async () => {
    try {
      loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
      const response = await getExerciseByIdRequest(location.state?.exerciseId);
      setExercise(response);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
    }
  };

  const submitExercise = async () => {
    if (!isFormValid(isValidForm)) return;

    const formData = new FormData();
    try {
      loaderHandler(loadingCtx, LoadingTypes.UPLOAD, true);
      formData.append('name', exercise.name!);
      formData.append('description', exercise.description!);
      formData.append('guide', exercise?.guide!);
      const fileTypes = [];
      if (imageFile) {
        formData.append('files', imageFile);
        fileTypes.push({type: 'image'});
      } else {
        formData.append('pictureUrl', exercise?.pictureUrl!);
      }
      if (videoFile) {
        fileTypes.push({type: 'video'});
        formData.append('files', videoFile);
      } else {
        formData.append('videoUrl', exercise?.videoUrl!);
      }
      formData.append('fileTypes', JSON.stringify(fileTypes));
      if (location.state) {
        switch (location.state.mode) {
          case 'ADD':
            await insertExercise(formData);
            toast.success('Vežba je uspešno dodata.');
            navigate('/exercises');
            break;
          case 'EDIT':
            await updateExercise(location.state.exerciseId, formData);
            toast.success('Vežba je uspešno izmenjena.');
            navigate('/exercises');
            setOpenModal(false);
            break;
          default:
            break;
        }
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      loaderHandler(loadingCtx, LoadingTypes.UPLOAD, false);
    }
  };

  const isValidForm =
    !!exercise.name &&
    !!exercise.description &&
    !!(imageFile || exercise.pictureUrl) &&
    !!(videoFile || exercise.videoUrl || selectedVideo.length > 0);

  useEffect(() => {
    if (location.state?.exerciseId) {
      getExerciseById();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return !loadingCtx.isLoading.common ? (
    <div className={'add-training-plan-card-container'}>
      {openModal && (
        <ConfirmationModal
          dispatchFunction={submitExercise}
          closeModal={setOpenModal}
          mode={ConfirmationModalMode.EDIT}
        />
      )}
      {modal && (
        <TrainingVideoSelect
          setSelectedVideo={setSelectedVideo}
          setExercise={setExercise}
          toggleModal={toggleModal}
        />
      )}
      <div className={'header-container'}>
        <BackArrow onClick={() => navigate('/exercises')} />
        <h1 className={'add-training-plan-name'}>
          {location.state.mode === Mode.EDIT
            ? editExerciseTitle
            : addExerciseTitle}
        </h1>
      </div>
      <div className={'card-container'}>
        <h2 className={'add-training-plan-subtitle'}>Osnovni podaci</h2>
        <div className={'form-container'}>
          <TextField
            label={'Naziv'}
            className={'form-text-input'}
            value={exercise?.name ? exercise.name : ''}
            onChange={event => handleFormChange(event, 'name')}
          />
          <TextareaAutosize
            className={'text-area'}
            placeholder={'Opis'}
            value={exercise?.description ? exercise.description : ''}
            onChange={event => handleFormChange(event, 'description')}
          />
          <TextareaAutosize
            className={'text-area'}
            placeholder={'Uputstvo'}
            value={exercise?.guide ? exercise.guide : ''}
            onChange={event => handleFormChange(event, 'guide')}
          />
        </div>
      </div>
      {/* second form */}
      <div className={'upload-image-container'}>
        <div className={'small-form-container'}>
          <h2 className={'add-training-plan-subtitle'}>Video</h2>
          <div className={'small-form-buttons'}>
            <div className={'button-container select-video-btn'}>
              <Button className={'button-primary'} onClick={toggleModalHandler}>
                Izaberi
              </Button>
            </div>
            <div className={'file-uploader-container'}>
              <FileUploader
                handleChange={(file: File) => handleChange(file, 'video')}
                name="file"
                label={'Dodaj ili prevuci video'}
                types={videoTypes}
                maxSize={100}
                onSizeError={(file: any) =>
                  setVideoError('Video ne sme biti veći od 100mb.')
                }
              />
            </div>
            {videoError && <p style={{color: 'red'}}>{videoError}</p>}
          </div>
        </div>
        {(exercise?.videoUrl || selectedVideo.length > 0) && (
          <div className={'video-container'}>
            <video id={'video'} controls src={exercise.videoUrl} />
          </div>
        )}
      </div>
      <div className={'upload-image-container'}>
        {((!isVideoChosen && setVideoImage) || exercise.pictureUrl) && (
          <div className={'small-form-container'}>
            <h2 className={'add-training-plan-subtitle'}>Slika</h2>
            {!isVideoChosen && setVideoImage && (
              <div className={'file-uploader-container'}>
                <FileUploader
                  handleChange={(file: File) => handleChange(file, 'image')}
                  name="file"
                  label={'Dodaj ili prevuci sliku'}
                  types={imageTypes}
                  maxSize={10}
                  onSizeError={(file: any) =>
                    setImageError('Slika ne sme biti veća od 10mb.')
                  }
                />
                {imageError && <p style={{color: 'red'}}>{imageError}</p>}
              </div>
            )}
          </div>
        )}
        {exercise?.pictureUrl && (
          <img className={'cover-image'} src={exercise.pictureUrl} alt={''} />
        )}
      </div>

      <div className={'button-container'}>
        <Button
          disabled={isValidForm && loadingCtx.isLoading.upload}
          onClick={submitExercise}
          className={'button-primary'}>
          {!loadingCtx.isLoading.upload
            ? saveExcerciseTitle
            : addExcerciseTitle}
        </Button>
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default AddExercise;
