import {get, post} from './utilities/http';
import {NOTIFICATIONS_URL} from './utilities/urls';

export async function getNotificationsRequest(): Promise<any[]> {
    try {
        return await get(`${NOTIFICATIONS_URL}`);
    } catch (error: any) {
        throw error;
    }
}

export async function getNotificationsCountRequest(userId: number): Promise<number> {
    try {
        return await get(`${NOTIFICATIONS_URL}/count?userId=${userId}`);
    } catch (error: any) {
        throw error;
    }
}

export async function readNotificationRequest(
    notificationId: number
): Promise<any> {
    return await post(
        `${NOTIFICATIONS_URL}?notificationId=${notificationId}`,
        {}
    );
}

export async function markAllNotificationsAsReadRequest(): Promise<any> {
    return await post(`${NOTIFICATIONS_URL}/mark-all-as-read`, {});
}
