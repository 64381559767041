import {DailyActivityResponseDTO} from '../common/dtos/dailyActivity.interface.dto';
import {
  ClientResponseDTO,
  UpdateUserPasswordRequestDTO,
  UserResponseDTO,
} from '../common/dtos/user.interface.dto';
import {deleteReq, get, put} from './utilities/http';
import {USER_URL, WEIGHT_PROGRESS_URL} from './utilities/urls';

export async function getAdminUserRequest(
  userId: number,
): Promise<UserResponseDTO> {
  try {
    return await get(`${USER_URL}/admin/${userId}`);
  } catch (error: any) {
    throw error;
  }
}

export async function getUserRequest(userId: number): Promise<UserResponseDTO> {
  try {
    return await get(`${USER_URL}/${userId}`);
  } catch (error: any) {
    throw error;
  }
}

export async function getAllUsersRequest(
  name?: string,
): Promise<ClientResponseDTO[]> {
  try {
    return await get(`${USER_URL}${name ? `?name=${name}` : ''}`);
  } catch (error: any) {
    throw error;
  }
}

export async function getAllUsersWhoseSubscriptionExpiresRequest(
  demo?: string,
): Promise<ClientResponseDTO[]> {
  try {
    return await get(
      `${USER_URL}/subscription-expiration${demo ? `?demo=${demo}` : ''}`,
    );
  } catch (error: any) {
    throw error;
  }
}

export async function updateUserInfoRequest(
  userId: number,
  data: FormData,
): Promise<FormData> {
  return await put(`${USER_URL}/${userId}`, data);
}

export async function updateUserPasswordRequest(
  data: UpdateUserPasswordRequestDTO,
): Promise<UpdateUserPasswordRequestDTO> {
  return await put('update_password', data);
}

export async function deleteUserRequest(userId: number) {
  return await deleteReq(`${USER_URL}/${userId}`);
}

export async function getWeightProgressRequest(
  userId: number,
): Promise<DailyActivityResponseDTO[]> {
  try {
    return await get(`${WEIGHT_PROGRESS_URL}/${userId}`);
  } catch (error: any) {
    throw error;
  }
}

export async function verifyUserRequest(token: string, userId: number) {
  try {
    return await get(`users/${userId}/auth/confirm?token=${token}`);
  } catch (e) {
    throw e;
  }
}
