import {createContext, useState} from 'react';
import {NotificationsContextInterface} from '../common/models/context.interface';
import {io} from 'socket.io-client';
// @ts-ignore

export const socket = new io(process.env.REACT_APP_BASE_URL!, {
    autoConnect: false,
    withCredentials: true,
});

const NotificationsAndSocketContext = createContext(
    {} as NotificationsContextInterface,
);
const {Provider} = NotificationsAndSocketContext;

const NotificationsProvider = ({children}: any) => {
    const [notificationsCount, setNotificationsCount] = useState(-1);

    const sendMessage = (userId: number) => {
        socket.emit('subscribeUser', {
            data: {userId: userId},
        });
    }

    const setupChatSocket = async () => {
        const userId = localStorage.getItem('userId');
        if (!userId) {
            return;
        }
        socket.connect();
        socket.on('connect', async () => {
           sendMessage(parseInt(userId));
        });
    };

    return (
        <Provider
            value={{
                setupChatSocket,
                socket,
                setNotificationsCount,
                notificationsCount,
            }}>
            {children}
        </Provider>
    );
};

export {NotificationsAndSocketContext, NotificationsProvider};
