import {
  LoginRequestDTO,
  LoginResponseDTO,
  LogoutRequestDTO,
  RefreshTokenRequestDTO,
  RefreshTokenResponseDTO,
  ResetPasswordRequestDTO,
  ResetPasswordVerifyRequestDTO,
} from '../common/dtos/auth.interface.dto';
import {get, post} from './utilities/http';
import {
  LOGIN_URL,
  LOGOUT_URL,
  REFRESH_TOKEN_URL,
  RESET_PASSWORD_REQUEST_URL,
  RESET_PASSWORD_URL,
} from './utilities/urls';

export async function loginUserRequest(
  data: LoginRequestDTO,
): Promise<LoginResponseDTO> {
  return await post(`${LOGIN_URL}?role=admin`, data);
}

export async function getRefreshToken(
  data: RefreshTokenRequestDTO,
): Promise<RefreshTokenResponseDTO> {
  try {
    return await get<RefreshTokenResponseDTO>(`${REFRESH_TOKEN_URL}`, data);
  } catch (error: any) {
    throw error;
  }
}

export async function logoutUserRequest(data: LogoutRequestDTO): Promise<any> {
  return await post(LOGOUT_URL, data);
}

export async function resetPasswordVerifyRequest(
  data: ResetPasswordVerifyRequestDTO,
): Promise<any> {
  return await post(RESET_PASSWORD_REQUEST_URL, data);
}

export async function resetPasswordRequest(
  data: ResetPasswordRequestDTO,
): Promise<any> {
  return await post(RESET_PASSWORD_URL, data);
}
