import {
    PackageDetailsDTO,
    PackageFormResponseDTO,
    PackageRequestDTO,
    PackageResponseDTO,
} from '../common/dtos/subscription.interface.dto';
import {deleteReq, get, post, put} from './utilities/http';
import {PACKAGE_CATEGORIES_URL, PACKAGES_URL} from './utilities/urls';

export async function getPackagesRequest(): Promise<PackageResponseDTO[]> {
    try {
        return await get(PACKAGES_URL);
    } catch (error: any) {
        throw error;
    }
}

export async function getAllPackagesRequest(
    name?: string
): Promise<PackageFormResponseDTO[]> {
    try {
        return await get(`${PACKAGES_URL}${name ? `?name=${name}` : ""}`);
    } catch (error: any) {
        throw error;
    }
}

export async function getPackageByIdRequest(
    id: number
): Promise<PackageDetailsDTO> {
    try {
        return await get(`${PACKAGES_URL}/${id}`);
    } catch (error: any) {
        throw error;
    }
}

export async function getPackageCategoriesRequest(): Promise<PackageFormResponseDTO[]> {
    try {
        return await get(`${PACKAGE_CATEGORIES_URL}`);
    } catch (error: any) {
        throw error;
    }
}

export async function insertPackageRequest(
    data: FormData
): Promise<void> {
    return await post(`${PACKAGES_URL}`, data);
}

export async function updatePackageRequest(
    data: FormData,
    packageId: number
): Promise<void> {
    return await put(`${PACKAGES_URL}/${packageId}`, data);
}

export async function deletePackageRequest(packageId: number): Promise<void> {
    return await deleteReq(`${PACKAGES_URL}/${packageId}`);
}
