import {Autocomplete, Button, TextareaAutosize, TextField} from '@mui/material';
import React, {ChangeEvent, Fragment, useContext, useEffect, useState} from 'react';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {useLocation, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

import {getFoodPlansRequest} from '../../api/food.api';
import {
    getPackageByIdRequest,
    getPackageCategoriesRequest,
    insertPackageRequest,
    updatePackageRequest,
} from '../../api/package.api';
import {getTrainingPlansRequest} from '../../api/training.api';
import {allFieldsAreRequiredAndPriceError, packagesPath} from '../../common/constants/Constants';
import {
    fromFoodPlanResponseDTOToFoodPlan,
} from '../../common/data-tansformers/input/foodInputDataTransformer';
import {
    fromPackageResponseDTOToPackageDetail,
} from '../../common/data-tansformers/input/subscriptionInputDataTransformer';
import {
    fromTrainingPlanResponseDTOToTrainingPlan,
} from '../../common/data-tansformers/input/trainingInputDataTransformer';
import {PackageRequestDTO} from '../../common/dtos/subscription.interface.dto';
import {
    PackageInterface,
    PackagePricesInterface,
} from '../../common/models/subscription.interface';
import {loaderHandler, Mode, Types} from '../../utility/Helpers';
import {CategoryInterface} from '../Food/AddFoodPlan';
import BackArrow from '../UI/BackArrow';
import DraggableContainer, {DraggableComponentInterface} from '../UI/DraggableContainer';
import {LoaderContext} from "../../store/LoaderContex";
import {ConfirmationModalMode, LoadingTypes} from "../../common/enums/common.enums";
import ConfirmationModal from "../UI/ConfirmationModal";
import {FileUploader} from "react-drag-drop-files";
import Loader from "../Loader/Loader";

const Package = () => {
    const options = [
        {id: 1, label: "DA"},
        {id: 2, label: "NE"},
    ];
    const loadingCtx = useContext(LoaderContext);
    const location = useLocation();
    const navigate = useNavigate();
    const editPackageTitle = "Izmeni paket";
    const addPackageTitle = "Dodaj novi paket";
    const saveNewPackageTitle = "Sačuvaj";
    const addNewPackageTitle = "Dodavanje novog paketa...";
    const uploadOrDragPicture = "Dodaj ili prevuci sliku";
    const packagePrices: PackagePricesInterface[] = [
        {
            id: 1,
            label: "1 mesec",
            price: undefined,
            priceEur: undefined,
            duration: 1,
        },
        {
            id: 2,
            label: "3 meseca",
            price: undefined,
            priceEur: undefined,
            duration: 3,
        },
        {
            id: 3,
            label: "6 meseci",
            price: undefined,
            priceEur: undefined,
            duration: 6,
        },
    ];

    const packagePricesForVipApp: PackagePricesInterface[] = [{
        id: 1,
        label: "1 mesec",
        price: undefined,
        priceEur: undefined,
        duration: 1
    }]

    const [fitnessPackage, setPackage] = useState<PackageInterface>(
        {} as PackageInterface
    );
    const [categoryOptions, setCategoryOptions] = useState<{ id: number; label: string }[]>([]);
    const [availableFoodPlans, setAvailableFoodPlans] =
        useState<CategoryInterface[]>();
    const [availableTrainingPlans, setAvailableTrainingPlans] =
        useState<CategoryInterface[]>();
    const [draggableComponents, setDraggableComponents] = useState<DraggableComponentInterface[]>([]);
    const [openModal, setOpenModal] = useState(false);
    const [file, setFile] = useState<File>();
    const [error, setError] = useState<string>();
    const fileTypes = ["JPG", "PNG"];


    const checkParam = (param: string, value?: { label: string; id: number }) => {
        if (param === "packageCategoryId") {
            return value?.id;
        }
        return value?.label === "DA" ? true : false;
    };

    const handleFormChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | null,
        param: string,
        value?: { label: string; id: number },
        monthIndex?: number
    ) => {
        if (
            (param === "priceEur" || param === "price") &&
            (monthIndex === 0 || monthIndex)
        ) {
            setPackage((prevState: PackageInterface) => {
                prevState.packagePrices[monthIndex][param] = event
                    ? parseInt(event.target.value)
                    : prevState.packagePrices[monthIndex][param];
                return prevState;
            });
            return;
        }
        setPackage((prevState: PackageInterface) => {
            return {
                ...prevState,
                [param]: event
                    ? param === "price"
                        ? parseInt(event.target.value)
                        : event.target.value
                    : checkParam(param, value),
            };
        });
    };

    const hasTrainingIds = draggableComponents.some(
        (component) => component["type"] === "training"
    );
    const hasFoodIds = draggableComponents.some(
        (component) => component["type"] === "food"
    );

    const isValidForm =
        !!fitnessPackage.name &&
        !!fitnessPackage.description &&
        fitnessPackage.name.trim().length > 0 && fitnessPackage.description.length > 0 &&
        !!fitnessPackage.packageCategoryId &&
        hasTrainingIds &&
        hasFoodIds;

    const getAllTrainingPlans = async (
        chosenTrainingPlans: CategoryInterface[]
    ) => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
            const availableTrainingsHelper: CategoryInterface[] = [];
            const trainings = fromTrainingPlanResponseDTOToTrainingPlan(
                await getTrainingPlansRequest()
            );
            trainings.forEach((training) => {
                if (
                    !chosenTrainingPlans.find((component) => component.id === training.id)
                )
                    availableTrainingsHelper.push({
                        id: training.id,
                        label: training.name,
                        type: Types.TRAINING,
                    });
            });
            setAvailableTrainingPlans(availableTrainingsHelper);
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
        }
    };

    const getAllFoodPlans = async (chosenFoodPlans: CategoryInterface[]) => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
            const availableFoodPlansHelper: CategoryInterface[] = [];
            const foodPlans = fromFoodPlanResponseDTOToFoodPlan(
                await getFoodPlansRequest()
            );

            foodPlans.forEach((foodPlan) => {
                if (!chosenFoodPlans.find((component) => component.id === foodPlan.id))
                    availableFoodPlansHelper.push({
                        id: foodPlan?.id!,
                        label: foodPlan?.name!,
                        type: Types.FOOD,
                    });
            });
            setAvailableFoodPlans(availableFoodPlansHelper);
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
        }
    };
    const getTrainingPlanIdsToInsert = (): number[] => {
        const trainingPlanIds: number[] = [];
        draggableComponents.forEach((component) => {
            if (component.type === Types.TRAINING) {
                trainingPlanIds.push(component.id);
            }
        });
        return trainingPlanIds;
    };

    const getFoodPlanIdsToInsert = (): number[] => {
        const foodPlanIds: number[] = [];
        draggableComponents.forEach((component) => {
            if (component.type === Types.FOOD) {
                foodPlanIds.push(component.id);
            }
        });
        return foodPlanIds;
    };

    const addPackage = async () => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.UPLOAD, true);
            let isDemoPackage = false;
            for(const category of categoryOptions){
                if(category.id === fitnessPackage.packageCategoryId && category.label.toLowerCase() === 'demo'){
                    isDemoPackage = true;
                }
            }
            if (
                ((!isDemoPackage || !loadingCtx.isVipApp) &&
                    fitnessPackage.packagePrices.find(
                        (packagePrice) =>
                            !packagePrice.priceEur ||
                            packagePrice.priceEur < 0 ||
                            !packagePrice.price ||
                            packagePrice.price < 0
                    )) ||
                !isValidForm
            ) {
                toast.error(allFieldsAreRequiredAndPriceError);
                return;
            }

            const trainingIds = getTrainingPlanIdsToInsert();
            const foodPlanIds = getFoodPlanIdsToInsert();

            const availability = fitnessPackage.available ? fitnessPackage.available : false;
            const formData = new FormData();
            formData.append('name', fitnessPackage.name!);
            formData.append('description', fitnessPackage.description!);
            formData.append('packageCategoryId', JSON.stringify(fitnessPackage.packageCategoryId!));
            formData.append('prices', JSON.stringify(fitnessPackage.packagePrices!));
            formData.append('isAvailable', JSON.stringify(availability));
            formData.append('selectedFoodPlanIds', JSON.stringify(foodPlanIds));
            formData.append('selectedTrainingPlanIds', JSON.stringify(trainingIds));
            formData.append('fileTypes', '[{"type":"image"}]');
            formData.append('files', file!);

            location.state.mode === "EDIT"
                ? await updatePackageRequest(formData, fitnessPackage?.id!)
                : await insertPackageRequest(formData);
            toast.success(
                location.state.mode === "EDIT"
                    ? "Paket uspešno izmenjen."
                    : "Paket uspešno dodat."
            );
            navigate(packagesPath);
            setOpenModal(false);
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.UPLOAD, false);
        }
    }

    const onSaveClickHandler = () => {
        if (!location.state) {
            return
        }
        location.state.mode === 'ADD' ? addPackage() : setOpenModal(true);
    };

    const getPackage = async () => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
            const response = await getPackageByIdRequest(location.state.packageId);
            setPackage(fromPackageResponseDTOToPackageDetail(response));
            const trainingPlansHelper: CategoryInterface[] = [];
            const foodPlansHelper: CategoryInterface[] = [];
            if (
                location.state.mode === Mode.EDIT &&
                response.selectedTrainingPlan &&
                response.selectedFoodPlan
            ) {
                for (const item of response.selectedFoodPlan) {
                    foodPlansHelper.push({
                        id: item.foodPlan?.id!,
                        label: item.foodPlan?.name!,
                        type: Types.FOOD_PLAN,
                    });
                }
                for (const item of response.selectedTrainingPlan) {
                    trainingPlansHelper.push({
                        id: item.trainingPlan?.id,
                        label: item.trainingPlan?.name,
                        type: Types.TRAINING_PLAN,
                    });
                }
            }
            await prepareForm(foodPlansHelper, trainingPlansHelper);
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
        }
    };

    const getPackageCategories = async () => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
            const packageCategoriesResponse = await getPackageCategoriesRequest();
            packageCategoriesResponse.map((category) => {
                setCategoryOptions((prevState) => [
                    ...prevState,
                    {id: category.id, label: category.name},
                ]);
                return null;
            });
        } catch (error: any) {
            toast.error(error.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
        }
    };

    // get last char of the month label in serbian language based on the number of months
    const getCharByDuration = (duration: number) => {
        switch (duration) {
            case 3:
                return "a";
            case 6:
                return "i";
            default:
                return "";
        }
    };
    const handleChange = (file: File) => {
        setFile(file);
        setError(undefined);
        setPackage((prevState) => {
            return (
                prevState && {
                    ...prevState,
                    pictureUrl: URL.createObjectURL(file),
                }
            );
        });
    };


    const prepareForm = async (
        chosenFoodPlans: CategoryInterface[],
        chosenTrainingPlans: CategoryInterface[]
    ) => {
        await getPackageCategories();
        await getAllFoodPlans(chosenFoodPlans);
        await getAllTrainingPlans(chosenTrainingPlans);
    };

    useEffect(() => {
        location.state.mode === "EDIT" ? getPackage() : prepareForm([], []);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (location.state.mode !== "EDIT") {
            setPackage({
                packagePrices: loadingCtx.isVipApp ? packagePricesForVipApp : packagePrices,
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (!loadingCtx.isLoading.common ?
            (<div className={'client-card-container'}>
                {openModal &&
                    <ConfirmationModal dispatchFunction={addPackage} closeModal={setOpenModal}
                                       mode={ConfirmationModalMode.EDIT}/>}
                <div className={'header-container'}>
                    <div className={'page-name-container'}>
                        <BackArrow
                            onClick={() => navigate('/packages')}
                        />
                        <h1 className={'client-name'}>{location.state.mode === Mode.EDIT ? editPackageTitle : addPackageTitle}</h1>
                    </div>
                </div>
                <div className={'card-container'}>
                    <h2 className={'client-subtitle'}>Osnovni podaci</h2>
                    <div className={'form-container'}>
                        <TextField
                            label={'Naziv paketa'}
                            value={fitnessPackage?.name ? fitnessPackage.name : ''}
                            onChange={event => handleFormChange(event, 'name')}
                            className={'form-text-input'}
                        />
                        <TextareaAutosize
                            className={'text-area'}
                            placeholder={'Opis'}
                            value={fitnessPackage?.description}
                            onChange={event => handleFormChange(event, 'description')}
                        />
                        {categoryOptions.length > 0 &&
                            <div className={'form-text-input-wrapper'}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    className={'autocomplete-box'}
                                    options={categoryOptions}
                                    defaultValue={categoryOptions.find(element => element.id === fitnessPackage.packageCategoryId)}
                                    style={{marginBottom: '20px'}}
                                    onChange={(event, value) =>
                                        handleFormChange(null, 'packageCategoryId', value!)}
                                    sx={{width: '20vw'}}
                                    renderInput={params => (
                                        <TextField {...params} label={'Kategorija'}/>
                                    )}
                                />
                                <Autocomplete
                                    disablePortal
                                    clearIcon={null}
                                    id="combo-box-demo"
                                    className={'autocomplete-box'}
                                    options={options}
                                    getOptionLabel={option => option.label}
                                    value={fitnessPackage.available ? options[0] : options[1]}
                                    onChange={(event, value, index) =>
                                        handleFormChange(null, 'available', value!)}
                                    sx={{width: '7vw'}}
                                    renderInput={params => (
                                        <TextField {...params} label={'Dostupnost'}/>
                                    )}
                                />
                            </div>
                        }
                        <div className={'package-prices-container'}>
                            {
                                fitnessPackage.packagePrices?.map((packagePrice, index: number) => (
                                    packagePrice.duration > 0 && <Fragment key={packagePrice.id + '_package_price'}>
                                        <h4>{`${packagePrice.duration} mesec${getCharByDuration(packagePrice.duration)}`}</h4>
                                        <div className={'form-text-input-wrapper'}>
                                            {fitnessPackage?.packagePrices && <TextField
                                                label={'Iznos (RSD)'}
                                                type={'number'}
                                                inputProps={{min: 0}}
                                                defaultValue={fitnessPackage.packagePrices[index]?.price}
                                                onChange={event => handleFormChange(event, 'price', undefined, index)}
                                                className={'form-text-input-small'}
                                            />}
                                            {fitnessPackage?.packagePrices[index] && <TextField
                                                label={'Iznos (EUR)'}
                                                inputProps={{min: 0}}
                                                type={'number'}
                                                defaultValue={fitnessPackage?.packagePrices[index]?.priceEur}
                                                onChange={event => handleFormChange(event, 'priceEur', undefined, index)}
                                                className={'form-text-input-small'}
                                            />}
                                        </div>
                                    </Fragment>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className={'small-form-container'}>
                    <h2 className={'client-subtitle'}>Slika</h2>
                    <div className={'file-uploader-container'}>
                        <FileUploader
                            handleChange={handleChange}
                            name="file"
                            label={uploadOrDragPicture}
                            types={fileTypes}
                            maxSize={10}
                            onSizeError={(file: File) =>
                                setError('Slika ne sme biti veća od 10mb.')
                            }
                        />

                        {error && <p style={{color: "red"}}>{error}</p>}
                    </div>
                </div>
                {fitnessPackage?.pictureUrl && (
                    <img className={"cover-image"} src={fitnessPackage.pictureUrl} alt={""}/>
                )}
                {/* second form */}
                {availableTrainingPlans && (
                    <div className={'small-form-container'}>
                        <h2 className={'client-subtitle'}>Treninzi</h2>
                        <DndProvider context={window} backend={HTML5Backend}>
                            <DraggableContainer
                                isPackage={true}
                                type={'training'}
                                draggableComponents={draggableComponents}
                                setDraggableComponents={setDraggableComponents}
                                availableOptions={availableTrainingPlans}
                                categories={fitnessPackage.selectedTrainingPlan}
                            />
                        </DndProvider>
                    </div>
                )}
                {/* thrid form */}
                {availableFoodPlans && (
                    <div className={'small-form-container'}>
                        <h2 className={'client-subtitle'}>Ishrana</h2>
                        <DndProvider context={window} backend={HTML5Backend}>
                            <DraggableContainer
                                isPackage={true}
                                type={'food'}
                                draggableComponents={draggableComponents}
                                setDraggableComponents={setDraggableComponents}
                                availableOptions={availableFoodPlans}
                                categories={fitnessPackage.selectedFoodPlan}
                            />
                        </DndProvider>
                    </div>
                )}
                <div className={'button-container'}>
                    <Button
                        disabled={isValidForm && loadingCtx.isLoading.upload}
                        onClick={onSaveClickHandler}
                        className={'button-primary'}>
                        {!loadingCtx.isLoading.upload ? saveNewPackageTitle : addNewPackageTitle}
                    </Button>
                </div>
            </div>) : <Loader/>
    );

}

export default Package;
