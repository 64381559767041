import {dateTransformer, isVerified, VERIFIED} from '../../../utility/Helpers';
import {
    ClientResponseDTO,
    UserResponseDTO,
} from '../../dtos/user.interface.dto';
import {ClientInterface, User} from '../../models/user.interface';
import {
    fromPackageResponseDTOToPackageDetail,
    fromSubscriptionResponseDTOToSubscription,
} from './subscriptionInputDataTransformer';

const noPackageText = 'Nema paketa';

export function fromClientResponseDTOtoClientInterface(
    clientResponseDto: ClientResponseDTO[],
): ClientInterface[] {
    return clientResponseDto.map(client => ({
        fullName: client.fullName,
        email: client.email,
        dateOfExpiration: client.subscriptionExpirationDate && new Date(client.subscriptionExpirationDate)?.toISOString(),
        imageURL: client.imageURL,
        id: client.userId,
        status:
            client.status === VERIFIED ? isVerified.VERIFIED : isVerified.UNVERIFIED,
        package: client.currentPackage
            ? fromPackageResponseDTOToPackageDetail(client.currentPackage).name
            : noPackageText,
        name: client.fullName,
        description: client.status,
        registrationDate: client.registrationDate,
        packageCategory: client.currentPackage?.packageCategory?.name,
        chatId: client.chatId
    }));
}

export function fromClientResponseDTOtoClientInterfaceDetail(
    clientResponseDto: ClientResponseDTO,
): ClientInterface {
    return {
        fullName: clientResponseDto.fullName,
        email: clientResponseDto.email,
        dateOfExpiration: dateTransformer(
            clientResponseDto.subscriptionExpirationDate,
        ),
        imageURL: clientResponseDto.imageURL,
        id: clientResponseDto.userId,
        status:
            clientResponseDto.status === VERIFIED
                ? isVerified.VERIFIED
                : isVerified.UNVERIFIED,
        package: clientResponseDto.currentPackage
            ? fromPackageResponseDTOToPackageDetail(clientResponseDto.currentPackage)
                .name
            : noPackageText,
        name: clientResponseDto.fullName,
        description: clientResponseDto.status,
    };
}

export function fromUserResponseDTOToUserDetail(
    userResponseDTO: UserResponseDTO,
): User {
    return {
        fullName: userResponseDTO.fullName,
        userId: userResponseDTO.userId,
        imageURL: userResponseDTO.imageURL,
        paymentHistory: fromSubscriptionResponseDTOToSubscription(
            userResponseDTO.paymentHistory,
        ),
        email: userResponseDTO.email,
        height: userResponseDTO.height,
        startingWeight: userResponseDTO.startingWeight,
        goalWeight: userResponseDTO.goalWeight,
        dateOfBirth: userResponseDTO.dateOfBirth,
        currentWeight: userResponseDTO.currentWeight,
        registrationDate: userResponseDTO.registrationDate,
        chatId: userResponseDTO.chatId
    };
}
