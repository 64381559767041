import {DailyActivityResponseDTO} from '../../dtos/dailyActivity.interface.dto';
import {DailyActivity} from '../../models/dailyActivityInterface';

export function fromDailyActivityResponseDTOToDailyActivity(
    dailyActivity: DailyActivityResponseDTO[],
): DailyActivity[] {
    return dailyActivity.map(activity => ({
        id: activity.id,
        dailyWeight: activity.dailyWeight,
        wakeUpTime: activity.wakeUpTime,
        sleepTime: activity.sleepTime,
        date: activity.createdAt
    }));
}
