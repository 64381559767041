import {Button, Card, Typography} from '@mui/material';
import {DataGrid, GridColDef, GridRowParams} from '@mui/x-data-grid';
import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

import {
    deleteTrainingsPlanRequest,
    getAllTrainingPlanCategories,
    getTrainingPlansByNameRequest,
    getTrainingPlansRequest,
} from '../../api/training.api';
import {
    fromTrainingPlanCategoryDtoToTrainingPlanCategory,
    fromTrainingPlanResponseDTOToTrainingPlan,
} from '../../common/data-tansformers/input/trainingInputDataTransformer';
import {
    Training,
    TrainingPlan,
    TrainingPlanCategory,
} from '../../common/models/training.interface';
import {addFilters, Mode, loaderHandler, setItemToDeleteHelper} from '../../utility/Helpers';
import {FilterCategory} from '../Food/Food';
import ActionMenu from '../UI/ActionMenu';
import Filter from '../UI/Filter';
import {noRowsLabel} from "../../common/constants/Constants";
import ConfirmationModal from "../UI/ConfirmationModal";
import {LoaderContext} from "../../store/LoaderContex";
import Loader from "../Loader/Loader";
import {ConfirmationModalMode, LoadingTypes} from "../../common/enums/common.enums";

export interface TrainingPlanInterface {
    id: number;
    image: string;
    name: string;
    numOfTrainings: number;
    status: string;
    trainings: Training[];
}

const TrainingPlans = () => {
    const navigate = useNavigate();
    const loadingCtx = useContext(LoaderContext);
    const [filters, setFilter] = useState<FilterCategory[]>([]);
    const [immutableTrainingPlansState, setImmutableTrainingPlansState] =
        useState<TrainingPlan[]>([]);
    const [trainingPlans, setTrainingPlans] = useState<TrainingPlan[]>([]);
    const [trainingPlanCategories, setTrainingPlanCategories] = useState<TrainingPlanCategory[]>([]);
    const [areAllItemsRequested, alreadyRequestedAllItems] = useState(true);
    const [timer, setTimer] = useState<any>();
    const [openModal, setOpenModal] = useState(false);
    const [trainingPlanToDeleteID, setTrainingPlanToDeleteID] = useState(-1);

    const convertToActionMenu = (params: any) => {
        return <ActionMenu
            onClick={setItemToDeleteHelper.bind(this, setOpenModal, setTrainingPlanToDeleteID, params.row.id)}
            params={params}/>;
    };

    const deleteTrainingPlan = async (id: number) => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
            const trainingPlansHelper = trainingPlans.slice();
            setOpenModal(false);
            await deleteTrainingsPlanRequest(id);
            setTrainingPlans(trainingPlansHelper.filter((item) => item.id !== id));
            toast.success("Trening plan je uspešno obrisan.");
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
        }
    };

    const getTrainingPlanCategories = async () => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
            const response = await getAllTrainingPlanCategories();
            setTrainingPlanCategories(
                fromTrainingPlanCategoryDtoToTrainingPlanCategory(response)
            );
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
        }
    };


    const getDescription = (params: any) => {
        return (
            <div className="name-description-wrapper">
                <Typography className="header">{params.row.name}</Typography>
                <Typography className="text">{params.row.description}</Typography>
            </div>
        );
    };
    const getCategory = (params: any) => {
        return (
            <div className="name-description-wrapper">
                <Typography className="header">{params.row.category.label}</Typography>
            </div>
        );
    };

    const getImage = (params: any) => {
        return (
            <div className={"table-image"}>
                <img src={params.row.pictureUrl} alt={"training-plan"}/>
            </div>
        );
    };

    const filterCategories = [
        {
            name: "Kategorija",
            filterType: "category",
            filters: trainingPlanCategories,
        },
    ];

    const columns: GridColDef[] = [
        {
            field: "image",
            headerName: "SLIKA",
            flex: 0.6,
            renderCell: (params) => getImage(params),
            sortable: false
        },
        {
            field: "name",
            headerName: "NAZIV I OPIS",
            flex: 1.8,
            renderCell: (params) => getDescription(params),
        },
        {
            field: "category",
            headerName: "KATEGORIJA",
            flex: 1.1,
            renderCell: (params) => getCategory(params),
        },

        {
            field: "numOfTrainings",
            headerName: "BROJ TRENINGA",
            type: "number",
            flex: 0.7,
            align: "left",
            headerAlign: "left",
        },

        {
            field: "action",
            headerName: "AKCIJE",
            flex: 0.4,
            sortable: false,
            renderCell: (params) => convertToActionMenu(params),
        },
    ];

    const controlFilters = async () => {
        await setTrainingPlans([]);
        await addFilters(
            filters,
            setTrainingPlans,
            immutableTrainingPlansState,
            trainingPlans
        );
    };

    const handleRowClick = (event: GridRowParams) => {
        navigate("/training-plan", {
            state: {
                trainingPlan: event.row,
                trainingPlanId: event.row.id,
                mode: Mode.EDIT,
            },
        });
    };

    const getAllTrainingPlans = async () => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
            const trainings = fromTrainingPlanResponseDTOToTrainingPlan(
                await getTrainingPlansRequest()
            );
            setTrainingPlans(trainings);
            setImmutableTrainingPlansState(trainings);
        } catch (error: any) {
            toast.error(error.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
        }
    };

    const getTrainingPlansByName = async (name: string) => {
        clearTimeout(timer);
        if (name.trim().length < 3 && !areAllItemsRequested) {
            alreadyRequestedAllItems(true);
            await getAllTrainingPlans();
        }
        const newTimer = setTimeout(async () => {
            if (name.trim().length > 2) {
                try {
                    loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
                    alreadyRequestedAllItems(false);
                    const response = await getTrainingPlansByNameRequest(name);
                    setTrainingPlans(fromTrainingPlanResponseDTOToTrainingPlan(response));
                } catch (error: any) {
                    toast.error(error?.response?.data?.message);
                } finally {
                    loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
                }
            }
        }, 500);
        setTimer(newTimer);
    };

    useEffect(() => {
        getAllTrainingPlans();
        getTrainingPlanCategories();
    }, []);

    useEffect(() => {
        controlFilters();
    }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {openModal && trainingPlanToDeleteID > -1 &&
                <ConfirmationModal dispatchFunction={deleteTrainingPlan.bind(this, trainingPlanToDeleteID)}
                                   closeModal={setOpenModal} mode={ConfirmationModalMode.DELETE}/>}
            <Card className={"item-table-wrapper"}>
                <div className={"page-header-with-table-container"}>
                    <h1 className={"client-table-header"}>Planovi treninga</h1>
                    <Button
                        className={"top-button-primary"}
                        onClick={() =>
                            navigate("/training-plan", {state: {mode: Mode.ADD}})
                        }
                    >
                        + Dodaj
                    </Button>
                </div>
                <div className={"search-wrapper"}>
                    <img
                        src={"/assets/search-icon.svg"}
                        className={"search-icon"}
                        alt={""}
                    />
                    <input
                        className={"search-input"}
                        placeholder={"Pretraži po nazivu"}
                        onChange={(event) =>
                            getTrainingPlansByName(event.currentTarget.value)
                        }
                    />
                </div>
                <Filter filterCategories={filterCategories} setFilter={setFilter}/>
                {!loadingCtx.isLoading.common ? (<div className={"data-grid-container"}>
                    <DataGrid
                        localeText={{
                            noRowsLabel: noRowsLabel
                        }}
                        disableColumnMenu={true}
                        onRowClick={(row) => handleRowClick(row)}
                        rows={trainingPlans}
                        columns={columns}
                        disableSelectionOnClick={true}
                        rowHeight={120}
                        pageSize={15}
                        checkboxSelection={false}
                    />
                </div>) : <Loader/>}
            </Card>
        </>
    );
};

export default TrainingPlans;
