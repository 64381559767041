import {
  ExerciseResponseDTO,
  TrainingDiaryResponseDTO,
  TrainingPlanCategoryDTO,
  TrainingPlanResponseDTO,
  TrainingRatingRequestDTO,
  TrainingResponseDTO,
  TrainingTypeResponseDTO,
} from '../common/dtos/training.interface.dto';
import {deleteReq, get, post, put} from './utilities/http';
import {
  EXERCISES_URL,
  MESSAGE_URL,
  TRAININGS_URL,
  TRAINING_PLANS_URL,
  FINISHED_TRAININGS_URL,
  TRAINING_VIDEOS,
  IMAGE_FROM_VIDEO_URL,
} from './utilities/urls';

export async function getTrainingPlansRequest(): Promise<
  TrainingPlanResponseDTO[]
> {
  try {
    return await get(`${TRAINING_PLANS_URL}`);
  } catch (error: any) {
    throw error;
  }
}

export async function getTrainingPlanDetailsRequest(
  trainingPlanId: number,
): Promise<TrainingPlanResponseDTO> {
  try {
    return await get(`${TRAINING_PLANS_URL}/${trainingPlanId}`);
  } catch (error: any) {
    throw error;
  }
}

export async function getTrainingsRequest(
  trainingPlanId: number,
): Promise<TrainingResponseDTO[]> {
  try {
    return await get(`${TRAINING_PLANS_URL}/${trainingPlanId}/trainings`);
  } catch (error: any) {
    throw error;
  }
}

export async function getAllTrainingsRequest(
  name?: string,
): Promise<TrainingResponseDTO[]> {
  try {
    return await get(`/trainings${name ? `?name=${name}` : ''}`);
  } catch (error: any) {
    throw error;
  }
}

export async function getTrainingTypesRequest(): Promise<
  TrainingTypeResponseDTO[]
> {
  try {
    return await get('training-types');
  } catch (error: any) {
    throw error;
  }
}

export async function getTrainingDetailsRequest(
  trainingId: number,
): Promise<TrainingResponseDTO> {
  try {
    return await get(`${TRAININGS_URL}/${trainingId}`);
  } catch (error: any) {
    throw error;
  }
}

export async function getExercisesFromTrainingRequest(
  trainingId: number,
): Promise<ExerciseResponseDTO[]> {
  try {
    return await get(`${TRAININGS_URL}/${trainingId}/exercises`);
  } catch (error: any) {
    throw error;
  }
}

export async function getExercisesRequest(
  name?: string,
): Promise<ExerciseResponseDTO[]> {
  try {
    return await get(`/exercises${name ? `?name=${name}` : ''}`);
  } catch (error: any) {
    throw error;
  }
}

export async function getExerciseByIdRequest(
  id: number,
): Promise<ExerciseResponseDTO> {
  try {
    return await get(`/exercises/${id}`);
  } catch (error: any) {
    throw error;
  }
}

export async function insertTrainingPlan(trainingPlanDto: FormData) {
  try {
    return await post(`${TRAINING_PLANS_URL}/`, trainingPlanDto);
  } catch (error: any) {
    throw error;
  }
}

export async function updateTrainingPlan(
  id: number,
  trainingPlanDto: FormData,
) {
  try {
    return await put(`${TRAINING_PLANS_URL}/${id}`, trainingPlanDto);
  } catch (error: any) {
    throw error;
  }
}

export async function getExercisesDetailsRequest(
  exerciseId: number,
): Promise<ExerciseResponseDTO[]> {
  try {
    return await get(`${EXERCISES_URL}/${exerciseId}`);
  } catch (error: any) {
    throw error;
  }
}

export async function insertExercise(
  exerciseRequestDto: FormData,
): Promise<ExerciseResponseDTO[]> {
  try {
    return await post(`${EXERCISES_URL}`, exerciseRequestDto);
  } catch (error: any) {
    throw error;
  }
}

export async function updateExercise(
  exerciseId: number,
  exerciseRequestDto: FormData,
): Promise<ExerciseResponseDTO[]> {
  try {
    return await put(`${EXERCISES_URL}/${exerciseId}`, exerciseRequestDto);
  } catch (error: any) {
    throw error;
  }
}

export async function postTrainingRatingRequest(
  data: TrainingRatingRequestDTO,
) {
  return await post(MESSAGE_URL, data);
}

export async function getAllTrainingPlanCategories(): Promise<
  TrainingPlanCategoryDTO[]
> {
  try {
    return await get('training-plan-categories');
  } catch (error: any) {
    throw error;
  }
}

export async function deleteTrainingsPlanRequest(id: number) {
  try {
    return await deleteReq(`training-plans/${id}`);
  } catch (error: any) {
    throw error;
  }
}

export async function deleteExerciseRequest(id: number) {
  try {
    return await deleteReq(`${EXERCISES_URL}/${id}`);
  } catch (error: any) {
    throw error;
  }
}

export async function getTrainingPlansByNameRequest(
  name: string,
): Promise<TrainingPlanResponseDTO[]> {
  try {
    return await get(`training-plans?name=${name}`);
  } catch (error: any) {
    throw error;
  }
}

export async function postTrainingRequest(data: FormData) {
  return await post(TRAININGS_URL, data);
}

export async function updateTrainingRequest(
  data: FormData,
  trainingId: number,
) {
  return await put(`${TRAININGS_URL}/${trainingId}`, data);
}

export async function deleteTrainingRequest(id: number) {
  return await deleteReq(`${TRAININGS_URL}/${id}`);
}

export async function getFinishedTrainingsRequest(
  userId: number,
): Promise<TrainingDiaryResponseDTO[]> {
  try {
    return await get(`${FINISHED_TRAININGS_URL}/user/${userId}`);
  } catch (error: any) {
    throw error;
  }
}

export async function getTrainingVideosRequest(
  trainerName: string | undefined,
): Promise<any[]> {
  try {
    return await get(`${TRAINING_VIDEOS}?trainerName=${trainerName}`);
  } catch (error: any) {
    throw error;
  }
}

export async function getVideoImagesRequest(
  trainerName: string,
  imageName: string,
): Promise<string> {
  try {
    return await get(
      `${IMAGE_FROM_VIDEO_URL}?trainerName=${trainerName}&imageName=${imageName}`,
    );
  } catch (error: any) {
    throw error;
  }
}
