import './App.scss';

import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import config from './config.json';
import BasicLayout from './components/BasicLayout';
import ForgotPassword from './components/ForgotPassword';
import PageNotFound from './components/PageNotFound';
import ResetPassword from './components/ResetPassword';
import SignIn from './components/Signin';
import {useContext, useEffect} from 'react';
import {toast} from 'react-toastify';
import {NotificationsAndSocketContext} from './store/NotificationsAndSocketContext';
import {LoggedIn} from './utility/Helpers';
import {ThemeConfigContext} from './store/ThemeConfigContext';
import {AuthContext} from './store/AuthContext';
import AccountConfirmation from './components/AccountConfirmation';

function App() {
  const authContext = useContext(AuthContext);
  const notificationAndSocketCtx = useContext(NotificationsAndSocketContext);
  const configContext = useContext(ThemeConfigContext);
  const tabTitle = 'FitHub Administracija';
  const connectToSocket = async () => {
    try {
      await notificationAndSocketCtx.setupChatSocket();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };
  const setConfigContext = () => {
    const configSubdomain = config.userConfigs.find(
      trainer => trainer.subdomain === process.env.REACT_APP_SUBDOMAIN,
    );
    document.title = configSubdomain ? configSubdomain.appName : tabTitle;
    if (!configSubdomain) return;
    configContext.setUserConfig(configSubdomain);
  };

  const handleAuth = (screenName: string) => {
    return localStorage.getItem(LoggedIn) ? (
      <BasicLayout screenName={screenName} />
    ) : (
      <Navigate to={'/login'} />
    );
  };

  useEffect(() => {
    localStorage.getItem(LoggedIn) && connectToSocket();
    setConfigContext();
  }, [authContext.authState.authenticated]);

  return (
    <Router basename={process.env.REACT_APP_BASENAME}>
      <Routes>
        <Route path="*" element={<PageNotFound />} />
        <Route path="/login" element={<SignIn />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/users/auth/confirm" element={<AccountConfirmation />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/page-not-found" element={<PageNotFound />} />
        <Route path="/" element={handleAuth('dashboard')} />
        <Route path="/clients" element={handleAuth('clients')} />
        <Route path="/food-plan" element={handleAuth('food-plan')} />
        <Route path="/food" element={handleAuth('food')} />
        <Route path="/recipes" element={handleAuth('recipes')} />
        <Route path="/recipe" element={handleAuth('recipe')} />
        <Route path="/packages" element={handleAuth('packages')} />
        <Route path="/package" element={handleAuth('package')} />
        <Route path="/training-plans" element={handleAuth('training-plans')} />
        <Route path="/training-plan" element={handleAuth('training-plan')} />
        <Route path="/trainings" element={handleAuth('trainings')} />
        <Route path="/training" element={handleAuth('training')} />
        <Route path="/exercises" element={handleAuth('exercises')} />
        <Route path="/exercise" element={handleAuth('exercise')} />
        <Route path="/chat" element={handleAuth('chat')} />
        <Route path="/settings" element={handleAuth('settings')} />
      </Routes>
    </Router>
  );
}

export default App;
