import '../styles/BasicLayoutStyle.scss';
import '../styles/DashboardStyle.scss';
import 'react-toastify/dist/ReactToastify.css';

import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {isMobile} from 'react-device-detect';
import {useLocation, useNavigate} from 'react-router-dom';
import {toast, ToastContainer} from 'react-toastify';

import {getNotificationsCountRequest} from '../api/notification.api';
import {Notification} from '../common/models/notification.interface';
import {NotificationsAndSocketContext, socket} from '../store/NotificationsAndSocketContext';
import {ScreenNames} from '../utility/Helpers';
import Chat from './Chat/Chat';
import Clients from './Client/Clients';
import Dashboard from './Dashboard/Dashboard';
import {MainListItems} from './DrawerItems';
import AddFoodPlan from './Food/AddFoodPlan';
import AddRecipe from './Food/AddRecipe';
import Food from './Food/Food';
import Recipes from './Food/Recipes';
import Notifications from './Notification/Notifications';
import Package from './Package/Package';
import Packages from './Packages/Packages';
import Settings from './Settings/Settings';
import AddExercise from './Trainings/AddExercise';
import AddTraining from './Trainings/AddTraining';
import AddTrainingPlan from './Trainings/AddTrainingPlan';
import Exercises from './Trainings/Exercises';
import TrainingPlans from './Trainings/TrainingPlans';
import Trainings from './Trainings/Trainings';
import {ThemeConfigContext} from "../store/ThemeConfigContext";
import {LoaderContext} from "../store/LoaderContex";

const drawerWidth = 240;
const openString = "open";
const width = "width";
const margin = "margin";
const calculatedWidth = `calc(100% - ${drawerWidth}px)`;
const relativeProperty = "relative";
const noWrap = "noWrap";
const boxSizingBorderBox = "border-box";
const overflowHidden = "hidden";

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== openString,
})<AppBarProps>(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create([width, margin], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: calculatedWidth,
        transition: theme.transitions.create([width, margin], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== openString,
})(({theme, open}) => ({
    "& .MuiDrawer-paper": {
        position: relativeProperty,
        whiteSpace: noWrap,
        width: drawerWidth,
        transition: theme.transitions.create(width, {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: boxSizingBorderBox,
        ...(!open && {
            overflowX: overflowHidden,
            transition: theme.transitions.create(width, {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(9),
            },
        }),
    },
}));

const DrawerContainer = () => {
    const fitnessLogo = '/assets/fithub-vip-beli.png';
    const configContext = useContext(ThemeConfigContext);
    const navigate = useNavigate();
    const notificationCtx = useContext(NotificationsAndSocketContext);
    const loadingCtx = useContext(LoaderContext);
    const [open, setOpen] = React.useState(!isMobile);
    const [openNotificationMenu, setOpenNotificationMenu] =
        useState<boolean>(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const getNotificationsCount = async () => {
        try {
            const userId = localStorage.getItem('userId');
            if (!userId) return;
            const response = await getNotificationsCountRequest(parseInt(userId));
            notificationCtx.setNotificationsCount(response);
        } catch (error: any) {
            if (error.response?.status === 401) {
                await localStorage.clear();
                navigate("/login");
                return;
            }
            toast.error(error?.response?.data?.message);
        }
    };

    useEffect(() => {
        getNotificationsCount();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const navigateToScreen = (screenName: string) => {
        navigate(screenName);
    };

    return (
        <>
            <AppBar position="absolute" open={open}>
                <Toolbar
                    onClick={(event) => {
                        event.stopPropagation();
                        setOpenNotificationMenu(false);
                    }}
                    className={"drawer-toolbar"}
                    style={{backgroundColor: configContext.userConfig.navbarColor}}
                    sx={{
                        pr: "24px",
                    }}
                >
                    {isMobile && (
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: "36px",
                                ...(open && {display: "block"}),
                            }}
                        >
                            <MenuIcon/>
                        </IconButton>
                    )}
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{flexGrow: 1}}
                    ></Typography>
                    <IconButton
                        style={{color: configContext.userConfig.ringImageColor}}
                        onClick={(event) => {
                            event.stopPropagation();
                            setOpenNotificationMenu(!openNotificationMenu);
                        }}
                    >
                        {notificationCtx.notificationsCount !== -1 && (
                            <Badge
                                badgeContent={notificationCtx.notificationsCount}
                                sx={{
                                    "& .MuiBadge-badge": {
                                        backgroundColor: configContext.userConfig.notificationColor,
                                        color: configContext.userConfig.notificationNumberColor
                                    }
                                }}
                            >
                                <NotificationsIcon/>
                            </Badge>
                        )}
                    </IconButton>
                    {openNotificationMenu && <Notifications/>}
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                open={open}
                className={!open ? "fitness-drawer-docked" : "fitness-drawer-undocked"}
                style={{
                    backgroundColor: "#111726!important",
                }}
            >
                <Toolbar
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        px: [1],
                    }}
                    style={{
                        backgroundColor: configContext.userConfig.sideMenuColor
                    }}
                >
                    {loadingCtx.isVipApp ?
                        (<img className={'fitness-drawer-logo'} src={fitnessLogo}/>) : (
                            <div className={"fitness-name-logo"} style={{
                                color: configContext.userConfig.themeFontColor,
                            }}>{configContext.userConfig.trainer}</div>)}
                </Toolbar>
                <List
                    component="nav"
                    style={{backgroundColor: configContext.userConfig.sideMenuColor, height: '100%', color: "#9DA2AF"}}
                >

                    <MainListItems navigate={navigateToScreen}/>
                </List>
            </Drawer>
        </>
    );
};

const mdTheme = createTheme();

function Content(props: BasicLayoutProps) {
    const location = useLocation();
    const notificationCtx = useContext(NotificationsAndSocketContext);
    const chatPath = location.pathname === "/chat";


    useEffect(() => {
        socket.on("newMessage", () => {
            notificationCtx.setNotificationsCount((prevState) => prevState + 1);
        });
        socket.on("newReminder", (reminderContent: string) => {
            notificationCtx.setNotificationsCount((prevState) => prevState + 1);
            toast.info(reminderContent);
        });
        socket.on("newNotification", (notifications: Notification[]) => {
            notificationCtx.setNotificationsCount(
                (prevState) => prevState + notifications.length
            );
            notifications.forEach((notification: Notification) => {
                toast.info(notification.text);
            });
        });
        return () => {
            socket.off("newReminder");
            socket.off("newMessage");
            socket.off("newNotification");
        };
    }, [chatPath ? location : null]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{display: "flex"}}>
                <CssBaseline/>
                <DrawerContainer/>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === "light"
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: "100vh",
                        overflow: "auto",
                    }}
                >
                    <Toolbar/>
                    {props.screenName === ScreenNames.DASHBOARD && <Dashboard/>}
                    {props.screenName === ScreenNames.CLIENTS && (
                        <Clients clientSection={"clients-table"}/>
                    )}
                    {props.screenName === ScreenNames.FOOD && <Food/>}
                    {props.screenName === ScreenNames.FOOD_PLAN && <AddFoodPlan/>}
                    {props.screenName === ScreenNames.RECIPES && <Recipes/>}
                    {props.screenName === ScreenNames.RECIPE && <AddRecipe/>}
                    {props.screenName === ScreenNames.PACKAGES && <Packages/>}
                    {props.screenName === ScreenNames.PACKAGE && <Package/>}
                    {props.screenName === ScreenNames.TRAINING_PLANS && <TrainingPlans/>}
                    {props.screenName === ScreenNames.TRAINING_PLAN && (
                        <AddTrainingPlan/>
                    )}
                    {props.screenName === ScreenNames.TRAININGS && <Trainings/>}
                    {props.screenName === ScreenNames.TRAINING && <AddTraining/>}
                    {props.screenName === ScreenNames.EXERCISES && <Exercises/>}
                    {props.screenName === ScreenNames.EXERCISE && <AddExercise/>}
                    {props.screenName === ScreenNames.CHAT && <Chat/>}
                    {props.screenName === ScreenNames.SETTINGS && <Settings/>}
                </Box>
                <ToastContainer position={"bottom-right"} autoClose={2000}/>
            </Box>
        </ThemeProvider>
    );
}

type BasicLayoutProps = {
    screenName: string;
};

export default function BasicLayout(props: BasicLayoutProps) {
    return <Content screenName={props.screenName}/>;
}
