import '../../styles/BackArrow.scss';

import React, { CSSProperties } from 'react';

type BackArrowProps = {
  style?: CSSProperties;
  onClick: React.MouseEventHandler;
};

const BackArrow: React.FC<BackArrowProps> = (props: BackArrowProps) => {
  return (
    <div className={"back-arrow"} style={props.style} onClick={props.onClick}>
      <img src={"/assets/arrow-icon.svg"} alt={""} />
    </div>
  );
};

export default BackArrow;
