import {Button, Card, Typography} from '@mui/material';
import {DataGrid, GridColDef, GridRowParams} from '@mui/x-data-grid';
import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

import {
    deleteTrainingRequest,
    getAllTrainingsRequest,
    getTrainingTypesRequest,
} from '../../api/training.api';
import {
    fromTrainingResponseDTOToTraining,
    fromTrainingTypeResponseDTOToTrainingType,
} from '../../common/data-tansformers/input/trainingInputDataTransformer';
import {Training, TrainingType} from '../../common/models/training.interface';
import {addFilters, setItemToDeleteHelper, loaderHandler} from '../../utility/Helpers';
import {FilterCategory} from '../Food/Food';
import ActionMenu from '../UI/ActionMenu';
import Filter from '../UI/Filter';
import {noRowsLabel} from "../../common/constants/Constants";
import ConfirmationModal from "../UI/ConfirmationModal";
import {LoaderContext} from "../../store/LoaderContex";
import Loader from "../Loader/Loader";
import {ConfirmationModalMode, LoadingTypes} from "../../common/enums/common.enums";

const Trainings = () => {
    const navigate = useNavigate();
    const loadingCtx = useContext(LoaderContext);
    const convertToActionMenu = (params: any) => {
        return <ActionMenu
            onClick={setItemToDeleteHelper.bind(this, setOpenModal, setTrainingToDeleteID, params.row.id)}
            params={params}/>;
    };

    const getDescription = (params: any) => {
        return (
            <div className="name-description-wrapper">
                <Typography className="header">{params.row.name}</Typography>
                <Typography className="text">{params.row.description}</Typography>
            </div>
        );
    };

    const getNumOfTrainings = (params: any) => {
        return (
            <div className="name-description-wrapper">
                <Typography className="header">{params.row.numOfExercises}</Typography>
            </div>
        );
    };

    const getImage = (params: any) => {
        return (
            <div className={"table-image"}>
                <img src={params.row.pictureUrl} alt={""}/>
            </div>
        );
    };

    const columns: GridColDef[] = [
        {
            field: "image",
            headerName: "SLIKA",
            flex: 0.6,
            renderCell: (params) => getImage(params),
            sortable: false
        },
        {
            field: "name",
            headerName: "NAZIV I OPIS",
            flex: 1.8,
            renderCell: (params) => getDescription(params),
        },
        {
            field: "trainingType",
            headerName: "TIP TRENINGA",
            flex: 0.7,
            valueGetter: (param) => {
                return `${param.row.trainingType?.name}`;
            },
        },
        {
            field: "numOfExercises",
            headerName: "BROJ VEŽBI",
            type: "number",
            flex: 0.5,
            align: "left",
            headerAlign: "left",
            renderCell: (params) => getNumOfTrainings(params),
        },

        {
            field: "action",
            headerName: "AKCIJE",
            flex: 0.4,
            sortable: false,
            renderCell: (params) => convertToActionMenu(params),
        },
    ];

    const [filters, setFilter] = useState<FilterCategory[]>([]);
    const [immutableTrainingState, setImmutableTrainingState] = useState<Training[]>([]);
    const [trainings, setTrainings] = useState<Training[]>([]);
    const [trainingTypes, setTrainingTypes] = useState<TrainingType[]>([]);
    const [areAllItemsRequested, alreadyRequestedAllItems] = useState(true);
    const [timer, setTimer] = useState<any>();
    const [openModal, setOpenModal] = useState(false);
    const [trainingToDeleteID, setTrainingToDeleteID] = useState(-1);

    const filterCategories = [
        {
            name: "Tip treninga",
            filterType: "trainingType",
            filters: trainingTypes,
        },
    ];

    const getAllTrainings = async () => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
            const response = await getAllTrainingsRequest();
            setTrainings(fromTrainingResponseDTOToTraining(response));
            setImmutableTrainingState(fromTrainingResponseDTOToTraining(response));
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
        }
    };

    const getAllTrainingTypes = async () => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
            const response = await getTrainingTypesRequest();
            setTrainingTypes(fromTrainingTypeResponseDTOToTrainingType(response));
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
        }
    };

    const getTrainingsByName = async (name: string) => {
        clearTimeout(timer);
        if (name.trim().length < 3 && !areAllItemsRequested) {
            alreadyRequestedAllItems(true);
            await getAllTrainings();
        }
        const newTimer = setTimeout(async () => {
            if (name.trim().length > 2) {
                try {
                    loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
                    alreadyRequestedAllItems(false);
                    setTrainings(
                        fromTrainingResponseDTOToTraining(await getAllTrainingsRequest(name))
                    );
                } catch (error: any) {
                    toast.error(error?.response?.data?.message);
                } finally {
                    loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
                }
            }
        }, 500);
        setTimer(newTimer);
    };

    const deleteTraining = async (id: number) => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
            const trainingsHelper = trainings.slice();
            setOpenModal(false);
            await deleteTrainingRequest(id);
            setTrainings(trainingsHelper.filter((training) => training.id !== id));
            toast.success("Trening je uspešno obrisan.");
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
        }
    };

    const controlFilters = async () => {
        await setTrainings([]);
        await addFilters(filters, setTrainings, immutableTrainingState, trainings);
    };

    const handleRowClick = (event: GridRowParams) => {
        navigate("/training", {
            state: {
                training: event.row,
                trainingId: event.row.id,
                trainingTypes: trainingTypes,
                mode: "EDIT",
            },
        });
    };

    useEffect(() => {
        getAllTrainingTypes();
        getAllTrainings();
    }, []);

    useEffect(() => {
        controlFilters();
    }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {openModal && trainingToDeleteID > -1 &&
                <ConfirmationModal dispatchFunction={deleteTraining.bind(this, trainingToDeleteID)}
                                   closeModal={setOpenModal} mode={ConfirmationModalMode.DELETE}/>}
            <Card className={"item-table-wrapper"}>
                <div className={"page-header-with-table-container"}>
                    <h1 className={"client-table-header"}>Treninzi</h1>
                    <Button
                        className={"top-button-primary"}
                        onClick={() =>
                            navigate("/training", {
                                state: {mode: "ADD", trainingTypes: trainingTypes},
                            })
                        }
                    >
                        + Dodaj
                    </Button>
                </div>
                <div className={"search-wrapper"}>
                    <img
                        src={"/assets/search-icon.svg"}
                        className={"search-icon"}
                        alt={""}
                    />
                    <input
                        className={"search-input"}
                        placeholder={"Pretraži po nazivu"}
                        onChange={(event) => getTrainingsByName(event.currentTarget.value)}
                    />
                </div>
                <Filter filterCategories={filterCategories} setFilter={setFilter}/>
                {!loadingCtx.isLoading.common ? (<div className={"data-grid-container"}>
                    <DataGrid
                        localeText={{
                            noRowsLabel: noRowsLabel
                        }}
                        disableColumnMenu={true}
                        onRowClick={(row) => handleRowClick(row)}
                        rows={trainings}
                        columns={columns}
                        disableSelectionOnClick={true}
                        rowHeight={80}
                        pageSize={15}
                        checkboxSelection={false}
                    />
                </div>) : <Loader/>}
            </Card>
        </>
    );
};

export default Trainings;
