import Modal from '@mui/material/Modal';
import React, {ChangeEvent, Dispatch, SetStateAction} from "react";
import {Button, TextareaAutosize, TextField,} from "@mui/material";
import {ReminderRequestDto} from "../../common/dtos/reminder.interface.dto";

type ReminderModalProps = {
    openModal: boolean,
    setOpenModal: Dispatch<SetStateAction<boolean>>
    handleFormChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                       param: string) => void
    saveReminder: () => void
    isValidForm: boolean
    reminder: ReminderRequestDto | undefined
}

const AddReminderModal: React.FC<ReminderModalProps> = (props: ReminderModalProps) => {
    const saveReminderTitle = "Sačuvaj";
    return (<Modal
        open={props.openModal}
        onClose={props.setOpenModal.bind(this, false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <div className={'modal-box'}>
            <div>
                <h3>Novi podsetnik</h3>
            </div>
            <div className={'form-container'}>
                <div className={'date-and-time-container'}>
                    <TextField
                        label={'Datum'}
                        type={'date'}
                        className={'form-text-input-small'}
                        onChange={(event) => props.handleFormChange(event, 'date')}
                        defaultValue={new Date().toISOString().split('T')[0]}
                    />
                    <TextField label={'Vreme'} type={'time'} className={'form-text-input-small'}
                               onChange={(event) => props.handleFormChange(event, 'time')}
                               defaultValue={'12:00'}
                    />

                </div>
                <TextareaAutosize
                    className={'text-area'}
                    placeholder={'Unesite podsetnik'}
                    onChange={(event) => props.handleFormChange(event, 'content')}
                />
                <div className={'button-container-modal'}>
                    <Button className={'button-with-stroke'}
                            onClick={props.setOpenModal.bind(this, false)}>Poništi</Button>
                    <Button className={'button-primary'} onClick={props.saveReminder}
                            disabled={!props.isValidForm}>{saveReminderTitle}
                    </Button>
                </div>
            </div>
        </div>
    </Modal>)
}

export default AddReminderModal;
