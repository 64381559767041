import {createContext, useState} from 'react';
import {LogoutRequestDTO} from '../common/dtos/auth.interface.dto';
import {AuthContextInterface} from '../common/models/context.interface';
import {Login} from '../common/models/user.interface';
import {toast} from 'react-toastify';
import {logoutUserRequest} from "../api/auth.api";

const AuthContext = createContext({} as AuthContextInterface);
const {Provider} = AuthContext;

const AuthProvider = ({children}: any) => {
    const [authState, setAuthState] = useState<Login>({
        accessToken: '',
        refreshToken: '',
        authenticated: false,
    });

    const logout = async () => {
        const refreshToken: LogoutRequestDTO = {};
        refreshToken.refresh_token = authState.refreshToken;
        try {
            setAuthState({
                accessToken: '',
                refreshToken: '',
                authenticated: false,
            });
            
            await logoutUserRequest(refreshToken);
            await localStorage.clear();
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
    };

    const getAccessToken = () => {
        return authState.accessToken;
    };

    const getRefreshToken = () => {
        return authState.refreshToken;
    };

    const setAuth = (loginObject: Login) => {
        setAuthState(loginObject);
    };

    return (
        <Provider
            value={{
                authState,
                getAccessToken,
                getRefreshToken,
                setAuth,
                logout,
            }}>
            {children}
        </Provider>
    );
};

export {AuthContext, AuthProvider};
