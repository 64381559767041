import {Button, Card, Typography} from '@mui/material';
import {DataGrid, GridColDef, GridRowParams} from '@mui/x-data-grid';
import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

import {deleteExerciseRequest, getExercisesRequest} from '../../api/training.api';
import {Exercise} from '../../common/models/training.interface';
import {addFilters, loaderHandler, setItemToDeleteHelper, Mode} from '../../utility/Helpers';
import {FilterCategory} from '../Food/Food';
import ActionMenu from '../UI/ActionMenu';
import {noRowsLabel} from "../../common/constants/Constants";
import {LoaderContext} from "../../store/LoaderContex";
import Loader from "../Loader/Loader";
import {ConfirmationModalMode, LoadingTypes} from "../../common/enums/common.enums";
import ConfirmationModal from "../UI/ConfirmationModal";

const Exercises = () => {
    const navigate = useNavigate();
    const loadingCtx = useContext(LoaderContext);
    const convertToActionMenu = (params: any) => {
        return <ActionMenu
            onClick={setItemToDeleteHelper.bind(this, setOpenModal, setExcerciseToDeleteID, params.row.id)}
            params={params}/>;
    };

    const getDescription = (params: any) => {
        return (
            <div className="name-description-wrapper">
                <Typography className="header">{params.row.name}</Typography>
                <Typography className="text">{params.row.description}</Typography>
            </div>
        );
    };

    const getImage = (params: any) => {
        return (
            <div className={"table-image"}>
                <img src={params.row.pictureUrl} alt={"exercise"}/>
            </div>
        );
    };

    const columns: GridColDef[] = [
        {
            field: "image",
            headerName: "SLIKA",
            flex: 0.4,
            renderCell: (param) => getImage(param),
            sortable: false
        },
        {
            field: "name",
            headerName: "NAZIV I OPIS",
            flex: 1.8,
            renderCell: (param) => getDescription(param),
        },
        {
            field: "action",
            headerName: "AKCIJE",
            flex: 0.2,
            sortable: false,
            renderCell: (params) => convertToActionMenu(params),
        },
    ];

    const [filters] = useState<FilterCategory[]>([]);
    const [immutableExerciseState, setImmutableExerciseState] = useState<Exercise[]>([]);
    const [exercises, setExercises] = useState<Exercise[]>([]);
    const [areAllItemsRequested, alreadyRequestedAllItems] = useState(true);
    const [timer, setTimer] = useState<any>();
    const [openModal, setOpenModal] = useState(false);
    const [excerciseToDeleteID, setExcerciseToDeleteID] = useState(-1);

    const controlFilters = async () => {
        await setExercises([]);
        await addFilters(filters, setExercises, immutableExerciseState, exercises);
    };

    const handleRowClick = (event: GridRowParams) => {
        navigate("/exercise", {
            state: {
                exercise: event.row,
                exerciseId: event.row.id,
                mode: Mode.EDIT,
            },
        });
    };

    const deleteExercise = async (id: number) => {
        const currentExercises = exercises.slice();

        try {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
            setOpenModal(false);
            await deleteExerciseRequest(id);
            setExercises(currentExercises.filter((item) => item.id !== id));
            toast.success("Vežba je uspešno obrisana");
        } catch (error: any) {
            toast.error(error.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
        }
    };


    const getAllExercises = async () => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
            const response = await getExercisesRequest();
            setExercises(response);
            setImmutableExerciseState(response);
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
        }
    };

    const getExercisesByName = async (name: string) => {
        clearTimeout(timer);
        if (name.trim().length < 3 && !areAllItemsRequested) {
            alreadyRequestedAllItems(true);
            await getAllExercises();
        }
        const newTimer = setTimeout(async () => {
            if (name.trim().length > 2) {
                try {
                    loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
                    alreadyRequestedAllItems(false);
                    const response = await getExercisesRequest(name);
                    setExercises(response);
                } catch (error: any) {
                    toast.error(error?.response?.data?.message);
                } finally {
                    loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
                }
            }
        }, 500);
        setTimer(newTimer);
    };

    useEffect(() => {
        getAllExercises();
    }, []);

    useEffect(() => {
        controlFilters();
    }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {openModal && excerciseToDeleteID > -1 &&
                <ConfirmationModal dispatchFunction={deleteExercise.bind(this, excerciseToDeleteID)}
                                   closeModal={setOpenModal} mode={ConfirmationModalMode.DELETE}/>}
            <Card className={"item-table-wrapper"}>
                <div className={"page-header-with-table-container"}>
                    <h1 className={"client-table-header"}>Vežbe</h1>
                    <Button
                        className={"top-button-primary"}
                        onClick={() => navigate("/exercise", {state: {mode: Mode.ADD}})}
                    >
                        + Dodaj
                    </Button>
                </div>
                <div className={"search-wrapper"}>
                    <img
                        src={"/assets/search-icon.svg"}
                        className={"search-icon"}
                        alt={""}
                    />
                    <input
                        className={"search-input"}
                        placeholder={"Pretraži po nazivu"}
                        onChange={(event) => getExercisesByName(event.currentTarget.value)}
                    />
                </div>
                {/*TODO check if filters exists*/}
                {/*<Filter*/}
                {/*  filterCategories={[]}*/}
                {/*  setFilter={() => console.log("not implemented")}*/}
                {/*></Filter>*/}

                {!loadingCtx.isLoading.common ? (<div className={"data-grid-container"}>
                    <DataGrid
                        localeText={{
                            noRowsLabel: noRowsLabel
                        }}
                        disableColumnMenu={true}
                        onRowClick={(row) => handleRowClick(row)}
                        rows={exercises}
                        columns={columns}
                        disableSelectionOnClick={true}
                        rowHeight={80}
                        pageSize={15}
                        checkboxSelection={false}
                    />
                </div>) : <Loader/>}
            </Card>
        </>
    );
};

export default Exercises;
