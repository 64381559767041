import '../../styles/FoodStyle.scss';

import {Button, Card, Typography} from '@mui/material';
import {DataGrid, GridColDef, GridRowParams} from '@mui/x-data-grid';
import React, {useEffect, useState, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

import {deleteFoodPlanRequest, getFoodPlansRequest} from '../../api/food.api';
import {
    fromFoodPlanResponseDTOToFoodPlan,
} from '../../common/data-tansformers/input/foodInputDataTransformer';
import {FoodPlan} from '../../common/models/food.interface';
import {addFilters, setItemToDeleteHelper, loaderHandler} from '../../utility/Helpers';
import ActionMenu from '../UI/ActionMenu';
import Filter from '../UI/Filter';
import {noRowsLabel} from "../../common/constants/Constants";
import ConfirmationModal from "../UI/ConfirmationModal";
import {LoaderContext} from "../../store/LoaderContex";
import Loader from "../Loader/Loader";
import {ConfirmationModalMode, LoadingTypes} from "../../common/enums/common.enums";

export interface FilterCategory {
    name: string;
    id: number;
    type: string;
}

const Food = () => {
    const navigate = useNavigate();
    const loadingCtx = useContext(LoaderContext);
    const convertToActionMenu = (params: any) => {
        return <ActionMenu
            onClick={setItemToDeleteHelper.bind(this, setOpenModal, setFoodPlanToDeleteID, params.row.id)}
            params={params}/>;
    };

    const getDescription = (param: any) => {
        return (
            <div className="name-description-wrapper">
                <Typography className="header">{param.row.name}</Typography>
                <Typography className="text">{param.row.description}</Typography>
            </div>
        );
    };

    const getImage = (param: any) => {
        return (
            <div className={"table-image"}>
                <img src={param.row.pictureUrl} alt={"food-plan"}/>
            </div>
        );
    };

    const filterCategories = [
        {
            name: "Kategorija",
            filterType: "isUniversal",
            filters: [
                {id: 1, name: "Univerzalan", type: "isUniversal"},
                {id: 2, name: "Nije univerzalan", type: "isUniversal"},
            ],
        },
    ];

    const columns: GridColDef[] = [
        {
            field: "image",
            headerName: "SLIKA",
            flex: 0.6,
            renderCell: (param) => getImage(param),
            sortable: false
        },
        {
            field: "name",
            headerName: "NAZIV I OPIS",
            flex: 1.8,
            renderCell: (param) => getDescription(param),
        },
        {field: "isUniversal", headerName: "KATEGORIJA", flex: 0.6},
        {
            field: "numOfRecipes",
            headerName: "BROJ RECEPATA",
            type: "number",
            flex: 0.7,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "action",
            headerName: "AKCIJE",
            flex: 0.4,
            sortable: false,
            renderCell: (params) => convertToActionMenu(params),
        },
    ];

    const [filters, setFilter] = useState<FilterCategory[]>([]);
    const [immutableFoodState, setImmutableFoodState] = useState<FoodPlan[]>([]);
    const [food, setFood] = useState<FoodPlan[]>([]);
    const [areAllItemsRequested, alreadyRequestedAllItems] = useState(true);
    const [timer, setTimer] = useState<any>();
    const [openModal, setOpenModal] = useState(false);
    const [foodPlanToDeleteID, setFoodPlanToDeleteID] = useState(-1);

    const controlFilters = async () => {
        await setFood([]);
        await addFilters(filters, setFood, immutableFoodState, food);
    };

    const getAllFoodPlans = async () => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
            const response = await getFoodPlansRequest();
            setFood(fromFoodPlanResponseDTOToFoodPlan(response));
            setImmutableFoodState(fromFoodPlanResponseDTOToFoodPlan(response));
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
        }
    };

    const getFoodPlansByName = async (name: string) => {
        clearTimeout(timer);
        if (name.trim().length < 3 && !areAllItemsRequested) {
            alreadyRequestedAllItems(true);
            await getAllFoodPlans();
        }
        const newTimer = setTimeout(async () => {
            if (name.trim().length > 2) {
                try {
                    loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
                    alreadyRequestedAllItems(false);
                    const response = await getFoodPlansRequest(name);
                    setFood(fromFoodPlanResponseDTOToFoodPlan(response));
                } catch (error: any) {
                    toast.error(error?.response?.data?.message);
                } finally {
                    loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
                }

            }
        }, 500);
        setTimer(newTimer);
    };

    const handleRowClick = (event: GridRowParams) => {
        navigate("/food-plan", {
            state: {
                foodPlan: event.row,
                foodPlanId: event.row.id,
                mode: "EDIT",
            },
        });
    };

    const deleteFoodPlan = async (id: number) => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
            const foodPlansHelper = food.slice();
            await deleteFoodPlanRequest(id);
            setFood(foodPlansHelper.filter((foodItem) => foodItem.id !== id));
            toast.success("Plan ishrane je uspešno obrisan.");
            setOpenModal(false);
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
        }
    };

    useEffect(() => {
        getAllFoodPlans();
    }, []);

    useEffect(() => {
        controlFilters();
    }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {openModal && foodPlanToDeleteID > -1 &&
                <ConfirmationModal dispatchFunction={deleteFoodPlan.bind(this, foodPlanToDeleteID)}
                                   closeModal={setOpenModal} mode={ConfirmationModalMode.DELETE}/>}
            <Card className={"item-table-wrapper"}>
                <div className={"page-header-with-table-container"}>
                    <h1 className={"client-table-header"}>Planovi ishrane</h1>
                    <Button
                        className={"top-button-primary"}
                        onClick={() => navigate("/food-plan", {state: {mode: "ADD"}})}
                    >
                        + Dodaj
                    </Button>
                </div>
                <div className={"search-wrapper"}>
                    <img
                        src={"/assets/search-icon.svg"}
                        className={"search-icon"}
                        alt={""}
                    />
                    <input
                        className={"search-input"}
                        placeholder={"Pretraži po nazivu"}
                        onChange={(event) => getFoodPlansByName(event.currentTarget.value)}
                    />
                </div>
                <Filter filterCategories={filterCategories} setFilter={setFilter}/>
                {!loadingCtx.isLoading.common ? (<div className={"data-grid-container"}>
                    <DataGrid
                        localeText={{
                            noRowsLabel: noRowsLabel
                        }}
                        disableColumnMenu={true}
                        onRowClick={(row) => handleRowClick(row)}
                        rows={food}
                        columns={columns}
                        disableSelectionOnClick={true}
                        rowHeight={80}
                        pageSize={15}
                        checkboxSelection={false}
                    />
                </div>) : <Loader/>}
            </Card>
        </>
    );
};

export default Food;
