import Modal from '../UI/Modal';
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import TrainingVideos from './TrainingVideos';
import {toast} from 'react-toastify';
import '../../styles/TrainingVideoSelect.scss';
import {
  getTrainingVideosRequest,
  getVideoImagesRequest,
} from '../../api/training.api';
import {Exercise} from '../../common/models/training.interface';

type TrainingVideoSelectProps = {
  toggleModal: Dispatch<SetStateAction<boolean>>;
  setSelectedVideo: Dispatch<SetStateAction<string>>;
  setExercise?: Dispatch<SetStateAction<Exercise>>;
};
const TrainingVideoSelect = (props: TrainingVideoSelectProps) => {
  const chooseVideo = 'Izaberi video';
  const cancelTrainingVideoSelection = 'Otkaži';
  const saveTrainingVideoSelection = 'Sačuvaj';
  const trainerName = process.env.REACT_APP_SUBDOMAIN;

  const [uploadedVideos, setUploadedVideos] = useState<string[]>([]);
  const [selectedVideo, setSelectedVideo] = useState<string>('');
  const [videoName, setVideoName] = useState('');

  const selectedVideoHandler = (src: string) => {
    setSelectedVideo(src);
    setVideoName(src.split('video/')[1].split('.')[0]);
  };

  const getTrainingVideos = async () => {
    try {
      const trainingVideosResponse = await getTrainingVideosRequest(
        trainerName,
      );
      setUploadedVideos(trainingVideosResponse);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  const closeModal = () => {
    props.toggleModal(false);
    setSelectedVideo('');
  };

  const saveModalSelection = async () => {
    if (selectedVideo.length < 1) {
      toast.error(chooseVideo);
      return;
    }
    if (!trainerName) return;
    const imageVideoResponse = await getVideoImagesRequest(
      trainerName!,
      videoName,
    );
    props.setSelectedVideo(selectedVideo);
    props.setExercise &&
      props.setExercise(prevState => {
        return (
          prevState && {
            ...prevState,
            videoUrl: selectedVideo,
            pictureUrl: imageVideoResponse[0],
          }
        );
      });
    props.toggleModal(false);
  };

  useEffect(() => {
    getTrainingVideos();
  }, []);

  return (
    <Modal onClick={props.toggleModal.bind(this, false)}>
      <div>
        <h3>{chooseVideo}</h3>
        <div className={'videoContainer'}>
          <div className={'videos'}>
            {uploadedVideos.map(video => (
              <TrainingVideos
                src={video}
                selectedVideo={selectedVideo}
                onClick={selectedVideoHandler}
              />
            ))}
          </div>
        </div>
        <div className={'modal-button-container'}>
          <button onClick={closeModal}>{cancelTrainingVideoSelection}</button>
          <button onClick={saveModalSelection}>
            {saveTrainingVideoSelection}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default TrainingVideoSelect;
