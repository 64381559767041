import '../../styles/ChatStyle.scss';

import {Button, TextField} from '@mui/material';
import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {FileUploader} from 'react-drag-drop-files';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useLocation} from 'react-router-dom';
import {toast} from 'react-toastify';

import {
  getAllChatsRequest,
  getChatByIdRequest,
  setMessagesReadAtRequest,
} from '../../api/chat.api';
import {ReminderRequestDto} from '../../common/dtos/reminder.interface.dto';
import {
  ChatInterface,
  MessageInterface,
} from '../../common/models/chat.interface';
import {BasicUserInfo} from '../../common/models/user.interface';
import {
  NotificationsAndSocketContext,
  socket,
} from '../../store/NotificationsAndSocketContext';
import AddReminderModal from '../Reminder/AddReminderModal';
import Search from '../UI/Search';
import ChatItem from './ChatItem';
import Message from './Message';
import {LoaderContext} from '../../store/LoaderContex';
import Loader from '../Loader/Loader';
import {loaderHandler} from '../../utility/Helpers';
import {LoadingTypes} from '../../common/enums/common.enums';
import {isMobile} from 'react-device-detect';

const Chat = () => {
  const defaultUserFullName = 'Korisnik';
  const defaultAvatarImageUrl = '/assets/avatar-img.png';
  const defaultCameraIconUrl = '/assets/camera-icon.svg';
  const defaultSendIconUrl = '/assets/send-icon.svg';
  const defaultArrowIconUrl = '/assets/arrow-icon.svg';
  const reminderAddedMessage = 'Podsetnik je uspešno dodat.';
  const addReminderTitle = '+ Podsetnik';
  const messageTextFieldPlaceHolder = 'Napiši poruku...';
  const maxImageSizeMessage = 'Maksimalna veličina slike je 5MB';
  const jpgFormat = 'JPG';
  const pngFormat = 'PNG';

  const submitFormOnEnter = 13;

  const scrollRef = useRef<any>();
  const messageRef = useRef<any>();

  const notificationAndSocketCtx = useContext(NotificationsAndSocketContext);

  const fileTypes = [jpgFormat, pngFormat];
  const location = useLocation();

  const [chats, setChats] = useState<ChatInterface[]>([]);
  const [messages, setMessages] = useState<MessageInterface[]>([]);
  const [user, setUser] = useState<BasicUserInfo>();
  const [message, setMessage] = useState<string>('');
  const [selectedChat, setSelectedChat] = useState(-1);
  const [file, setFile] = useState<File>();
  const [image, setImage] = useState<string>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [deleteMessageModal, toggleDeleteMessageModal] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState<MessageInterface>();
  const [reminder, setReminder] = useState<ReminderRequestDto>(
    {} as ReminderRequestDto,
  );
  const [userId, setUserId] = useState<number>(-1);
  const [clientId, setClientId] = useState(-1);
  const [areAllItemsRequested, alreadyRequestedAllItems] = useState(true);
  const [clientAvatar, setClientAvatar] = useState<string>();
  const [clientFullName, setClientFullName] = useState<string>();
  const loadingCtx = useContext(LoaderContext);
  const handleFileChange = (file: File) => {
    setFile(file);
    setImage(URL.createObjectURL(file));
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }, 250);
  };

  const handleMessageInput = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setMessage(event.target.value);
  };

  const blobToBase64 = async (blob: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const sendMessage = async () => {
    socket.off('adminMessage');
    let base64file;

    if (file) {
      await blobToBase64(file).then(res => {
        base64file = res;
      });
    }
    if (!((message && message.trim().length > 0) || image)) {
      return;
    }
    const newMessage: MessageInterface = {
      payload: message!,
      createdAt: new Date().toISOString(),
      user: {
        id: user?.userId!,
        fullName: user?.fullName!,
      },
      isImage: file ? true : false,
      imageUrl: file ? URL.createObjectURL(file) : '',
    };
    notificationAndSocketCtx.socket?.emit('sendMessage', {
      data: {
        senderId: user?.userId!,
        chatId: selectedChat,
        message: message,
        file: file
          ? {
              originalname: file?.name,
              size: file?.size,
              uri: base64file,
              mimetype: file?.type,
            }
          : undefined,
        isImage: file ? true : false,
      },
    });
    notificationAndSocketCtx.socket?.on('adminMessage', (res: {id: number}) => {
      newMessage['id'] = res.id;
    });
    const chatsHelper = [...chats];
    const chat = chats.find(chat => chat.id === selectedChat);
    if (!chat) {
      return;
    }

    const index = chatsHelper.indexOf(chat);
    chatsHelper.splice(index, 1);
    chatsHelper.unshift(chat);
    await setChats(chatsHelper);

    setImage(undefined);
    setFile(undefined);
    setMessage('');
    setMessages(prevState => [newMessage, ...prevState]);
    scrollToBottom();
  };

  const deleteMessage = async (messageId: number, clientId: number) => {
    try {
      notificationAndSocketCtx.socket?.emit('deleteMessage', {
        data: {messageId: messageId, clientId: clientId},
      });
      const messagesHelper = messages.filter(
        message => message.id !== messageId,
      );
      await setMessages(messagesHelper);
      if (messages.length < 7) {
        await getPaginatedChat(selectedChat);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  const onMessageReceived = (
    chatId: number,
    selectedChatId: number,
    message: string,
    isImage: boolean,
    imageUrl: string,
    userId: number,
    userFullName: string,
  ) => {
    const newMessage: MessageInterface = {
      payload: message!,
      createdAt: new Date().toISOString(),
      user: {
        id: userId ? userId : -1,
        fullName: userFullName ? userFullName : defaultUserFullName,
      },
      isImage,
      imageUrl,
      chatId,
    };

    const chatsHelper = [...chats];
    const chatToUnshift = chats.find(chat => chat.id === chatId);
    if (chatToUnshift) {
      const index = chatsHelper.indexOf(chatToUnshift);
      if (chatId !== selectedChatId) {
        chatToUnshift.haveUnreadMessages = true;
      }
      chatsHelper.splice(index, 1);
      chatsHelper.unshift(chatToUnshift);
      setChats(chatsHelper);
    }
    if (selectedChatId !== chatId) {
      return;
    }
    setMessages(prevState =>
      prevState.length > 0 ? [newMessage, ...prevState] : [newMessage],
    );
    notificationAndSocketCtx.setNotificationsCount(prevState => prevState + 1);
  };

  const handleSocketEvent = async (
    selectedChatId: number,
    userFullName: string | undefined,
    chatClientId: number,
  ) => {
    await socket.off('newMessage');
    await socket.on(
      'newMessage',
      (
        message: string,
        isImage: boolean,
        imageUrl: string,
        chatId: number,
        res: any,
      ) => {
        onMessageReceived(
          chatId,
          selectedChatId,
          message,
          isImage,
          imageUrl,
          chatClientId,
          userFullName!,
        );
      },
    );
  };

  const handleSocket = async (
    selectedChatId: number,
    clientIdFromSelectedChat?: number,
  ) => {
    await getLoggedInUser();
    const chat = chats.find(chat => chat.id === selectedChatId);
    const chatUserId = chat?.user?.id;
    setUserId(userId!);
    const userFullName = chat?.user?.fullName;
    await handleSocketEvent(
      selectedChatId,
      userFullName,
      clientIdFromSelectedChat ? clientIdFromSelectedChat : chatUserId!,
    );
  };

  const getAllChats = async () => {
    try {
      loaderHandler(loadingCtx, LoadingTypes.CHAT, true);
      const response = await getAllChatsRequest();
      await setChats(response);
      loaderHandler(loadingCtx, LoadingTypes.CHAT, false);
      if (response.length < 1) return;
      loaderHandler(loadingCtx, LoadingTypes.MESSAGE, true);
      if (!location.state) {
        await setSelectedChat(response[0]?.id);
        await setClientAvatar(response[0]?.user?.imageURL);
        await setClientFullName(response[0]?.user.fullName);
        await setClientId(response[0]?.user?.id);
        await getPaginatedChat(response[0]?.id);
      }
      loadingCtx.toggleAllChats(true);
      await handleSocket(response[0]?.id, response[0]?.user?.id);
      await readAllMessages(response[0]?.id, response);
      scrollToBottom();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      loaderHandler(loadingCtx, LoadingTypes.MESSAGE, false);
    }
  };

  const getChat = async (chatId: number) => {
    try {
      loaderHandler(loadingCtx, LoadingTypes.MESSAGE, true);
      loaderHandler(loadingCtx, LoadingTypes.MOBILE_MESSAGES, true);
      setMessage('');
      await setSelectedChat(chatId);
      const response = await getChatByIdRequest(chatId);
      await readAllMessages(chatId);
      setClientId(response.user.id);
      await handleSocket(chatId);
      await setClientAvatar(response?.user?.imageURL);
      await setClientFullName(response?.user?.fullName);
      await setMessages(response.messages ? response.messages : []);
      loadingCtx.toggleAllChats(false);
      scrollToBottom();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      loaderHandler(loadingCtx, LoadingTypes.MESSAGE, false);
      loaderHandler(loadingCtx, LoadingTypes.MOBILE_MESSAGES, false);
    }
  };

  const getPaginatedChat = async (chatId: number) => {
    try {
      loaderHandler(loadingCtx, LoadingTypes.LOAD_MESSAGES, true);
      const response = await getChatByIdRequest(chatId, messages.length);
      if (!location.state) {
        setSelectedChat(chatId);
      }
      const messagesToConcat = messages.slice();
      const reversedResponse: MessageInterface[] | undefined =
        response.messages;
      if (reversedResponse && reversedResponse.length > 0) {
        setMessages(messagesToConcat.concat(reversedResponse));
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      loaderHandler(loadingCtx, LoadingTypes.LOAD_MESSAGES, false);
    }
  };
  const handleFormChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    param: string,
  ) => {
    setReminder(prevState => {
      return (
        prevState && {
          ...prevState,
          [param]: event.target.value,
        }
      );
    });
  };

  const saveReminder = async () => {
    try {
      const reminderDto: ReminderRequestDto = {
        date: reminder?.date ? reminder.date : new Date(),
        content: reminder?.content ? reminder.content : '',
        time: reminder?.time ? reminder.time : '00:00',
        clientId: clientId,
        adminId: parseInt(localStorage.getItem('userId')!),
      };
      notificationAndSocketCtx.socket?.emit('sendReminder', {
        data: reminderDto,
      });
      toast.success(reminderAddedMessage);
      //TODO find cleaner way to reset state after saving reminder
      setReminder({} as ReminderRequestDto);
    } catch (error: any) {
      toast.error(error?.response?.data?.message[0]);
    } finally {
      setOpenModal(false);
    }
  };

  const isValidReminderForm =
    !!reminder?.content && reminder.content.trim().length > 0;

  const readAllMessages = async (
    chatId: number,
    allChats?: ChatInterface[],
  ) => {
    try {
      await setMessagesReadAtRequest({chatId: chatId});
      const chatIndex = (allChats ? allChats : chats).findIndex(
        chat => chat.id === chatId,
      );

      if (!chatIndex && chatIndex !== 0) return;

      setChats((prevState: ChatInterface[]) => {
        if (chatIndex > -1) prevState[chatIndex].haveUnreadMessages = false;
        return prevState;
      });
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  const getLoggedInUser = async () => {
    const user: BasicUserInfo = {
      userId: parseInt(localStorage.getItem('userId')!),
      fullName: localStorage.getItem('fullName')!,
      email: localStorage.getItem('email')!,
      pictureUrl: localStorage.getItem('avatarUrl')!,
    };

    setUser(user);
  };

  const openDeleteModalHandler = (message: MessageInterface) => {
    setSelectedMessage(message);
    toggleDeleteMessageModal(true);
  };

  const getChatsByUserName = async (name: string) => {
    if (name.trim().length < 3 && !areAllItemsRequested) {
      alreadyRequestedAllItems(true);
      await getAllChats();
    }
    if (name.trim().length > 2) {
      try {
        loaderHandler(loadingCtx, LoadingTypes.CHAT, true);
        alreadyRequestedAllItems(false);
        const response = await getAllChatsRequest(name);
        await setChats(response);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      } finally {
        loaderHandler(loadingCtx, LoadingTypes.CHAT, false);
      }
    }
  };

  useEffect(() => {
    setMessages([]);
    if (location.state && location.state.chatId) {
      getChat(location.state.chatId);
    }
    getAllChats();
    return () => socket.off('newMessage');
  }, [location.state]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'chat-container'}>
      {openModal && (
        <AddReminderModal
          handleFormChange={handleFormChange}
          openModal={openModal}
          setOpenModal={setOpenModal}
          saveReminder={saveReminder}
          isValidForm={isValidReminderForm}
          reminder={reminder}
        />
      )}
      {(!isMobile || (isMobile && loadingCtx.showAllChats)) && (
        <div className={'all-chats-container'}>
          <h1 className={'header'}>Chats</h1>
          <Search onChange={getChatsByUserName} placeholder={'Pretraži'} />
          {!loadingCtx.isLoading.chat &&
          !loadingCtx.isLoading.mobileMessages ? (
            <div className={'all-chats-wrapper'}>
              {chats.map(
                chat =>
                  chat.user && (
                    <ChatItem
                      key={'chat_item_' + chat.id}
                      chat={chat}
                      selectedChat={selectedChat}
                      setSelectedChat={getChat}
                    />
                  ),
              )}
            </div>
          ) : (
            <Loader chatLoader={true} />
          )}
        </div>
      )}
      <div className={'border'} />
      {(!isMobile || (isMobile && !loadingCtx.showAllChats)) && (
        <div className={'chat-container-fixed'}>
          <div className={'user-container'}>
            <div className={'user'}>
              {isMobile && (
                <img
                  className={'back-arrow-chat'}
                  src={defaultArrowIconUrl}
                  onClick={getAllChats}
                />
              )}
              {chats.length > 0 && (
                <div>
                  <img
                    src={
                      clientAvatar &&
                      !loadingCtx.isLoading.chat &&
                      !loadingCtx.isLoading.message
                        ? clientAvatar
                        : defaultAvatarImageUrl
                    }
                    alt={'user'}
                  />
                </div>
              )}
              <h2>
                {clientFullName &&
                !loadingCtx.isLoading.chat &&
                !loadingCtx.isLoading.message
                  ? clientFullName
                  : chats.length < 1 || loadingCtx.isLoading.chat
                  ? 'Ne postoji nijedan chat'
                  : 'Korisnik'}
              </h2>
            </div>
            <div className={'button-container'}>
              {
                <Button
                  disabled={chats.length < 1}
                  onClick={() => setOpenModal(true)}
                  className={'button-primary'}>
                  {addReminderTitle}
                </Button>
              }
            </div>
          </div>
          <div
            ref={scrollRef}
            id="scrollableDiv"
            className="messages-container">
            {!loadingCtx.isLoading.message ? (
              <InfiniteScroll
                dataLength={messages.length}
                next={() => getPaginatedChat(selectedChat)}
                className={'infinite-scroll'}
                hasMore={true}
                inverse={true}
                loader={
                  loadingCtx.isLoading.loadMessages &&
                  messages.length > 0 && <Loader moreMessageLoader={true} />
                }
                scrollableTarget="scrollableDiv">
                {messages &&
                  messages.length > 0 &&
                  messages?.map((message, index) => (
                    <Message
                      deleteMessage={deleteMessage}
                      deleteMessageModal={deleteMessageModal}
                      toggleDeleteMessageModal={toggleDeleteMessageModal}
                      selectedMessage={selectedMessage}
                      openDeleteModalHandler={openDeleteModalHandler.bind(
                        this,
                        message,
                      )}
                      messageRef={messageRef}
                      key={'chat_message_' + index}
                      userImage={
                        clientAvatar ? clientAvatar : defaultAvatarImageUrl
                      }
                      isSender={
                        message.user?.id !==
                        chats.find(chat => chat?.id === selectedChat)?.user?.id
                      }
                      message={message}
                      adminAvatar={user?.pictureUrl}
                      clientId={clientId}
                    />
                  ))}
              </InfiniteScroll>
            ) : (
              <Loader />
            )}
          </div>

          {chats.length > 0 &&
            !loadingCtx.isLoading.chat &&
            !loadingCtx.isLoading.message && (
              <div className={'message-input-container'}>
                <img
                  src={
                    user?.pictureUrl !== 'null'
                      ? user?.pictureUrl
                      : defaultAvatarImageUrl
                  }
                  alt={'admin'}
                />
                <TextField
                  placeholder={messageTextFieldPlaceHolder}
                  onKeyDown={event => {
                    if (event.which === submitFormOnEnter) {
                      sendMessage();
                    }
                  }}
                  className={'message-text-input'}
                  onChange={handleMessageInput}
                  value={message}
                />
                <div className={'icons-wrapper'}>
                  <img
                    src={defaultSendIconUrl}
                    onClick={sendMessage}
                    alt={'send'}
                  />
                  <FileUploader
                    handleChange={handleFileChange}
                    name="file"
                    maxSize={5}
                    types={fileTypes}
                    onSizeError={(file: File) =>
                      toast.error(maxImageSizeMessage)
                    }
                    children={
                      <img
                        src={defaultCameraIconUrl}
                        className={'icon-with-margin'}
                        alt={'camera'}
                      />
                    }
                  />
                </div>
                {image && (
                  <div className={'chat-image-wrapper'}>
                    <img
                      className={'close-icon'}
                      src={'/assets/close-icon.svg'}
                      onClick={() => {
                        setImage(undefined);
                        setFile(undefined);
                      }}
                      alt={'close'}
                    />
                    <img className={'main-image'} src={image} alt={'Main'} />
                  </div>
                )}
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export default Chat;
