import '../../styles/DraggableContainerStyle.scss';

import {Autocomplete, TextField} from '@mui/material';
import update from 'immutability-helper';
import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  FoodItem,
  FoodPlan,
  FoodRecipe,
  MealType,
  SelectedFoodPlan,
  SelectedFoodRecipes,
} from '../../common/models/food.interface';
import {
  SelectedExercisesHelper,
  SelectedTrainingForTrainingPlan,
  SelectedTrainingPlan,
  Training,
  TrainingPlan,
} from '../../common/models/training.interface';
import {CategoryInterface} from '../Food/AddFoodPlan';
import DraggableComponent from './DraggableComponent';
import {Types} from '../../utility/Helpers';
import {toast} from 'react-toastify';
import {isMobile} from 'react-device-detect';

export type DraggableComponentInterface = {
  id: number;
  label: string;
  type?: string;
  repeats?: string;
  timeBreak?: string;
  sets?: string;
  mealTypeId?: number;
  day?: string;
};

type DraggableContainerProps = {
  children?: JSX.Element;
  categories?:
    | Training[]
    | FoodRecipe[]
    | FoodPlan[]
    | SelectedFoodRecipes[]
    | TrainingPlan[]
    | SelectedExercisesHelper[]
    | FoodItem[]
    | SelectedTrainingForTrainingPlan[]
    | MealType[]
    | DraggableComponentInterface[]
    | SelectedTrainingPlan[]
    | SelectedFoodPlan[]
    | string[];
  placeholder?: string;
  setStateAction?: Dispatch<
    SetStateAction<TrainingPlan | string[] | undefined>
  >;
  handleFoodItems?: (item: FoodItem) => void;
  isExercise?: boolean;
  availableOptions?: CategoryInterface[];
  draggableComponents?: DraggableComponentInterface[] | undefined;
  setDraggableComponents?:
    | Dispatch<SetStateAction<DraggableComponentInterface[]>>
    | undefined;
  type?: string;
  mealTypes?: MealType[];
  setRecipeItems?: Dispatch<SetStateAction<string[]>>;
  isPackage?: boolean;
  isRecipe?: boolean;
};

const DraggableContainer: React.FC<DraggableContainerProps> = (
  props: DraggableContainerProps,
) => {
  const [options, setOptions] = useState<DraggableComponentInterface[]>([]);
  const [item, setItem] = useState<FoodItem>();
  const labelStyle = {width: isMobile ? '62vw !important' : '30vw'};
  const moveComponent = useCallback(
    async (dragIndex: number, hoverIndex: number) => {
      if (props.setDraggableComponents)
        await props.setDraggableComponents(
          (prevCards: DraggableComponentInterface[]) =>
            update(prevCards, {
              $splice: [
                [dragIndex, 1],
                [
                  hoverIndex,
                  0,
                  prevCards[dragIndex] as DraggableComponentInterface,
                ],
              ],
            }),
        );
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );
  const addOption = (value: DraggableComponentInterface) => {
    if (value) {
      if (props.setDraggableComponents) {
        if (props.type === Types.EXERCISE) {
          props.setDraggableComponents(prevState => [
            ...prevState,
            {
              id: value.id,
              label: value.label,
              type: value.type,
              timeBreak: '',
              sets: '',
              repeats: '',
            },
          ]);
        } else if (props.type === Types.RECIPE && props.mealTypes) {
          props.setDraggableComponents(prevState => [
            ...prevState,
            {
              id: value.id,
              label: value.label,
              type: value.type,
              mealTypeId: value.mealTypeId
                ? value.mealTypeId
                : props.mealTypes
                ? props.mealTypes[0]?.id
                : -1,
              day: value.day,
            },
          ]);
        } else {
          props.setDraggableComponents(prevState => [...prevState, value]);
        }
      }
      setOptions(prevState =>
        prevState.filter(option => option.id !== value.id),
      );
    }
  };

  const removePackageItem = (
    value: DraggableComponentInterface,
    prevState: DraggableComponentInterface[],
  ) => {
    let newState = prevState;
    for (const prev of prevState) {
      if (prev.id === value.id) {
        newState = prevState.filter(
          item => item.type !== value.type || item.id !== value.id,
        );
      }
    }
    return newState;
  };

  const removeItem = (value: DraggableComponentInterface) => {
    if (value) {
      if (props.setDraggableComponents) {
        if (props.isPackage) {
          props.setDraggableComponents(prevState =>
            removePackageItem(value, prevState),
          );
        } else {
          props.setDraggableComponents(prevState =>
            prevState.filter(item => item.id !== value.id),
          );
        }
      }

      setOptions(prevState => [...prevState, value]);
      if (props.setRecipeItems) {
        props.setRecipeItems((prevState: string[]) =>
          prevState.filter(item => item !== value.label),
        );
      }
    }
  };

  const handleInput = () => {
    if (!item || item?.label?.trim().length! < 1) {
      toast.error('Polje ne sme biti prazno!');
      return;
    }
    props.handleFoodItems && props.handleFoodItems(item!);
    props.setDraggableComponents &&
      props.setDraggableComponents(prevState => [...prevState, item!]);
    setItem({
      id: -1,
      label: '',
      type: Types.RECIPE,
    });
  };

  const renderDraggableComponent = useCallback(
    (
      draggableComponent: {
        id: number;
        label: string;
        repeats?: string;
        timeBreak?: string;
        sets?: string;
        mealTypeId?: number;
      },
      index: number,
    ) => {
      return (
        <Fragment key={'draggable_' + index}>
          {!props.isExercise && !props.mealTypes && (
            <DraggableComponent
              key={'component_' + index}
              index={index}
              children={props.children}
              id={draggableComponent.id}
              text={draggableComponent.label}
              moveComponent={moveComponent}
              removeItem={removeItem}
              type={props.type}
              draggableComponent={draggableComponent}
            />
          )}
          {props.type === Types.RECIPE && props.mealTypes && (
            <DraggableComponent
              key={'component_' + index}
              index={index}
              children={props.children}
              id={draggableComponent.id}
              text={draggableComponent.label}
              draggableComponent={draggableComponent}
              setDraggableComponent={props.setDraggableComponents}
              moveComponent={moveComponent}
              removeItem={removeItem}
              type={props.type}
              mealTypes={props.mealTypes}
              placeholder={props.placeholder}
            />
          )}
          {props.isExercise && (
            <DraggableComponent
              type={props.type}
              index={index}
              children={props.children}
              id={draggableComponent.id}
              text={draggableComponent.label}
              moveComponent={moveComponent}
              removeItem={removeItem}
              isExercise={props.isExercise}
              draggableComponent={draggableComponent}
            />
          )}
        </Fragment>
      );
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (props.availableOptions) {
      setOptions(props.availableOptions);
    }
    props.categories?.map((category, index) => {
      if (typeof category === 'string') {
        props.setDraggableComponents &&
          props.setDraggableComponents(prevState => [
            ...prevState,
            {
              label: category,
              id: index,
              type: Types.RECIPE,
            },
          ]);
      } else if ('exercise' in category) {
        props.setDraggableComponents &&
          props.setDraggableComponents(prevState => [
            ...prevState,
            {
              label: category.exercise?.name!,
              id: category.exercise?.id!,
              type: props.type,
              repeats: category.repetitions! + '',
              sets: category.setNumber! + '',
              timeBreak: category.rest + ''!,
            },
          ]);
      } else if ('training' in category) {
        props.setDraggableComponents &&
          props.setDraggableComponents(prevState => [
            ...prevState,
            {
              label: category.training?.name!,
              id: category.training?.id!,
              type: props.type,
            },
          ]);
      } else if ('foodPlan' in category) {
        props.setDraggableComponents &&
          props.setDraggableComponents(prevState => [
            ...prevState,
            {
              label: category.foodPlan?.name!,
              id: category.foodPlan?.id!,
              type: props.type,
            },
          ]);
      } else if ('trainingPlan' in category) {
        props.setDraggableComponents &&
          props.setDraggableComponents(prevState => [
            ...prevState,
            {
              label: category.trainingPlan?.name!,
              id: category.trainingPlan?.id!,
              type: props.type,
            },
          ]);
      } else {
        props.setDraggableComponents &&
          props.setDraggableComponents(prevState => [
            ...prevState,
            {
              label:
                'recipe' in category
                  ? category?.recipe?.name!
                  : 'label' in category
                  ? category?.label!
                  : category?.name!,
              id:
                category && 'recipe' in category
                  ? category.recipe.id!
                  : category.id!,
              type: props.type,
              mealTypeId: 'mealType' in category ? category.mealType?.id : -1,
              day: 'day' in category ? category.day : '',
            },
          ]);
      }
      return null;
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    props.setStateAction &&
      props.setStateAction(prevState => {
        return (
          prevState &&
          props.draggableComponents && {
            ...prevState,
            trainings: props.draggableComponents,
          }
        );
      });
  }, [props.draggableComponents]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={
        !props.placeholder
          ? 'draggable-container'
          : 'recipe-draggable-container'
      }>
      <div>
        {props.isRecipe &&
          props.draggableComponents &&
          props.draggableComponents.map((draggableComponent, i) => {
            return (
              props.type === draggableComponent.type &&
              renderDraggableComponent(draggableComponent, i)
            );
          })}
      </div>
      {!props.placeholder ? (
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          className={'autocomplete-box'}
          options={options}
          getOptionLabel={option => option.label}
          onChange={(event, value, index) => addOption(value!)}
          sx={{width: '30vw'}}
          renderInput={params => (
            <TextField {...params} label={'Pretraži po nazivu'} />
          )}
        />
      ) : (
        <div className={'draggable-input-container'}>
          <img
            src={'/assets/input-check-icon.svg'}
            onClick={handleInput}
            alt={''}
          />
          <TextField
            className={'autocomplete-box'}
            label={'Unesi naziv namirnice i količinu'}
            sx={labelStyle}
            value={item?.label || ''}
            onChange={event =>
              setItem({
                id: props.categories?.length! + 1,
                label: event.target.value,
                type: Types.RECIPE,
              })
            }
          />
        </div>
      )}
      <div>
        {!props.isRecipe &&
          props.draggableComponents &&
          props.draggableComponents.map((draggableComponent, i) => {
            return (
              props.type === draggableComponent.type &&
              renderDraggableComponent(draggableComponent, i)
            );
          })}
      </div>
    </div>
  );
};

export default DraggableContainer;
