import '../../styles/FilterStyle.scss';

import {isEqual} from 'lodash';
import React, {Dispatch, Fragment, SetStateAction, useState} from 'react';

import {FilterCategory} from '../Food/Food';
import {capitalizeLetters} from "../../utility/Helpers";

type FilterDataInterface = {
    id: number;
    name: string;
    type: string;
};

type FilterInterface = {
    name: string;
    filterType: string;
    filters: FilterDataInterface[];
};

type FilterProps = {
    setFilter: Dispatch<SetStateAction<FilterCategory[]>>;
    filterCategories: FilterInterface[];
};

const Filter: React.FC<FilterProps> = (props: FilterProps) => {
    const [filterDropdown, toggleFilterDropdown] = useState<string>("");
    const [checkedCategories, setCheckedCategories] = useState<FilterCategory[]>(
        []
    );

    const handleDropdown = (dropdownType: string) => {
        toggleFilterDropdown(dropdownType === filterDropdown ? "" : dropdownType);
    };

    const compareCategories = (category: FilterCategory) => {
        let isEqualCategory = false;
        checkedCategories.forEach((checkedCategory) => {
            if (isEqual(checkedCategory, category)) {
                isEqualCategory = true;
            }
        });
        return isEqualCategory;
    };

    const addCategory = async (category: FilterCategory) => {
        for (const checkedCategory of checkedCategories) {
            if (isEqual(checkedCategory, category)) {
                await setCheckedCategories((prevState) =>
                    prevState.filter(
                        (checkedCategory) => !isEqual(checkedCategory, category)
                    )
                );
                await props.setFilter((prevState) =>
                    prevState.filter(
                        (checkedCategory) => !isEqual(checkedCategory, category)
                    )
                );
                return;
            }
        }
        await setCheckedCategories((prevState) => [...prevState, category]);
        await props.setFilter((prevState) => [...prevState, category]);
    };

    return (
        <>
            <div className={"filter-wrapper"}>
                {checkedCategories.length < 1 && <p>Filteri nisu primenjeni</p>}
                {checkedCategories.length > 0 && (
                    <div className={"checked-categories-wrapper"}>
                        {checkedCategories.map((checkedCategory) => (
                            <div
                                key={
                                    "checked_category_" +
                                    checkedCategory.name +
                                    "_" +
                                    checkedCategory.id
                                }
                                className={"checked-category-container"}
                            >
                                <h2>{capitalizeLetters(checkedCategory.name)}</h2>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className={"filter-by-category-container"}>
                {props.filterCategories.map((categories, index) => (
                    <div
                        key={"filter_category_" + index}
                        className={"filter-category-container"}
                    >
                        <div
                            className={"filter-option-wrapper"}
                            onClick={handleDropdown.bind(this, categories.filterType)}
                        >
                            <h2 className={"filter-option-value"}>{categories.name}</h2>
                            <img
                                className={"filter-category-icon"}
                                src={"/assets/arrow-icon.svg"}
                                alt={""}
                            />
                        </div>
                        {filterDropdown === categories.filterType && (
                            <div className={"filter-dropdown"}>
                                {categories.filters.map((category, index) => (
                                    <div
                                        key={"category_" + index}
                                        className={"filter-option-container"}
                                        onClick={async () => await addCategory(category)}
                                    >
                                        <input
                                            type={"checkbox"}
                                            name={category.name}
                                            value={category.name}
                                            checked={compareCategories(category)}
                                            readOnly={true}
                                        />
                                        <h2>{capitalizeLetters(category.name)}</h2>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </>
    );
};

export default Filter;
