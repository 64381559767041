import '../styles/DrawerItemsStyle.scss';

import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {Collapse, List} from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

import {AuthContext} from '../store/AuthContext';
import {NotificationsAndSocketContext} from '../store/NotificationsAndSocketContext';
import {themeColor} from "../common/constants/Constants";
import {ThemeConfigContext} from "../store/ThemeConfigContext";
import {LoaderContext} from "../store/LoaderContex";
import {isMobile} from "react-device-detect";

type mainListItemsProps = {
    navigate: (screenName: string) => void;
};

export const MainListItems: React.FC<mainListItemsProps> = (
    props: mainListItemsProps
) => {
    const location = useLocation();
    const loadingCtx = useContext(LoaderContext);

    const helperContext = useContext(NotificationsAndSocketContext);
    const authCtx = useContext(AuthContext);
    const configContext = useContext(ThemeConfigContext);
    const [activeDrawerMenu, setActiveDrawerMenu] = useState("/");
    const [isFoodMenuOpened, openFoodMenu] = useState(false);
    const [isTrainingMenuOpened, openTrainingMenu] = useState(false);

    const handleClick = (clickedMenu: string) => {
        switch (clickedMenu) {
            case "food":
                openFoodMenu(!isFoodMenuOpened);
                break;
            case "recipes":
                openFoodMenu(true);
                break;
            case "trainings":
                openTrainingMenu(true);
                break;
            case "training-plans":
                openTrainingMenu(!isTrainingMenuOpened);
                break;
            case "logout":
                helperContext.socket?.close();
                authCtx.logout();
                break;
            default:
                return;
        }
    };
    const getIconStyle = (path: string): string => {
        return activeDrawerMenu === path ? configContext.userConfig.themeIconColor : configContext.userConfig.imageColor;
    };
    const getFontStyle = (path: string): string => {
        return activeDrawerMenu === path ? configContext.userConfig.themeFontColor : configContext.userConfig.fontColor;
    };

    useEffect(() => {
        setActiveDrawerMenu(location.pathname);
    }, [location.pathname]);

    return (
        <React.Fragment>
            <ListItemButton
                className={activeDrawerMenu === "/" ? "list-item-button-active" : ""}
                onClick={props.navigate.bind(this, "/")}
            >
                <ListItemIcon
                    style={{
                        color: activeDrawerMenu === "/" ? themeColor : "#9DA2AF",
                    }}
                >
                    <img src={"/assets/house-icon.svg"} alt={""}
                         style={{filter: getIconStyle("/")}}/>
                </ListItemIcon>
                <ListItemText
                    primary="Kontrolni panel"
                    style={{color: getFontStyle("/")}}
                />
            </ListItemButton>
            <ListItemButton
                className={
                    activeDrawerMenu === "/clients" ? "list-item-button-active" : ""
                }
                onClick={props.navigate.bind(this, "/clients")}
            >
                <ListItemIcon>
                    <img src={"/assets/client-icon.svg"} alt={""}
                         style={{filter: getIconStyle("/clients")}}/>
                </ListItemIcon>
                <ListItemText
                    primary="Klijenti"
                    style={{
                        color: getFontStyle("/clients"),
                    }}
                />
            </ListItemButton>
            <ListItemButton
                className={
                    activeDrawerMenu === "/packages" ? "list-item-button-active" : ""
                }
                onClick={props.navigate.bind(this, "/packages")}
            >
                <ListItemIcon>
                    <img src={"/assets/box-icon.svg"} alt={""}
                         style={{filter: getIconStyle("/packages")}}/>
                </ListItemIcon>
                <ListItemText
                    primary="Dodati paketi"
                    style={{
                        color: getFontStyle("/packages"),
                    }}
                />
            </ListItemButton>
            <ListItemButton
                className={
                    activeDrawerMenu === "/food" ? "list-item-button-active" : ""
                }
                onClick={() => {
                    handleClick("food");
                    props.navigate("/food");
                }}
            >
                <ListItemIcon>
                    <img src={"/assets/apple-icon.svg"} alt={""}
                         style={{filter: getIconStyle("/food")}}/>
                </ListItemIcon>
                <ListItemText
                    primary="Planovi ishrane"
                    style={{
                        color: getFontStyle("/food"),
                    }}
                />
                {isFoodMenuOpened ? <ExpandLess filter={configContext.userConfig.imageColor}/> :
                    <ExpandMore filter={configContext.userConfig.imageColor}/>}
            </ListItemButton>
            <Collapse in={isFoodMenuOpened} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton
                        sx={{pl: 4}}
                        className={
                            activeDrawerMenu === "/recipes" ? "list-item-button-active" : ""
                        }
                        onClick={() => {
                            handleClick("recipes");
                            props.navigate("/recipes");
                        }}
                    >
                        <ListItemIcon></ListItemIcon>
                        <ListItemText
                            primary="Recepti"
                            style={{
                                color: getFontStyle("/recipes"),
                            }}
                        />
                    </ListItemButton>
                </List>
            </Collapse>
            <ListItemButton
                className={
                    activeDrawerMenu === "/training-plans"
                        ? "list-item-button-active"
                        : ""
                }
                onClick={() => {
                    handleClick("training-plans");
                    props.navigate("/training-plans");
                }}
            >
                <ListItemIcon>
                    <img src={"/assets/weight-icon.svg"} alt={""}
                         style={{filter: getIconStyle("/training-plans")}}/>
                </ListItemIcon>
                <ListItemText
                    primary="Planovi treninga"
                    style={{
                        color: getFontStyle("/training-plans"),
                    }}
                />
                {isTrainingMenuOpened ? <ExpandLess filter={configContext.userConfig.imageColor}/> :
                    <ExpandMore filter={configContext.userConfig.imageColor}/>}
            </ListItemButton>
            <Collapse in={isTrainingMenuOpened} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton
                        className={
                            activeDrawerMenu === "/trainings" ? "list-item-button-active" : ""
                        }
                        sx={{pl: 4}}
                        onClick={() => {
                            handleClick("trainings");
                            props.navigate("/trainings");
                        }}
                    >
                        <ListItemIcon></ListItemIcon>
                        <ListItemText
                            primary="Treninzi"
                            style={{
                                color: getFontStyle("/trainings")
                            }}
                        />
                    </ListItemButton>
                    <ListItemButton
                        className={
                            activeDrawerMenu === "/exercises" ? "list-item-button-active" : ""
                        }
                        sx={{pl: 4}}
                        onClick={() => {
                            handleClick("exercises");
                            props.navigate("/exercises");
                        }}
                    >
                        <ListItemIcon></ListItemIcon>
                        <ListItemText
                            primary="Vežbe"
                            style={{
                                color: getFontStyle("/exercises")
                            }}
                        />
                    </ListItemButton>
                </List>
            </Collapse>
            <ListItemButton
                className={
                    activeDrawerMenu === "/chat" ? "list-item-button-active" : ""
                }
                onClick={() => {
                    handleClick("chat");
                    props.navigate("/chat");
                    isMobile && loadingCtx.toggleAllChats(true);
                }}
            >
                <ListItemIcon>
                    <img src={"/assets/chat-icon.svg"} alt={""}
                         style={{filter: getIconStyle("/chat")}}
                    />
                </ListItemIcon>
                <ListItemText
                    primary="Chat"
                    style={{
                        color: getFontStyle("/chat"),
                    }}
                />
            </ListItemButton>
            <ListItemButton
                className={
                    activeDrawerMenu === "/settings" ? "list-item-button-active" : ""
                }
                onClick={() => {
                    handleClick("settings");
                    props.navigate("/settings");
                }}
            >
                <ListItemIcon>
                    <img src={"/assets/settings-icon.svg"} alt={""}
                         style={{filter: getIconStyle("/settings")}}/>
                </ListItemIcon>
                <ListItemText
                    primary="Podešavanja"
                    style={{
                        color: getFontStyle("/settings"),
                    }}
                />
            </ListItemButton>
            <ListItemButton
                className={
                    activeDrawerMenu === "/logout" ? "list-item-button-active" : ""
                }
                onClick={() => {
                    handleClick("logout");
                    props.navigate("/login");
                }}
            >
                <ListItemIcon>
                    <img src={"/assets/log-out.svg"} alt={""}
                         style={{filter: getIconStyle("/logout")}}/>
                </ListItemIcon>
                <ListItemText
                    primary="Izlogujte se"
                    style={{
                        color: getFontStyle("/logout"),
                    }}
                />
            </ListItemButton>
        </React.Fragment>
    );
};

export default MainListItems;
