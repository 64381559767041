import {dateTransformerForSubscription, Types} from '../../../utility/Helpers';
import {SelectedFoodPlanResponseDTO} from '../../dtos/food.interface.dto';
import {
    PackageFormResponseDTO,
    PackageResponseDTO,
    SubscriptionResponseDTO,
} from '../../dtos/subscription.interface.dto';
import {SelectedFoodPlan} from '../../models/food.interface';
import {
    PackageInterface,
    PackageFormInterface,
    Subscription,
    ClientSubscriptionInterface
} from '../../models/subscription.interface';
import {fromFoodPlanResponseDTOToFoodPlanDetail} from './foodInputDataTransformer';

export function fromSubscriptionResponseDTOToSubscription(
    subscriptionResponseDTO: SubscriptionResponseDTO[],
): Subscription[] {
    return subscriptionResponseDTO.map(subscription => ({
        id: subscription.id,
        dateFrom: subscription.dateFrom,
        subscriptionPackage: fromPackageResponseDTOToPackageDetail(
            subscription.subscriptionPackage,
        ),
        dateTo: subscription.dateTo,
        price: subscription.price,
        paymentDate: subscription.paymentDate,
        amount: subscription.amount,
        additionalComment: subscription.additionalComment,
        paymentCode: subscription.paymentCode,
    }));
}

function monthDiff(dateFrom: Date, dateTo: Date) {
    let months;
    months = (dateTo.getFullYear() - dateFrom.getFullYear()) * 12;
    months -= dateFrom.getMonth();
    months += dateTo.getMonth();

    return months <= 0 ? 0 : months;
}

export function fromSubscriptionResponseDTOToClientSubPackage(
    subscriptionResponseDTO: SubscriptionResponseDTO,
): ClientSubscriptionInterface {
    return {
        id: subscriptionResponseDTO.id,
        subscriptionPackage: fromPackageResponseDTOToPackageDetail(
            subscriptionResponseDTO.subscriptionPackage,
        ),
        amount: subscriptionResponseDTO.amount,
        status: subscriptionResponseDTO.status,
        dateFrom: new Date(subscriptionResponseDTO.dateFrom),
        dateTo: new Date(subscriptionResponseDTO.dateTo),
        duration: monthDiff(new Date(subscriptionResponseDTO.dateFrom), new Date(subscriptionResponseDTO.dateTo)),
        paymentDate: subscriptionResponseDTO.paymentDate
    }
}

export function fromSubscriptionResponseDTOToSubscriptionTable(
    subscriptionResponseDTO: SubscriptionResponseDTO[]
): Subscription[] {
    return subscriptionResponseDTO.map(subscription => ({
        id: subscription.id,
        dateFrom: subscription.dateFrom,
        amount: subscription.amount,
        dateTo: subscription.dateTo,
        subscriptionPackage: subscription.subscriptionPackage
            ? fromPackageResponseDTOToPackageDetail(subscription.subscriptionPackage)
            : undefined,
        paymentCode: subscription.paymentCode,
        paymentDate: subscription.paymentDate,
        status: subscription.status,
        additionalComment: subscription.additionalComment
    }))
}

export function fromSelectedFoodPlanResponseDTOToSelectedFoodPlan(
    selectedFoodPlanResponseDTO: SelectedFoodPlanResponseDTO[],
): SelectedFoodPlan[] {
    return selectedFoodPlanResponseDTO.map(selected => ({
        id: selected.id,
        foodPlan: fromFoodPlanResponseDTOToFoodPlanDetail(selected.foodPlan),
    }));
}

export function fromPackageResponseDTOToPackageDetail(
    packageResponseDTO: PackageResponseDTO,
): PackageInterface {
    return {
        id: packageResponseDTO.id,
        description: packageResponseDTO.description,
        name: packageResponseDTO.name,
        demo: packageResponseDTO.demo,
        price: packageResponseDTO.price,
        packagePrices: packageResponseDTO.packagePrices,
        duration: packageResponseDTO.duration,
        amountEur: packageResponseDTO.amountEur,
        packageCategoryId: packageResponseDTO.packageCategory?.id,
        available: packageResponseDTO.available,
        type: Types.PACKAGE,
        selectedFoodPlan: packageResponseDTO.selectedFoodPlan,
        selectedTrainingPlan: packageResponseDTO.selectedTrainingPlan,
        pictureUrl: packageResponseDTO.pictureUrl
    };
}

export function fromPackageResponseDTOToPackage(
    packageResponseDTO: PackageResponseDTO[],
): PackageInterface[] {
    return packageResponseDTO.map(subPackage => ({
        id: subPackage.id,
        description: subPackage.description,
        name: subPackage.name,
        price: subPackage.price,
        amountEur: subPackage.amountEur,
        packagePrices: subPackage.packagePrices,
        duration: subPackage.duration,
        type: Types.PACKAGE,
        available: subPackage.available,
        categoryId: subPackage.packageCategory?.id,
    }));
}

export function fromPackageResponseDTOToPackageForm(
    packageResponseDTO: PackageFormResponseDTO[],
): PackageFormInterface[] {
    return packageResponseDTO.map(packageForm => ({
        available: packageForm.available,
        category: packageForm.category,
        createdAt: dateTransformerForSubscription(packageForm.createdAt),
        description: packageForm.description,
        duration: packageForm.duration,
        packagePrices: packageForm.packagePrices,
        id: packageForm.id,
        name: packageForm.name,
        numOfFoodPlans: parseInt(packageForm.numoffoodplans),
        numOfTrainingPlans: parseInt(packageForm.numoftrainingplans),
        price: packageForm.price,
    }))
}


