import {createContext, useState} from 'react';
import {ThemeConfigInterface} from "../common/models/context.interface";
import {UserConfig} from "../common/models/user.interface";

const ThemeConfigContext = createContext(
    {} as ThemeConfigInterface
);

const {Provider} = ThemeConfigContext;
const ThemeConfigProvider = ({children}: any) => {
    const [userConfig, setUserConfig] = useState<UserConfig>({} as UserConfig);
    return (
        <Provider value={{
            userConfig,
            setUserConfig
        }}>
            {children}
        </Provider>
    );
};
export {ThemeConfigContext, ThemeConfigProvider};