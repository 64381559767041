import React from 'react';

type ActionMenuProps = {
    params: any;
    onClickDelete?: (id: number) => void;
    isDashboard?: boolean;
    openChat?: (chatId: number) => void;
    onClick?: () => void;
};

const ActionMenu: React.FC<ActionMenuProps> = (props: ActionMenuProps) => {
    const editActionMenuLabel = "Izmeni";
    const deleteActionMenuLabel = "Obriši";
    return (
        <div className={"action-menu-container"}>
            {!props.isDashboard && (
                <>
                    <p>{editActionMenuLabel}</p>
                    <p
                        onClick={(event) => {
                            event.stopPropagation();
                            props.onClick && props.onClick()
                        }}
                    >
                        {deleteActionMenuLabel}
                    </p>
                </>
            )}
            {props.isDashboard && (
                <img
                    onClick={() => props.openChat && props.openChat!(props.params.row.chatId)}
                    src={"/assets/message-icon.png"}
                    alt={""}
                />
            )}
        </div>
    );
};

export default ActionMenu;
