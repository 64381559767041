import {
    SubscriptionRequestDTO,
    SubscriptionResponseDTO,
    UpdateSubscriptionAmountRequestDto
} from '../common/dtos/subscription.interface.dto';
import {post, get, put} from './utilities/http';
import {SUBSCRIPTION_URL, USER_URL} from './utilities/urls';

export async function insertSubscriptionRequest(
    userId: number,
    data: SubscriptionRequestDTO,
): Promise<SubscriptionRequestDTO> {
    return await post(`${USER_URL}/${userId}/subscriptions`, data);
}

export async function updateSubscriptionRequest(
    userId: number,
    subscriptionId: number,
    data: SubscriptionRequestDTO,
): Promise<SubscriptionRequestDTO> {
    return await put(`${USER_URL}/${userId}/subscriptions/${subscriptionId}`, data)
}

export async function updateSubscriptionAmountRequest(userId: number, subscriptionId: number, data: UpdateSubscriptionAmountRequestDto): Promise<UpdateSubscriptionAmountRequestDto> {
    return await put(`${USER_URL}/${userId}/subscriptions/${subscriptionId}/amount`, data);
}


export async function getSubscriptionRequest(
    userId: number,
): Promise<SubscriptionResponseDTO[]> {
    try {
        return await get(`${USER_URL}/${userId}${SUBSCRIPTION_URL}`);
    } catch (error: any) {
        throw error;
    }
}

export async function getSubscriptionByIdRequest(
    subscriptionId: number,
): Promise<SubscriptionResponseDTO> {
    try {
        return await get(`${USER_URL}${SUBSCRIPTION_URL}/${subscriptionId}`);
    } catch (error: any) {
        throw error;
    }
}
