import '../../styles/AddRecipeStyle.scss';

import {Button, TextareaAutosize, TextField} from '@mui/material';
import {ChangeEvent, useEffect, useState, useContext} from 'react';
import {isMobile} from 'react-device-detect';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {FileUploader} from 'react-drag-drop-files';
import {useLocation, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

import {
  getRecipeDetails,
  insertRecipeRequest,
  updateRecipeRequest,
} from '../../api/food.api';
import {fromFoodRecipeResponseDTOToFoodRecipeDetails} from '../../common/data-tansformers/input/foodInputDataTransformer';
import {FoodItem, FoodRecipe} from '../../common/models/food.interface';
import {isPositiveValueForm, loaderHandler, Mode} from '../../utility/Helpers';
import BackArrow from '../UI/BackArrow';
import DraggableContainer, {
  DraggableComponentInterface,
} from '../UI/DraggableContainer';
import RecipeImageSelect from './RecipeImageSelect';
import ConfirmationModal from '../UI/ConfirmationModal';
import {LoaderContext} from '../../store/LoaderContex';
import {
  ConfirmationModalMode,
  LoadingTypes,
} from '../../common/enums/common.enums';
import Loader from '../Loader/Loader';

const AddRecipe = () => {
  const loadingCtx = useContext(LoaderContext);
  const location = useLocation();
  const addRecipeTitle = 'Dodaj novi recept';
  const editRecipeTitle = 'Izmeni recept';
  const fileTypes = ['JPG', 'PNG'];
  const saveAddRecipeTitle = 'Sačuvaj';
  const uploadRecipeTitle = 'Dodavanje recepta...';

  const navigate = useNavigate();

  const [error, setError] = useState<string>();
  const [file, setFile] = useState<File>();
  const [foodRecipe, setFoodRecipe] = useState<FoodRecipe>({} as FoodRecipe);
  const [foodItems, setFoodItems] = useState<FoodItem[]>();
  const [recipeItems, setRecipeItems] = useState<string[]>([]);
  const [draggableComponents, setDraggableComponents] = useState<
    DraggableComponentInterface[]
  >([]);
  const [modal, toggleModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const getFoodRecipe = async () => {
    try {
      loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
      if (location.state.recipeId) {
        const foodItemsArray: FoodItem[] = [];
        const recipeItemsHelper: string[] = [];
        const response = await getRecipeDetails(location.state.recipeId);
        const recipe = fromFoodRecipeResponseDTOToFoodRecipeDetails(response);
        setFoodRecipe(recipe);
        recipe.listOfFood.forEach((item, index) => {
          foodItemsArray.push({
            id: index,
            label: item,
            type: 'recipe',
          });
          recipeItemsHelper.push(item);
        });
        setFoodItems(foodItemsArray);
        setRecipeItems(recipeItemsHelper);
      } else {
        setFoodItems([]);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
    }
  };
  const isValidForm =
    !!foodRecipe.name &&
    foodRecipe.name.trim().length > 0 &&
    foodRecipe.cookingTime !== undefined &&
    foodRecipe.cookingTime >= 0 &&
    foodRecipe.calories !== undefined &&
    foodRecipe.calories >= 0 &&
    foodRecipe.carbohydrates !== undefined &&
    foodRecipe.carbohydrates >= 0 &&
    foodRecipe.proteins !== undefined &&
    foodRecipe.proteins >= 0 &&
    foodRecipe.fats !== undefined &&
    foodRecipe.fats >= 0 &&
    foodItems?.length! > 0 &&
    recipeItems.length! > 0 &&
    !!foodRecipe.guide &&
    foodRecipe.guide.trim().length > 0 &&
    (!!file || !!foodRecipe.pictureUrl || selectedImage.length > 0);

  const insertOrUpdateRecipe = async () => {
    try {
      loaderHandler(loadingCtx, LoadingTypes.UPLOAD, true);
      if (!isPositiveValueForm(isValidForm)) return;

      const formData = new FormData();
      formData.append('name', foodRecipe.name);
      formData.append('cookingTime', JSON.stringify(foodRecipe.cookingTime));
      formData.append(
        'carbohydrates',
        JSON.stringify(foodRecipe.carbohydrates),
      );
      formData.append('proteins', JSON.stringify(foodRecipe.proteins));
      formData.append('fats', JSON.stringify(foodRecipe.fats));
      formData.append('listOfFood', JSON.stringify(recipeItems));
      formData.append('calories', JSON.stringify(foodRecipe.calories));
      formData.append('guide', foodRecipe.guide);
      formData.append('fileTypes', '[{"type":"image"}]');
      formData.append('imageUrl', foodRecipe.pictureUrl);
      if (location.state) {
        switch (location.state.mode) {
          case 'ADD':
            await insertRecipeRequest(formData);
            toast.success('Recept je uspešno dodat.');
            navigate('/recipes');
            break;
          case 'EDIT':
            await updateRecipeRequest(location.state.recipeId, formData);
            toast.success('Recept je uspešno izmenjen.');
            navigate('/recipes');
            setOpenModal(false);
            break;
          default:
            break;
        }
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      loaderHandler(loadingCtx, LoadingTypes.UPLOAD, false);
    }
  };

  const onSaveClickHandler = () => {
    if (!location.state) {
      return;
    }
    location.state.mode === 'ADD' ? insertOrUpdateRecipe() : setOpenModal(true);
  };

  const handleChange = (file: File) => {
    setFile(file);
    setError(undefined);
    setFoodRecipe(prevState => {
      return (
        prevState && {
          ...prevState,
          pictureUrl: URL.createObjectURL(file),
        }
      );
    });
  };

  const handleFoodItems = (item: FoodItem) => {
    setFoodItems(prevState => prevState && [...prevState, item]);
    setRecipeItems(prevState => [...prevState, item.label]);
  };

  const handleFormChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    param: string,
  ) => {
    setFoodRecipe((prevState: FoodRecipe) => {
      return {
        ...prevState,
        [param]:
          event.target.value?.length > 0 ? event.target.value : undefined,
      };
    });
  };

  useEffect(() => {
    getFoodRecipe();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return !loadingCtx.isLoading.common ? (
    <div className={'add-food-card-container'}>
      {openModal && (
        <ConfirmationModal
          dispatchFunction={insertOrUpdateRecipe}
          closeModal={setOpenModal}
          mode={ConfirmationModalMode.EDIT}
        />
      )}
      {modal && (
        <RecipeImageSelect
          setFoodRecipe={setFoodRecipe}
          setSelectedImage={setSelectedImage}
          toggleModal={toggleModal}
        />
      )}
      <div className={'header-container'}>
        <BackArrow onClick={() => navigate('/recipes')} />
        <h1 className={'add-food-name'}>
          {location.state.mode === Mode.EDIT ? editRecipeTitle : addRecipeTitle}
        </h1>
      </div>
      <div className={'card-container'}>
        <h2 className={'add-food-subtitle'}>Osnovni podaci</h2>
        <div className={'form-container'}>
          <TextField
            label={'Naziv'}
            className={'form-text-input'}
            value={foodRecipe?.name ? foodRecipe.name : ''}
            style={{width: isMobile ? 'unset' : '36vw'}}
            onChange={event => handleFormChange(event, 'name')}
          />
          <div className={'input-fields-row'}>
            <TextField
              value={
                foodRecipe?.cookingTime !== undefined
                  ? foodRecipe.cookingTime
                  : ''
              }
              onChange={event => handleFormChange(event, 'cookingTime')}
              label={'Priprema (u minutima)'}
              type={'number'}
              className={'form-text-input-small'}
              style={{width: isMobile ? 'unset' : '11vw'}}
              inputProps={{min: 0}}
            />
            <TextField
              value={
                foodRecipe?.calories !== undefined ? foodRecipe.calories : ''
              }
              onChange={event => handleFormChange(event, 'calories')}
              type={'number'}
              label={'Kalorije'}
              className={'form-text-input-small'}
              style={{width: isMobile ? 'unset' : '11vw'}}
              inputProps={{min: 0}}
            />
            <TextField
              value={
                foodRecipe?.carbohydrates !== undefined
                  ? foodRecipe.carbohydrates
                  : ''
              }
              onChange={event => handleFormChange(event, 'carbohydrates')}
              type={'number'}
              label={'Hidrati'}
              className={'form-text-input-small'}
              style={{width: isMobile ? 'unset' : '11vw'}}
              inputProps={{min: 0}}
            />
          </div>
          <div className={'input-fields-row'}>
            <TextField
              value={
                foodRecipe?.proteins !== undefined ? foodRecipe.proteins : ''
              }
              onChange={event => handleFormChange(event, 'proteins')}
              type={'number'}
              label={'Proteini'}
              className={'form-text-input-small'}
              style={{width: isMobile ? 'unset' : '17vw'}}
              inputProps={{min: 0}}
            />
            <TextField
              value={foodRecipe?.fats !== undefined ? foodRecipe.fats : ''}
              onChange={event => handleFormChange(event, 'fats')}
              type={'number'}
              label={'Masti'}
              className={'form-text-input-small'}
              style={{width: isMobile ? 'unset' : '17vw'}}
              inputProps={{min: 0}}
            />
          </div>
        </div>
      </div>
      {/* second form */}
      <div className={'small-form-container'}>
        <h2 className={'add-food-subtitle'}>Priprema</h2>
        <div className={'recipe-form-container'}>
          <div className={'recipe-draggable-wrapper'}>
            <DndProvider context={window} backend={HTML5Backend}>
              {((foodItems && location.state.mode === 'EDIT') ||
                location.state.mode === 'ADD') && (
                <DraggableContainer
                  handleFoodItems={handleFoodItems}
                  categories={foodItems}
                  type={'recipe'}
                  placeholder={'Unesi naziv namirnice i količinu'}
                  setDraggableComponents={setDraggableComponents}
                  draggableComponents={draggableComponents}
                  setRecipeItems={setRecipeItems}
                />
              )}
            </DndProvider>
          </div>
          <TextareaAutosize
            className={'text-area'}
            placeholder={'Uputstvo za pripremu'}
            value={foodRecipe?.guide}
            onChange={event => handleFormChange(event, 'guide')}
          />
        </div>
      </div>
      {/* third form */}
      <div className={'small-form-container'}>
        <h2 className={'add-food-subtitle'}>Slika</h2>
        <div className={'small-form-buttons'}>
          <div className={'button-container'}>
            <Button
              className={'button-primary'}
              onClick={toggleModal.bind(this, true)}>
              Izaberi
            </Button>
          </div>
          <div className={'file-uploader-container'}>
            <FileUploader
              handleChange={handleChange}
              name="file"
              label={'Dodaj ili prevuci sliku'}
              types={fileTypes}
              maxSize={10}
              onSizeError={(file: any) =>
                setError('Slika ne sme biti veća od 10mb.')
              }
            />
            {error && <p style={{color: 'red'}}>{error}</p>}
          </div>
        </div>
      </div>
      {(foodRecipe?.pictureUrl || selectedImage.length > 0) && (
        <img
          className={'cover-image'}
          src={foodRecipe.pictureUrl}
          alt={'recipe'}
        />
      )}
      <div className={'button-container'}>
        <Button
          disabled={isValidForm && loadingCtx.isLoading.upload}
          onClick={onSaveClickHandler}
          className={'button-primary'}>
          {!loadingCtx.isLoading.upload
            ? saveAddRecipeTitle
            : uploadRecipeTitle}
        </Button>
      </div>
    </div>
  ) : (
    <Loader />
  );
};
export default AddRecipe;
