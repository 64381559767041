import React, {useState} from 'react';
import {capitalize} from '@mui/material';

type TrainingVideosProps = {
  onClick: (src: string) => void;
  src: string;
  selectedVideo: string;
};

const TrainingVideos = ({onClick, src, selectedVideo}: TrainingVideosProps) => {
  const newVideoName = src.split('video/')[1].split('.')[0];
  const [isActive, setIsActive] = useState(false);

  const onClickHandler = () => {
    if (src === selectedVideo) {
      setIsActive(false);
      onClick('');
      return;
    }
    setIsActive(true);
    onClick(src);
  };

  return (
    <>
      <div
        onClick={onClickHandler}
        className={
          isActive && selectedVideo === src ? 'selected' : 'videoWrapper'
        }>
        <video>
          <source src={src} type="video/mp4" />
        </video>
        <h5>{capitalize(newVideoName)}</h5>
      </div>
    </>
  );
};

export default TrainingVideos;
