import '../../styles/SearchStyle.scss';

import React, {ChangeEvent} from 'react';

type SearchProps = {
    placeholder?: string;
    onChange: (name: string) => void;
};

const Search: React.FC<SearchProps> = (props: SearchProps) => {
    return (
        <div className={"search-wrapper"}>
            <img src={"/assets/search-icon.svg"} className={"search-icon"} alt={""}/>
            <input
                className={"search-input"}
                onChange={(
                    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                    props.onChange(event.currentTarget.value);
                }}
                placeholder={props.placeholder}
            />
        </div>
    );
};

export default Search;
