import {
    Notification,
    NotificationsCount,
} from '../../models/notification.interface';
import {
    NotificationResponseDTO,
    NotificationsCountResponseDTO,
} from '../../dtos/notification.interface.dto';

export function fromNotificationResponseDTOToNotification(
    notificationResponseDTO: NotificationResponseDTO[],
): Notification[] {
    return notificationResponseDTO.map(notification => ({
        fullName: notification.sender?.fullName,
        imageUrl: notification.sender?.imageURL,
        type: notification.type,
        readAt: notification.read_at,
        text: notification.text,
        id: notification.id,
        chatId: notification.sender?.chat?.id,
        senderId: notification.sender.id
    }));
}

export function fromNotificationsCountResponseDTOToNotificationsCount(
    notificationsCountResponseDto: NotificationsCountResponseDTO,
): NotificationsCount {
    return {
        count: notificationsCountResponseDto.count,
    };
}
