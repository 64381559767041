import React from 'react';
import '../styles/PageNotFoundStyle.scss';

const PageNotFound = () => {
    return (
        <div className={'page-not-found-container'}>
            <h1>404 Not Found</h1>
        </div>
    );
}

export default PageNotFound;
