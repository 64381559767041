import Modal from '../UI/Modal';
import RecipeImage from "./RecipeImage";
import '../../styles/RecipeImageSelect.scss';
import {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {getRecipeImagesRequest} from "../../api/food.api";
import {toast} from "react-toastify";
import {LoaderContext} from "../../store/LoaderContex";
import React from "react";
import {FoodRecipe} from "../../common/models/food.interface";
import {loaderHandler} from "../../utility/Helpers";
import {LoadingTypes} from "../../common/enums/common.enums";


const chooseImage = 'Izaberi sliku';
const cancelRecipeImageSelection = 'Otkaži';
const saveRecipeImageSelection = 'Sačuvaj';

type RecipeImageSelectProps = {
    toggleModal: Dispatch<SetStateAction<boolean>>;
    setSelectedImage: Dispatch<SetStateAction<string>>
    setFoodRecipe?: Dispatch<SetStateAction<FoodRecipe>>;
}

const RecipeImageSelect = (props: RecipeImageSelectProps) => {
    const loadingCtx = useContext(LoaderContext);
    const [selectedImage, setSelectedImage] = useState<string>('');
    const [recipeImages, setRecipeImages] = useState<string[]>([]);
    const selectImageHandler = (src: string) => {
        setSelectedImage(src);
    };

    const getRecipeImages = async () => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.UPLOAD, true);
            const recipeImageResponse = await getRecipeImagesRequest();
            setRecipeImages(recipeImageResponse);
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.UPLOAD, false);
        }
    };

    const closeModal = () => {
        props.toggleModal(false)
        setSelectedImage('')
    };

    const saveModalSelection = () => {
        if (selectedImage.length < 1) {
            toast.error(chooseImage);
            return
        }
        props.setSelectedImage(selectedImage)
        props.setFoodRecipe && props.setFoodRecipe((prevState) => {
            return (
                prevState && {
                    ...prevState,
                    pictureUrl: selectedImage,
                }
            );
        });
        props.toggleModal(false)
    }

    useEffect(() => {
        getRecipeImages();
    }, [])

    return (
        <Modal onClick={props.toggleModal.bind(this, false)}>
            <div>
                <h3>{chooseImage}</h3>
                <div className={'imageContainer'}>
                    <div className={'images'}>
                        {

                            recipeImages.map((imageUrl, index) => (
                                <RecipeImage
                                    key={`${imageUrl}_${index}`}
                                    src={imageUrl}
                                    onClick={selectImageHandler}
                                    selectedImage={selectedImage}
                                />
                            ))
                        }
                    </div>
                </div>
                <div className={'modal-button-container'}>
                    <button onClick={closeModal}>{cancelRecipeImageSelection}</button>
                    <button onClick={saveModalSelection}>{saveRecipeImageSelection}</button>
                </div>
            </div>
        </Modal>

    )
}

export default RecipeImageSelect;