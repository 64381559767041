import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {useContext, useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import {resetPasswordVerifyRequest} from "../api/auth.api";
import 'react-toastify/dist/ReactToastify.css';
import {LoaderContext} from "../store/LoaderContex";
import {emailRegex, loaderHandler} from "../utility/Helpers";
import {LoadingTypes} from "../common/enums/common.enums";

const theme = createTheme();

export default function ForgotPassword() {
    const [email, setEmail] = useState<string>();
    const loadingCtx = useContext(LoaderContext);
    const sendEmailRequestTitle = " Pošalji zahtev na email";
    const loadingEmailRequestTitle = "Slanje zahteva...";
    const invalidEmailTitle = "Email nije validan!"
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
            if (!email?.match(emailRegex)) {
                toast.error(invalidEmailTitle)
                return;
            }
            await resetPasswordVerifyRequest({email: email});
            toast.success('Zahtev za promenu lozinke je uspešno poslat.');

        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                    <Typography component="h1" variant="h5">
                        Zaboravljana lozinka
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Adresa"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email ? email : ''}
                            onChange={(event) => setEmail(event.currentTarget.value)}
                        />
                        <Button
                            disabled={loadingCtx.isLoading.common}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}>
                            {!loadingCtx.isLoading.common ? sendEmailRequestTitle : loadingEmailRequestTitle}
                        </Button>
                        <Grid container textAlign={'center'}>
                            <Grid item xs>
                                <Link href="/login" variant="body2" color={'#000000'}>
                                    Vrati se na login stranicu?
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <ToastContainer position={'bottom-right'} autoClose={2000}/>
            </Container>
        </ThemeProvider>
    );
}
