import {createContext, useState} from 'react';
import {LoaderContextInterface, LoadingInterface} from "../common/models/context.interface";

const LoaderContext = createContext(
    {} as LoaderContextInterface
);
const {Provider} = LoaderContext;
const LoaderProvider = ({children}: any) => {
    const isVipApp = process.env.REACT_APP_IS_VIP_APP === 'true';
    const [isLoading, toggleIsLoading] = useState<LoadingInterface>({} as LoadingInterface);
    const [showAllChats, toggleAllChats] = useState<boolean>(true);
    return (
        <Provider value={{
            isVipApp,
            isLoading,
            toggleIsLoading,
            showAllChats,
            toggleAllChats
        }}>
            {children}
        </Provider>
    );
};

export {LoaderContext, LoaderProvider};
