import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import TextField, {TextFieldProps} from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {useContext, useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import {resetPasswordRequest} from "../api/auth.api";
import {ResetPasswordRequestDTO} from "../common/dtos/auth.interface.dto";
import 'react-toastify/dist/ReactToastify.css';
import {LoadingTypes, UserRoles} from "../common/enums/common.enums";
import {LoaderContext} from "../store/LoaderContex";
import {loaderHandler} from "../utility/Helpers";

const theme = createTheme();

export default function ResetPassword() {
    const resetPasswordButtonText = "Resetuj lozinku";
    const resetPasswordTitle = "Resetovanje lozinke";
    const successMessage = 'Lozinka je uspešno promenjena. Možete se ulogovati na aplikaciju sa novom lozinkom.';

    const location = useLocation();
    const navigate = useNavigate();

    const newPasswordRef = useRef<TextFieldProps>();
    const confirmPasswordRef = useRef<TextFieldProps>();

    const [token, setToken] = useState<string>();
    const [error, setError] = useState<string>();
    const [hideForm, setHideForm] = useState(false);
    const loadingCtx = useContext(LoaderContext);
    const handleSubmit = async () => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
            if (!token) return;

            if (
                !newPasswordRef.current?.value ||
                !confirmPasswordRef.current?.value ||
                newPasswordRef.current?.value === "" ||
                confirmPasswordRef?.current.value === ""
            ) {
                setError("Oba polja moraju biti popunjena.");
                return;
            }

            if (newPasswordRef.current?.value !== confirmPasswordRef.current?.value) {
                setError("Lozinke se moraju podudarati.");
                return;
            }

            setError(undefined);

            const resetPasswordRequestDTO: ResetPasswordRequestDTO = {
                newPassword: newPasswordRef.current?.value as string,
                confirmedPassword: newPasswordRef.current?.value as string,
                token: token,
            }
            const response = await resetPasswordRequest(resetPasswordRequestDTO);
            if (response === UserRoles.ADMIN) {
                navigate('/login');
            }
            setHideForm(true);
            toast.success('Uspešno promenjena lozinka.');
        } catch (error: any) {
            toast.error(error.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
        }
    };

    useEffect(() => {
        let queryParam = location.search.split("?token=")[1];
        if (!queryParam) {
            navigate("/page-not-found");
            return;
        }
        setToken(queryParam);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                    <Typography component="h1" variant="h5">
                        {resetPasswordTitle}
                    </Typography>
                    {!hideForm && <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                        <TextField
                            inputRef={newPasswordRef}
                            margin="normal"
                            required
                            type={"password"}
                            fullWidth
                            id="password"
                            label="Nova lozinka"
                            name="password"
                            autoFocus
                        />
                        <TextField
                            inputRef={confirmPasswordRef}
                            margin="normal"
                            required
                            type={"password"}
                            fullWidth
                            id="confirm-password"
                            label="Potvrdi lozinku"
                            name="confirm-password"
                            autoFocus
                        />
                        {error && <h2 style={{fontSize: '14px', color: 'darkred'}}>{error}</h2>}
                        <Button
                            type={'button'}
                            fullWidth
                            onClick={handleSubmit}
                            variant="contained"
                            sx={{mt: 3, mb: 2}}>
                            {resetPasswordButtonText}
                        </Button>
                    </Box>}
                    {hideForm &&
                        <Typography component="h3" variant="subtitle1"
                                    style={{width: '100%', marginTop: '100px', textAlign: 'center'}}>
                            {successMessage}
                        </Typography>}
                </Box>
                <ToastContainer position={'bottom-right'} autoClose={2000}/>
            </Container>
        </ThemeProvider>
    );
}
