export const LOGIN_URL = '/login';
export const REGISTRATION_URL = '/register';
export const RESET_PASSWORD_REQUEST_URL = '/reset_password_request';
export const RESET_PASSWORD_URL = '/reset_password';
export const LOGOUT_URL = '/logout-admin';
export const CHATS_URL = '/chats';
export const MESSAGE_URL = `${CHATS_URL}/message`;
export const RECIPES_URL = '/recipes';
export const FOOD_PLANS_URL = '/food-plans';
export const FOOD_RECIPE_URL = '/recipes';
export const FOOD_RECIPE_IMAGES = '/recipes/s3/gallery';
export const TRAINING_VIDEOS = '/exercises/s3/video';
export const TRAINING_PLANS_URL = '/training-plans';
export const TRAININGS_URL = '/trainings';
export const EXERCISES_URL = '/exercises';
export const USER_URL = '/users';
export const DAILY_ACTIVITY_URL = '/activities';
export const FINISHED_TRAININGS_URL = '/done-trainings';
export const NOTIFICATIONS_URL = '/notifications';
export const REFRESH_TOKEN_URL = '/refresh-token';
export const PACKAGES_URL = '/packages';
export const PACKAGE_CATEGORIES_URL = '/package-categories';
export const SUBSCRIPTION_URL = '/subscriptions';
export const WEIGHT_PROGRESS_URL = '/activities/progress';
export const IMAGE_FROM_VIDEO_URL = '/exercises/s3/video/image';
