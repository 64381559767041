import {useState} from "react";

type RecipeImageProps = {
    onClick: (src: string) => void
    src: string
    selectedImage: string
}


const RecipeImage = ({onClick, src, selectedImage}: RecipeImageProps) => {
    const [isActive, setIsActive] = useState(false);

    const onClickhandler = () => {
        if (src === selectedImage) {
            setIsActive(false);
            onClick('');
            return;
        }
        setIsActive(true);
        onClick(src);
    }

    return <div onClick={onClickhandler}
                className={isActive && selectedImage === src ? 'selected ' : 'imageWrapper'}>
        <img
            src={src}/>
    </div>
}

export default RecipeImage;

