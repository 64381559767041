import {createContext, useContext} from 'react';
import axiosInstance from '../api/utilities/axios';
import {AuthContext} from './AuthContext';

const AxiosContext = createContext({});

const {Provider} = AxiosContext;

const AxiosProvider = ({children}: any) => {
  const authContext = useContext(AuthContext);

  axiosInstance.interceptors.request.use(
    config => {
      if (config.headers && !config.headers?.Authorization) {
        // @ts-ignore
          config.headers = {
          Authorization: `Bearer ${authContext.getAccessToken()}`,
        };
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    },
  );

  return <Provider value={{axiosInstance: axiosInstance}}>{children}</Provider>;
};

export {AxiosContext, AxiosProvider};
