import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './App';
import {AuthProvider} from './store/AuthContext';
import {AxiosProvider} from './store/AxiosContext';
import {NotificationsProvider} from './store/NotificationsAndSocketContext';
import {LoaderProvider} from "./store/LoaderContex";
import {ThemeConfigProvider} from "./store/ThemeConfigContext";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

root.render(
    <AuthProvider>
        <AxiosProvider>
            <LoaderProvider>
                <ThemeConfigProvider>
                    <NotificationsProvider>
                        <App/>
                    </NotificationsProvider>
                </ThemeConfigProvider>
            </LoaderProvider>
        </AxiosProvider>
    </AuthProvider>,
);

reportWebVitals();
