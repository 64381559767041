import {
    FoodPlanResponseDTO,
    FoodRecipeResponseDTO,
    MealTypeResponseDTO,
} from '../common/dtos/food.interface.dto';
import {deleteReq, get, post, put} from './utilities/http';
import {FOOD_PLANS_URL, FOOD_RECIPE_IMAGES, RECIPES_URL} from './utilities/urls';

export async function getMealTypesRequest(): Promise<MealTypeResponseDTO[]> {
    try {
        return await get("meal-types");
    } catch (error: any) {
        throw error;
    }
}

export async function getRecipeImagesRequest(): Promise<string[]> {
    try {
        return await get(FOOD_RECIPE_IMAGES);
    } catch (error: any) {
        throw error;
    }
}

export async function getFoodPlansRequest(
    name?: string
): Promise<FoodPlanResponseDTO[]> {
    try {
        return await get(`${FOOD_PLANS_URL}${name ? `?name=${name}` : ""}`);
    } catch (error: any) {
        throw error;
    }
}

export async function getFoodPlanDetailsRequest(
    foodPlanId: number
): Promise<FoodPlanResponseDTO> {
    try {
        return await get(`${FOOD_PLANS_URL}/${foodPlanId}`);
    } catch (error: any) {
        throw error;
    }
}

export async function insertFoodPlanRequest(data: any) {
    return await post(FOOD_PLANS_URL, data);
}

export async function updateFoodPlanRequest(id: number, data: any) {
    return await put(`${FOOD_PLANS_URL}/${id}`, data);
}

export async function getRecipesRequest(
    name?: string
): Promise<FoodRecipeResponseDTO[]> {
    try {
        return await get(`${RECIPES_URL}${name ? `?name=${name}` : ""}`);
    } catch (error: any) {
        throw error;
    }
}

export async function getRecipeDetails(
    recipeId: number
): Promise<FoodRecipeResponseDTO> {
    try {
        return await get(`${RECIPES_URL}/${recipeId}`);
    } catch (error: any) {
        throw error;
    }
}

export async function insertRecipeRequest(data: any) {
    return await post(RECIPES_URL, data);
}

export async function updateRecipeRequest(recipeId: number, data: FormData) {
    return await put(`${RECIPES_URL}/${recipeId}`, data);
}

export async function deleteFoodPlanRequest(foodPlanId: number) {
    return await deleteReq(`${FOOD_PLANS_URL}/${foodPlanId}`);
}

export async function deleteRecipeRequest(recipeId: number) {
    return await deleteReq(`${RECIPES_URL}/${recipeId}`);
}


