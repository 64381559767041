import {
    ExerciseResponseDTO,
    SelectedTrainingsResponseDTO,
    SelectedTrainingsResponseDTOForTrainingPlan,
    TrainingDiaryResponseDTO,
    TrainingPlanCategoryDTO,
    TrainingPlanResponseDTO,
    TrainingResponseDTO,
    TrainingResponseDTOForTrainingPlan,
    TrainingTypeResponseDTO,
} from '../../dtos/training.interface.dto';
import {
    Exercise,
    SelectedTraining,
    SelectedTrainingForTrainingPlan,
    Training,
    TrainingDiary,
    TrainingForTrainingPlan,
    TrainingPlan,
    TrainingPlanCategory,
    TrainingType,
} from '../../models/training.interface';
import {Types} from "../../../utility/Helpers";

export function fromTrainingPlanResponseDTOToTrainingPlan(
    trainingPlanResponseDTO: TrainingPlanResponseDTO[],
): TrainingPlan[] {
    return trainingPlanResponseDTO.map(trainingPlan => ({
        selectedTrainings: fromSelectedTrainingsResponseDTOToSelectedTrainings(
            trainingPlan.selectedTrainings,
        ),
        name: trainingPlan.name,
        pictureUrl: trainingPlan.pictureUrl,
        isUniversal: trainingPlan.universal,
        description: trainingPlan.description,
        category: fromTrainingPlanCategoryDtoToTrainingPlanCategory([
            trainingPlan.selectedTrainingPlanCategory,
        ])[0]!,
        numOfTrainings: trainingPlan.selectedTrainings?.length,
        id: trainingPlan.id,
    }));
}

export function fromSingleTrainingPlanResponseDTOToTrainingPlan(
    trainingPlan: TrainingPlanResponseDTO,
): TrainingPlan {
    return {
        selectedTrainings: fromSelectedTrainingsResponseDTOToSelectedTrainings(
            trainingPlan.selectedTrainings,
        ),
        name: trainingPlan.name,
        pictureUrl: trainingPlan.pictureUrl,
        isUniversal: trainingPlan.universal,
        description: trainingPlan.description,
        category: fromTrainingPlanCategoryDtoToTrainingPlanCategory([
            trainingPlan.selectedTrainingPlanCategory,
        ])[0],
        numOfTrainings: trainingPlan.selectedTrainings?.length,
        id: trainingPlan.id,
    };
}


export function fromTrainingResponseDTOToTraining(
    trainingResponseDTO: TrainingResponseDTO[],
): Training[] {
    return trainingResponseDTO.map(training => ({
        id: training.id,
        name: training.name,
        pictureUrl: training.pictureUrl,
        trainingType: fromTrainingTypeResponseDTOToTrainingType([
            training.trainingType,
        ])[0],
        approxTime: training.approxTime,
        description: training.description,
        selectedExercises: training.selectedExercises,
        numOfExercises: training.selectedExercises
            ? training.selectedExercises.length
            : 0,
    }));
}

export function fromExerciseResponseDTOToExercise(
    exerciseResponseDTO: ExerciseResponseDTO[],
): Exercise[] {
    return exerciseResponseDTO.map(exercise => ({
        id: exercise.id,
        name: exercise.name,
        pictureUrl: exercise.pictureUrl,
        guide: exercise.guide,
        rest: exercise.rest,
        description: exercise.description,
        repetitions: exercise.repetitions,
        setNumber: exercise.setNumber,
        videoUrl: exercise.videoUrl,
    }));
}

export function fromTrainingPlanCategoryDtoToTrainingPlanCategory(
    trainingCategoryDto: TrainingPlanCategoryDTO[],
): TrainingPlanCategory[] {
    return trainingCategoryDto.map(category => ({
        id: category.id,
        label: category.name,
        type: Types.TRAINING,
        name: category.name,
    }));
}

export function fromTrainingTypeResponseDTOToTrainingType(
    trainingTypes: TrainingTypeResponseDTO[],
): TrainingType[] {
    return trainingTypes.map(type => ({
        name: type.name,
        id: type.id,
        type: Types.TRAINING_TYPE,
    }));
}

export function fromSelectedTrainingsResponseDTOToSelectedTrainingsForTrainingPlan(
    selectedTrainings: SelectedTrainingsResponseDTOForTrainingPlan[],
): SelectedTrainingForTrainingPlan[] {
    if (selectedTrainings.length < 1) {
        return [];
    } else {
        return selectedTrainings.map(selected => ({
            id: selected.id,
            order: selected.order,
            training: fromTrainingResponseDTOToTrainingForTrainingPlan([
                selected.training,
            ])[0],
        }));
    }
}

export function fromTrainingResponseDTOToTrainingForTrainingPlan(
    trainingResponseDTO: TrainingResponseDTOForTrainingPlan[],
): TrainingForTrainingPlan[] {
    return trainingResponseDTO.map(training => ({
        id: training.id,
        name: training.name,
        pictureUrl: training.pictureUrl,
        trainingType: fromTrainingTypeResponseDTOToTrainingType([
            training.trainingType!,
        ])[0],
        approxTime: training.approxTime,
        description: training.description,
    }));
}

export function fromSelectedTrainingsResponseDTOToSelectedTrainings(
    selectedTrainings: SelectedTrainingsResponseDTO[],
): SelectedTraining[] {
    if (selectedTrainings.length < 1) {
        return [];
    }
    return selectedTrainings.map(selected => ({
        id: selected.id,
        order: selected.order,
        training: fromTrainingResponseDTOToTraining([selected.training])[0],
    }));

}

export function fromTrainingDiaryResponseDTOToTrainingDiary(
    trainingDiary: TrainingDiaryResponseDTO[],
): TrainingDiary[] {
    return trainingDiary.map(diary => ({
        date: diary.date,
        comment: diary.comment,
        id: diary.id,
        rating: diary.rate,
        name: diary.training?.name,
    }));
}
