import {Autocomplete, Button, TextField} from '@mui/material';
import {isNumber} from 'lodash';
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useContext,
} from 'react';
import {toast} from 'react-toastify';

import {getPackagesRequest} from '../../api/package.api';
import {
  getSubscriptionByIdRequest,
  insertSubscriptionRequest,
  updateSubscriptionRequest,
} from '../../api/subscription.api';
import {
  fromPackageResponseDTOToPackage,
  fromSubscriptionResponseDTOToClientSubPackage,
} from '../../common/data-tansformers/input/subscriptionInputDataTransformer';
import {SubscriptionRequestDTO} from '../../common/dtos/subscription.interface.dto';
import {
  ClientSubscriptionInterface,
  PackageInterface,
} from '../../common/models/subscription.interface';
import {isFormValid, loaderHandler} from '../../utility/Helpers';
import BackArrow from '../UI/BackArrow';
import {LoaderContext} from '../../store/LoaderContex';
import {LoadingTypes} from '../../common/enums/common.enums';
import Loader from '../Loader/Loader';

type PackageFormProps = {
  setClientSection: Dispatch<SetStateAction<string>>;
  clientId: number | undefined;
  clientFullName: string | undefined;
  packages: PackageInterface[] | undefined;
  subscriptionId?: number;
  subPackage: ClientSubscriptionInterface;
  setSubPackage: Dispatch<SetStateAction<ClientSubscriptionInterface>>;
};

const PackageForm: React.FC<PackageFormProps> = (props: PackageFormProps) => {
  const loadingCtx = useContext(LoaderContext);
  const editSubscriptionTitle = 'Izmeni pretplatu';
  const addSubscriptionTitle = 'Dodaj novu pretplatu';
  const addNewSubscriptionTitle = 'Dodavanje nove pretplate...';
  const saveSubscriptionTitle = 'Sačuvaj';

  const packageDurationOptions = [
    {id: 1, label: '1 mesec'},
    {id: 2, label: '3 meseca'},
    {id: 3, label: '6 meseci'},
  ];

  const statusOptions = [
    {id: 1, label: 'Obrada'},
    {id: 2, label: 'Proknjižena'},
    {id: 3, label: 'Poništena'},
  ];

  const [packageOptions, setPackageOptions] = useState<
    {label: string; id: number}[]
  >([]);
  const [shouldBeRendered, toggleShouldBeRendered] = useState(true);

  const [subPackage, setSubPackage] = useState<ClientSubscriptionInterface>(
    {} as ClientSubscriptionInterface,
  );
  const [packages, setPackages] = useState<PackageInterface[]>([]);

  const handleFormChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    param: string,
  ) => {
    props.setSubPackage((prevState: ClientSubscriptionInterface) => {
      return {
        ...prevState,
        [param]: event.target.value,
      };
    });
  };

  const isValidForm =
    !!props.subPackage.dateTo &&
    !!props.subPackage.subscriptionPackage?.id &&
    !!props.subPackage;

  const insertSubscription = async () => {
    try {
      loaderHandler(loadingCtx, LoadingTypes.UPLOAD, true);
      if (!isFormValid(isValidForm)) return;

      const subscriptionRequestDTO: SubscriptionRequestDTO = {
        dateFrom: props.subPackage.dateFrom
          ? props.subPackage.dateFrom
          : new Date(),
        additionalComment: '',
        duration: props.subPackage.duration,
        status: props.subPackage.status,
        subscriptionPackageId: props.subPackage.subscriptionPackage?.id!,
      };

      props.subscriptionId
        ? await updateSubscriptionRequest(
            props.clientId!,
            props.subscriptionId!,
            subscriptionRequestDTO,
          )
        : await insertSubscriptionRequest(
            props.clientId!,
            subscriptionRequestDTO,
          );
      toast.success(
        props.subscriptionId
          ? 'Pretplata uspešno promenjena'
          : 'Pretplata uspešno dodata.',
      );
      props.setClientSection('edit-client');
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      loaderHandler(loadingCtx, LoadingTypes.UPLOAD, false);
    }
  };

  const getPackages = async () => {
    try {
      loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
      const response = await getPackagesRequest();
      let transformedPackages = fromPackageResponseDTOToPackage(response);

      setPackages(transformedPackages);

      transformedPackages.forEach(transformedPackage => {
        setPackageOptions(prevState => [
          ...prevState,
          {
            id: transformedPackage.id!,
            label: transformedPackage.name!,
          },
        ]);
      });
    } catch (error: any) {
      toast.error(error.response?.data?.message);
    } finally {
      loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
    }
  };

  const checkMonth = (month: number) => (month < 10 ? '0' : '');
  const checkDay = (day: number) => (day < 10 ? `0${day}` : day);
  const addMonth = (month: number, addNumberOfMonths: number) =>
    month + addNumberOfMonths > 12
      ? addNumberOfMonths - (12 - month)
      : month + addNumberOfMonths;
  const addYear = (month: number, year: number, addNumberOfMonths: number) =>
    month + addNumberOfMonths > 12 ? year + 1 : year;

  const updateSubscriptionDate = (date: string, numberOfMonths: number) => {
    let dateArray = date.split('-');
    props.setSubPackage({
      ...props.subPackage,
      duration: numberOfMonths,
      dateTo: new Date(
        `${addYear(
          parseInt(dateArray[1]),
          parseInt(dateArray[0]),
          numberOfMonths,
        )}-${checkMonth(parseInt(dateArray[1]))}${addMonth(
          parseInt(dateArray[1]),
          numberOfMonths,
        )}-${checkDay(parseInt(dateArray[2]))}`,
      ),
    });
  };

  const changeDateTo = (value: {id: number; label: string} | null) => {
    let numberOfMonths;
    switch (value?.id) {
      case 2:
        numberOfMonths = 3;
        break;
      case 3:
        numberOfMonths = 6;
        break;
      default:
        numberOfMonths = 1;
        break;
    }

    if (value)
      updateSubscriptionDate(
        props.subPackage.dateFrom
          ? new Date(props.subPackage.dateFrom).toISOString().split('T')[0]
          : new Date().toISOString().split('T')[0],
        numberOfMonths,
      );
  };

  const getSubscriptionById = async () => {
    try {
      loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
      const response = await getSubscriptionByIdRequest(props.subscriptionId!);
      await props.setSubPackage(
        fromSubscriptionResponseDTOToClientSubPackage(response),
      );
    } catch (error: any) {
      toast.error(error.response?.data?.message);
    } finally {
      loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
    }
  };
  const handleDateChange = (
    event: React.SyntheticEvent,
    value: {id: number; label: string} | null,
  ) => {
    toggleShouldBeRendered(false);
    changeDateTo(value);
  };
  const handleSubPackageChange = (
    event: React.SyntheticEvent,
    value: {id: number; label: string} | null,
  ) => {
    if (!value) {
      props.setSubPackage(prevState => {
        return {
          ...prevState,
          subscriptionPackage: {} as PackageInterface,
        };
      });
      return;
    }
    toggleShouldBeRendered(false);
    props.setSubPackage(prevState => {
      return {
        ...prevState,
        subscriptionPackage: packages.find(
          element => element.id === value?.id,
        )!,
      };
    });
  };

  useEffect(() => {
    getPackages();
    props.subscriptionId && getSubscriptionById();
    !props.subscriptionId &&
      shouldBeRendered &&
      props.setSubPackage({} as ClientSubscriptionInterface);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return !loadingCtx.isLoading.common ? (
    <div className={'client-card-container'}>
      <div className={'header-container'}>
        <div className={'page-name-container'}>
          <BackArrow
            onClick={() => {
              props.setClientSection('edit-client');
            }}
          />
          <h1 className={'client-name'}>
            {props.subscriptionId
              ? editSubscriptionTitle
              : addSubscriptionTitle}
          </h1>
        </div>
      </div>
      <div className={'card-container'}>
        <h2 className={'client-subtitle'}>Osnovni podaci</h2>
        <div className={'form-container'}>
          <TextField
            placeholder={'Ime i prezime klijenta'}
            disabled={true}
            value={props.clientFullName}
            className={'form-text-input'}
          />
          <div className={'form-text-input-wrapper'}>
            <TextField
              label={'Datum od'}
              type={'date'}
              value={
                props.subPackage.dateFrom
                  ? new Date(props.subPackage.dateFrom)
                      .toISOString()
                      .split('T')[0]
                  : new Date().toISOString().split('T')[0]
              }
              onChange={event => handleFormChange(event, 'dateFrom')}
              placeholder={'01.01.2023.'}
              className={'form-text-input-small'}
            />
            <TextField
              label={'Datum do'}
              type={'date'}
              value={
                props.subPackage.dateTo
                  ? props.subPackage.dateTo.toISOString().split('T')[0]
                  : new Date().toISOString().split('T')[0]
              }
              disabled={true}
              onChange={event => handleFormChange(event, 'dateTo')}
              placeholder={'01.01.2023.'}
              className={'form-text-input-small'}
            />
          </div>
          {((!props.subscriptionId && packageDurationOptions) ||
            (props.subscriptionId &&
              isNumber(props.subPackage.duration) &&
              props.subPackage.duration > 0)) && (
            <Autocomplete
              disablePortal
              clearIcon={null}
              id="combo-box-demo"
              value={
                props.subPackage.duration
                  ? packageDurationOptions.find(
                      duration =>
                        parseInt(duration.label) === props.subPackage.duration,
                    )
                  : null
              }
              onChange={handleDateChange}
              options={packageDurationOptions}
              sx={{width: '30vw'}}
              renderInput={params => (
                <TextField {...params} label={'Izaberi trajanje paketa'} />
              )}
            />
          )}
        </div>
      </div>
      {/*first form*/}
      <div className={'small-form-container'}>
        <h2 className={'client-subtitle'}>Paket</h2>
        <div>
          {((!props.subscriptionId && packageOptions) ||
            (props.subscriptionId && props.subPackage.subscriptionPackage)) && (
            <Autocomplete
              disablePortal
              clearIcon={null}
              id="combo-box-demo"
              value={
                props.subPackage.subscriptionPackage &&
                packageOptions.length > 0
                  ? packageOptions.find(
                      element =>
                        element.id === props.subPackage.subscriptionPackage.id,
                    )
                  : {
                      label: '',
                      id: -1,
                    }
              }
              onChange={(event, value) => handleSubPackageChange(event, value)}
              options={packageOptions}
              getOptionLabel={option => (option.label ? option.label : '')}
              sx={{width: '30vw'}}
              renderInput={params => (
                <TextField {...params} label={'Izaberi paket'} />
              )}
            />
          )}
        </div>
      </div>
      <div className={'small-form-container'}>
        <h2 className={'client-subtitle'}>Status</h2>
        <div>
          {packageOptions && (
            <Autocomplete
              clearIcon={null}
              disablePortal
              id="combo-box-demo"
              value={
                props.subPackage.status
                  ? statusOptions.find(
                      status => status.label === props.subPackage.status,
                    )
                  : statusOptions[0]
              }
              onChange={(event, value) =>
                value &&
                props.setSubPackage(prevState => {
                  return {
                    ...prevState,
                    status: value.label,
                  };
                })
              }
              options={statusOptions}
              getOptionLabel={option => (option.label ? option.label : '')}
              sx={{width: '30vw'}}
              renderInput={params => (
                <TextField {...params} label={'Promeni status'} />
              )}
            />
          )}
        </div>
      </div>
      <div className={'button-container'}>
        {props.subscriptionId && (
          <Button
            className={'button-with-stroke'}
            onClick={props.setClientSection.bind(this, 'pay-slip')}>
            Generisi uplatnicu
          </Button>
        )}
        <Button
          disabled={isValidForm && loadingCtx.isLoading.upload}
          onClick={insertSubscription}
          className={'button-primary'}>
          {!loadingCtx.isLoading.upload
            ? saveSubscriptionTitle
            : addNewSubscriptionTitle}
        </Button>
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default PackageForm;
