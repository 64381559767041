import NotificationItem from './NotificationItem';
import '../../styles/NotificationsStyle.scss';
import {toast} from 'react-toastify';
import {
    getNotificationsRequest, markAllNotificationsAsReadRequest,
    readNotificationRequest,
} from '../../api/notification.api';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {Notification} from '../../common/models/notification.interface';
import {
    fromNotificationResponseDTOToNotification
} from '../../common/data-tansformers/input/notificationInputDataTransformer';
import {useNavigate} from 'react-router-dom';
import {NotificationsAndSocketContext} from "../../store/NotificationsAndSocketContext";
import {NotificationType} from "../../common/enums/common.enums";
import {CHAT_SCREEN, CLIENTS_SCREEN} from "../../common/constants/ScreenNames";
import Loader from "../Loader/Loader";
import InfiniteScroll from "react-infinite-scroll-component";

const Notifications = () => {
    const notificationCtx = useContext(NotificationsAndSocketContext);
    const navigate = useNavigate();
    const markAllAsReadLabel = 'Označi sve kao pročitano';
    const scrollRef = useRef<any>();

    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [isLoading, toggleIsLoading] = useState(true);
    const [notificationsToShow, setNotificationsToShow] = useState<Notification[]>([]);
    const getAllNotifications = async () => {
        try {
            toggleIsLoading(true);
            const response = await getNotificationsRequest();
            const notificationsArray: Notification[] = [];
            const transformedResponse = fromNotificationResponseDTOToNotification(response);
            setNotifications(transformedResponse);
            for (const [index, notification] of transformedResponse.entries()) {
                if (index === 5) break;
                notificationsArray.push(notification);
            }
            setNotificationsToShow(notificationsArray);
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        } finally {
            toggleIsLoading(false);
        }
    };
    const listContainsNotification = (notifications: Notification[], notificationForCheck: Notification) => {
        for (const notification of notifications) {
            if (notification.id === notificationForCheck.id) {
                return true;
            }
        }
        return false;
    }
    const loadMoreNotifications = () => {
        let count = 0;
        for (const notification of notifications) {
            if (count >= 5) {
                break;
            }
            if (!listContainsNotification(notificationsToShow, notification)) {
                setNotificationsToShow(prevState => [...prevState, notification]);
                count++;
            }
        }
    }

    const readNotification = async (notification: Notification) => {
        try {
            if (!notification) return;
            if (notification.type === NotificationType.NEW_MESSAGE || notification.type === NotificationType.REMINDER) {
                navigate(CHAT_SCREEN, {
                    state: {chatId: notification.chatId},
                });
            } else {
                navigate(CLIENTS_SCREEN, {state: {clientId: notification.senderId}})
            }
            if (!notification.readAt) {
                await readNotificationRequest(notification.id);
                notificationCtx.setNotificationsCount(prevState => prevState - 1);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
    };

    const markAllNotificationsAsRead = async () => {
        try {
            await markAllNotificationsAsReadRequest();
            const notificationsHelper = [...notifications];
            notificationsHelper.forEach(notification => {
                if (!notification.readAt) {
                    notification.readAt = new Date();
                }
            })
            setNotifications(notificationsHelper);
            notificationCtx.setNotificationsCount(0);
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
    }

    useEffect(() => {
        getAllNotifications();
    }, []);

    return (
        <div className={'notifications-container'}>
            <div ref={scrollRef} id="notificationScroll" className={'notifications'}>
                <InfiniteScroll next={() => loadMoreNotifications()} hasMore={true} loader={null}
                                scrollableTarget="notificationScroll"
                                dataLength={notificationsToShow.length}>
                    {!isLoading ? notifications &&
                        notificationsToShow.length > 0 &&
                        notificationsToShow.map((notification, index) => (
                            <NotificationItem
                                key={`${notification.id}_${index}_notification}`}
                                notification={notification}
                                isRead={!!notification.readAt}
                                onClick={() => readNotification(notification)}
                            />

                        )) : (<Loader isNotificationsLoader={true}/>)}
                    {notifications && notifications.length < 1 && !isLoading && (
                        <h3>No new notifications</h3>
                    )}
                </InfiniteScroll>
            </div>
            <div className={'read-all-notifications-container'} onClick={markAllNotificationsAsRead}>
                {notifications.length > 0 &&
                    <h4 className={'read-all-notifications-text'}>{markAllAsReadLabel}</h4>
                }
            </div>
        </div>
    );
};
export default Notifications;
