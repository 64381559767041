import '../../styles/AddFoodPlanStyle.scss';

import {Autocomplete, Button, TextareaAutosize, TextField} from '@mui/material';
import React, {ChangeEvent, useEffect, useState, useContext} from 'react';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {FileUploader} from 'react-drag-drop-files';
import {useLocation, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

import {
  getFoodPlanDetailsRequest,
  getMealTypesRequest,
  getRecipesRequest,
  insertFoodPlanRequest,
  updateFoodPlanRequest,
} from '../../api/food.api';
import {
  fromFoodPlanResponseDTOToFoodPlanDetail,
  fromFoodRecipeResponseDTOToFoodRecipe,
  fromMealTypeResponseDTOToMealType,
} from '../../common/data-tansformers/input/foodInputDataTransformer';
import {FoodPlan, MealType} from '../../common/models/food.interface';
import {
  capitalizeLetters,
  isFormValid,
  IsUniversal,
  loaderHandler,
  Mode,
  Types,
} from '../../utility/Helpers';
import BackArrow from '../UI/BackArrow';
import {LoaderContext} from '../../store/LoaderContex';
import {
  ConfirmationModalMode,
  LoadingTypes,
} from '../../common/enums/common.enums';
import DraggableContainer, {
  DraggableComponentInterface,
} from '../UI/DraggableContainer';
import ConfirmationModal from '../UI/ConfirmationModal';
import Loader from '../Loader/Loader';

export type CategoryInterface = {
  label: string;
  id: number;
  type: string;
  mealTypeId?: number;
};

export interface SelectedRecipeDTO {
  [id: number]: number;

  mealTypeId: number;
  day?: string;
}

const AddFoodPlan = () => {
  const location = useLocation();
  const loadingCtx = useContext(LoaderContext);
  const fileTypes = ['JPG', 'PNG'];
  const options = [
    {label: 'UNIVERZALAN', id: 1, type: 'isUniversal'},
    {label: 'NIJE UNIVERZALAN ', id: 2, type: 'isUniversal'},
  ];

  const addFoodPlanTitle = 'Dodaj novi plan ishrane';
  const editFoodPlanTitle = 'Izmeni plan ishrane';
  const saveFoodPlanTitle = 'Sačuvaj';
  const uploadFoodPlanTitle = 'Dodavanje plana...';

  const navigate = useNavigate();

  const [error, setError] = useState<string>();
  const [selectedCategory, setSelectedCategory] = useState<CategoryInterface>();
  const [file, setFile] = useState<File>();
  const [foodPlan, setFoodPlan] = useState<FoodPlan>({} as FoodPlan);
  const [availableRecipes, setAvailableRecipes] =
    useState<CategoryInterface[]>();
  const [draggableComponents, setDraggableComponents] = useState<
    DraggableComponentInterface[]
  >([]);
  const [mealTypes, setMealTypes] = useState<MealType[]>();
  const [openModal, setOpenModal] = useState(false);

  const getFoodPlan = async () => {
    try {
      loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
      if (!location.state?.foodPlanId) return;
      const response = await getFoodPlanDetailsRequest(
        location.state.foodPlanId,
      );
      const foodPlan = fromFoodPlanResponseDTOToFoodPlanDetail(response);
      setFoodPlan(foodPlan);
      setSelectedCategory({
        id: foodPlan.isUniversal === IsUniversal.UNIVERSAL ? 1 : 2,
        label: foodPlan.isUniversal!,
        type: 'isUniversal',
      });

      const recipesHelper: CategoryInterface[] = [];
      if (location.state.mode === Mode.EDIT) {
        for (const item of foodPlan.selectedFoodRecipes) {
          recipesHelper.push({
            id: item?.recipe.id,
            label: item?.recipe.name,
            type: 'recipe',
          });
        }
      }
      await prepareForm(recipesHelper);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
    }
  };

  const getAllRecipes = async (chosenRecipes: CategoryInterface[]) => {
    const optionsHelper: CategoryInterface[] = [];
    try {
      loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
      const response = await getRecipesRequest();
      const recipes = fromFoodRecipeResponseDTOToFoodRecipe(response);
      recipes.forEach(recipe => {
        if (!chosenRecipes.find(component => component.id === recipe.id)) {
          optionsHelper.push({
            label: recipe.name,
            id: recipe.id,
            type: 'recipe',
            mealTypeId: recipe.mealType?.id!,
          });
        }
      });
      setAvailableRecipes(optionsHelper);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
    }
  };
  const prepareForm = async (chosenRecipes: CategoryInterface[]) => {
    await getAllMealTypes();
    await getAllRecipes(chosenRecipes);
  };
  const isValidForm =
    !!foodPlan.name &&
    !!foodPlan.description &&
    foodPlan.name.trim().length > 0 &&
    foodPlan.description.trim().length > 0 &&
    selectedCategory?.id !== -1 &&
    (!!file || !!foodPlan.pictureUrl);
  const insertOrUpdateFoodPlan = async () => {
    try {
      loaderHandler(loadingCtx, LoadingTypes.UPLOAD, true);
      if (!isFormValid(isValidForm)) return;

      const selectedRecipes: SelectedRecipeDTO[] = [];
      draggableComponents.forEach((component, index) => {
        selectedRecipes.push({
          [component.id]: index + 1,
          mealTypeId: component.mealTypeId!,
          day: component.day,
        });
      });
      if (selectedRecipes.length < 1) {
        toast.error('Morate izabrati recept.');
        return;
      }
      const formData = new FormData();
      formData.append('name', foodPlan.name!);
      formData.append('description', foodPlan.description);
      formData.append('fileTypes', '[{"type":"image"}]');
      formData.append('selectedFoodRecipes', JSON.stringify(selectedRecipes));
      formData.append('isUniversal', selectedCategory?.label!);
      formData.append('files', file!);
      if (location.state.mode) {
        switch (location.state.mode) {
          case 'ADD':
            await insertFoodPlanRequest(formData);
            toast.success('Plan ishrane je uspešno dodat.');
            navigate('/food');
            break;
          case 'EDIT':
            await updateFoodPlanRequest(location.state.foodPlanId, formData);
            toast.success('Plan ishrane je uspešno izmenjen.');
            navigate('/food');
            setOpenModal(false);
            break;
          default:
            break;
        }
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      loaderHandler(loadingCtx, LoadingTypes.UPLOAD, false);
    }
  };

  const getAllMealTypes = async () => {
    try {
      loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
      const response = await getMealTypesRequest();
      setMealTypes(fromMealTypeResponseDTOToMealType(response));
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
    }
  };
  const onSaveClickHandler = () => {
    if (!location.state) {
      return;
    }
    location.state.mode === 'ADD'
      ? insertOrUpdateFoodPlan()
      : setOpenModal(true);
  };

  const handleChange = (file: File) => {
    setFile(file);
    setError(undefined);
    setFoodPlan(prevState => {
      return (
        prevState && {
          ...prevState,
          pictureUrl: URL.createObjectURL(file),
        }
      );
    });
  };

  const handleFormChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    param: string,
  ) => {
    setFoodPlan((prevState: FoodPlan) => {
      return {
        ...prevState,
        [param]: event.target.value,
      };
    });
  };
  const handleSelectedCategoryChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: CategoryInterface | null,
  ) => {
    if (!value) {
      setSelectedCategory({id: -1, label: '', type: 'training'});
      return;
    }
    setSelectedCategory(value!);
  };

  useEffect(() => {
    if (location.state?.foodPlanId) {
      getFoodPlan();
    } else {
      prepareForm([]);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return !loadingCtx.isLoading.common ? (
    <div className={'add-food-card-container'}>
      {openModal && (
        <ConfirmationModal
          dispatchFunction={insertOrUpdateFoodPlan}
          closeModal={setOpenModal}
          mode={ConfirmationModalMode.EDIT}
        />
      )}
      <div className={'header-container'}>
        <BackArrow onClick={() => navigate('/food')} />
        <h1 className={'add-food-name'}>
          {location.state.mode === Mode.EDIT
            ? editFoodPlanTitle
            : addFoodPlanTitle}
        </h1>
      </div>
      <div className={'card-container'}>
        <h2 className={'add-food-subtitle'}>Osnovni podaci</h2>
        <div className={'form-container'}>
          <TextField
            label={'Naziv plana ishrane'}
            className={'form-text-input'}
            value={foodPlan.name ? foodPlan.name : ''}
            onChange={event => handleFormChange(event, 'name')}
          />
          <TextareaAutosize
            className={'text-area'}
            placeholder={'Opis'}
            value={foodPlan?.description}
            onChange={event => handleFormChange(event, 'description')}
          />
          {((selectedCategory && location.state.mode === 'EDIT') ||
            location.state.mode === 'ADD') && (
            <Autocomplete
              clearIcon={null}
              disablePortal
              defaultValue={selectedCategory}
              className={'autocomplete-box'}
              id="combo-box-demo"
              options={options}
              getOptionLabel={option => capitalizeLetters(option.label!)}
              onChange={handleSelectedCategoryChange}
              sx={{width: '30vw'}}
              renderInput={params => (
                <TextField {...params} label="Kategorija" />
              )}
            />
          )}
        </div>
      </div>
      {/* second form */}
      <div className={'small-form-container'}>
        <h2 className={'add-food-subtitle'}>Slika</h2>
        <div className={'file-uploader-container'}>
          <FileUploader
            handleChange={handleChange}
            name="file"
            label={'Dodaj ili prevuci sliku'}
            types={fileTypes}
            maxSize={10}
            onSizeError={(file: File) =>
              setError('Slika ne sme biti veća od 10mb.')
            }
          />

          {error && <p style={{color: 'red'}}>{error}</p>}
        </div>
      </div>
      {foodPlan?.pictureUrl && (
        <img className={'cover-image'} src={foodPlan.pictureUrl} alt={''} />
      )}
      {/* thrid form */}
      <div className={'small-form-container'}>
        <h2 className={'add-food-subtitle'}>Recepti</h2>
        {availableRecipes && mealTypes && (
          <DndProvider context={window} backend={HTML5Backend}>
            <DraggableContainer
              availableOptions={availableRecipes}
              type={Types.RECIPE}
              categories={foodPlan?.selectedFoodRecipes}
              draggableComponents={draggableComponents}
              setDraggableComponents={setDraggableComponents}
              mealTypes={mealTypes}
              isRecipe={true}
            />
          </DndProvider>
        )}
      </div>
      <div className={'button-container'}>
        <Button
          onClick={onSaveClickHandler}
          className={'button-primary'}
          disabled={isValidForm && loadingCtx.isLoading.upload}>
          {!loadingCtx.isLoading.upload
            ? saveFoodPlanTitle
            : uploadFoodPlanTitle}
        </Button>
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default AddFoodPlan;
