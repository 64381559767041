import {AxiosResponse} from 'axios';
import axiosInstance from './axios';
import {RestCodes} from '../../common/enums/common.enums';

export class HttpError {
  constructor(public response: AxiosResponse) {}
}

type HttpMethod = 'POST' | 'GET' | 'PUT' | 'DELETE';

async function makeRequest(
  method: HttpMethod,
  path: string,
  data?: Record<string, any>,
  hasResponseBody?: boolean,
  headers?: any,
) {
  const response = await axiosInstance({
    url: `${path}`,
    method,
    data,
    headers,
  });

  if (response.status >= RestCodes.BadRequest) {
    throw new HttpError(response);
  }

  return method === 'DELETE' || hasResponseBody ? response : response.data;
}

export function post<T = any>(
  path: string,
  data: Record<string, any>,
): Promise<T> {
  return makeRequest('POST', path, data);
}

export function put<T = any>(
  path: string,
  data: Record<string, any>,
): Promise<T> {
  return makeRequest('PUT', path, data);
}

export function getWithHeaders<T = any>(
  path: string,
  data?: Record<string, any>,
): Promise<T> {
  return data
    ? makeRequest('GET', path, data, true)
    : makeRequest('GET', path, undefined, true);
}

export function deleteReq<T = any>(path: string): Promise<T> {
  return makeRequest('DELETE', path);
}

export function get<T = any>(
  path: string,
  data?: Record<string, any>,
): Promise<T> {
  return data ? makeRequest('GET', path, data) : makeRequest('GET', path);
}

export function postMedia<T = any>(
  path: string,
  data: Record<string, any>,
): Promise<T> {
  return makeRequest('POST', path, data, false, {
    'Content-Type': 'multipart/form-data',
  });
}
