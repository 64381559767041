import '../../styles/PaySlipStyle.scss';

import {Button, TextField} from '@mui/material';
import React, {Dispatch, SetStateAction, useContext, useState} from 'react';
import {toast} from 'react-toastify';

import BackArrow from '../UI/BackArrow';
import {ClientSubscriptionInterface} from "../../common/models/subscription.interface";
import {updateSubscriptionAmountRequest} from "../../api/subscription.api";
import {UpdateSubscriptionAmountRequestDto} from "../../common/dtos/subscription.interface.dto";
import {loaderHandler} from "../../utility/Helpers";
import {LoaderContext} from "../../store/LoaderContex";
import {LoadingTypes} from "../../common/enums/common.enums";

const domtoimage = require("dom-to-image");

type PaySlipProps = {
    setClientSection: Dispatch<SetStateAction<string>>;
    clientFullName: string | undefined;
    clientId: number | undefined;
    subPackage: ClientSubscriptionInterface
    setSubPackage: Dispatch<SetStateAction<ClientSubscriptionInterface>>
    subscriptionId: number
};

const PaySlip: React.FC<PaySlipProps> = (props: PaySlipProps) => {
    const loadingCtx = useContext(LoaderContext);
    const generatePaySlipText = "Generiši uplatnicu";
    const saveGeneratePaySlipTitle = "Generišem uplatnicu...";
    const recipient = 'EMERGO TRENING DOO BEOGRAD';
    const paySlipPurposeOfPayment = 'Fit Hub pretplata - ';
    const [paySlipURL, setPaySlipURL] = useState<string>();
    const getMonthsString = props.subPackage.duration === 1 ? ' mesec' : props.subPackage.duration === 3 ? ' meseca' : ' meseci';

    const getReferenceNumber = (id?: number): string => {
        return id?.toString().padStart(7, '0') ?? '/';
    };

    const generatePaySlip = async () => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.UPLOAD, true);
            const node = document.getElementById("pay-slip");
            const domToImageResponse = await domtoimage.toPng(node);
            setPaySlipURL(domToImageResponse);
            const dto: UpdateSubscriptionAmountRequestDto = {amount: props.subPackage?.amount ? props.subPackage.amount : -1}
            await updateSubscriptionAmountRequest(props.clientId!, props.subscriptionId, dto);
        } catch (error: any) {
            toast.error("Greška prilikom generisanja uplatnice.");
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.UPLOAD, false);
        }
    };

    return (
        <div className={"client-card-container"}>
            <div className={"header-container"}>
                <div className={"page-name-container"}>
                    <BackArrow
                        onClick={() => {
                            props.setClientSection("edit-client");
                        }}
                    />
                    <h1 className={"client-name"}>Uplatnica</h1>
                </div>
            </div>
            <div className={"card-container"} id={"pay-slip"}>
                <div className={"form-container"}>
                    <div className={"pay-slip-input-fields-container"}>
                        {props.clientFullName && (
                            <TextField
                                label={"Uplatilac"}
                                disabled={true}
                                value={props.clientFullName}
                                className={"pay-slip-text-input-large"}
                            />
                        )}
                        <TextField
                            InputLabelProps={{shrink: true}}
                            label={"Svrha uplate"}
                            value={`${paySlipPurposeOfPayment}${props.subPackage.duration}${getMonthsString}`}
                            disabled={true}
                            className={"pay-slip-text-input-large"}
                        />
                        <TextField
                            InputLabelProps={{shrink: true}}
                            label={"Primalac"}
                            value={recipient}
                            disabled={true}
                            className={"pay-slip-text-input-large"}
                        />
                    </div>
                </div>
                <div className={"form-container"}>
                    <div className={"pay-slip-text-input-wrapper"}>
                        <TextField
                            InputLabelProps={{shrink: true}}
                            label={"Šifra plaćanja"}
                            value={"189"}
                            disabled={true}
                            className={"pay-slip-text-input-small"}
                        />
                        <TextField
                            label={"RSD"}
                            placeholder={"RSD"}
                            disabled={true}
                            className={"pay-slip-text-input-small"}
                        />
                        <TextField
                            label={"Iznos (RSD)"}
                            type={"number"}
                            value={props.subPackage?.amount ? props.subPackage.amount : ''}
                            onChange={(event) => props.setSubPackage({
                                ...props.subPackage,
                                amount: parseInt(event.currentTarget.value)
                            })}
                            className={"pay-slip-text-input-small"}
                        />
                    </div>
                    <TextField
                        InputLabelProps={{shrink: true}}
                        label={"Račun primaoca"}
                        value={"123-0004567890-12"}
                        disabled={true}
                        className={"pay-slip-text-input"}
                    />
                    <div className={"pay-slip-text-input-wrapper"}>
                        <TextField
                            InputLabelProps={{shrink: true}}
                            label={"Model"}
                            value={97}
                            disabled={true}
                            className={"pay-slip-text-input-medium"}
                        />
                        <TextField
                            InputLabelProps={{shrink: true}}
                            label={"Poziv na broj"}
                            value={getReferenceNumber(props.clientId)}
                            disabled={true}
                            className={"pay-slip-text-input-medium"}
                        />
                    </div>
                    <p className={"pay-slip-date"}>{new Date().toLocaleDateString()}</p>
                </div>
            </div>
            <div className={"button-container"}>
                <Button className={"button-primary"} onClick={() => generatePaySlip()}
                        disabled={loadingCtx.isLoading.upload}>
                    {!loadingCtx.isLoading.upload ? generatePaySlipText : saveGeneratePaySlipTitle}
                </Button>
            </div>
            {paySlipURL && <img src={paySlipURL} alt={"Payslip"}/>}
        </div>
    );
};

export default PaySlip;
