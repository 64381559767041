import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

import {getAllUsersRequest, getAllUsersWhoseSubscriptionExpiresRequest} from '../../api/user.api';
import {
    fromClientResponseDTOtoClientInterface,
} from '../../common/data-tansformers/input/userInputDataTransformer';
import {ClientInterface} from '../../common/models/user.interface';
import {dateTransformer, isWithinLastSevenDays, loaderHandler} from '../../utility/Helpers';
import ActionMenu from '../UI/ActionMenu';
import {noRowsLabel} from "../../common/constants/Constants";
import {LoaderContext} from "../../store/LoaderContex";
import Loader from "../Loader/Loader";
import {LoadingTypes} from "../../common/enums/common.enums";
import {ThemeConfigContext} from "../../store/ThemeConfigContext";


const Dashboard = () => {
    const loadingCtx = useContext(LoaderContext);
    const [clients, setClients] = useState<ClientInterface[]>();
    const [numberOfClients, setNumberOfClients] = useState<number>(-1);
    const [numberOfNewClients, setNumberOfNewClients] = useState<number>(0);
    const [clientsWhoseSubscriptionExpires, setClientsWhoseSubscriptionExpires] =
        useState<ClientInterface[]>([]);
    const [
        clientsWhoseDemoSubscriptionExpires,
        setClientsWhoseDemoSubscriptionExpires,
    ] = useState<ClientInterface[]>([]);
    const navigate = useNavigate();
    const configContext = useContext(ThemeConfigContext);
    const getImage = (params: any) => {
        return (
            <div className={"table-image"}>
                <img
                    className={"client"}
                    src={
                        params?.row?.imageURL
                            ? params.row.imageURL
                            : "/assets/avatar-img.png"
                    }
                    alt={"avatar"}
                />
            </div>
        );
    };

    const openChat = (chatId: number) => {
        navigate("/chat", {state: {chatId: chatId}});
    };

    const convertToActionMenu = (params: any) => {
        return (
            <ActionMenu openChat={openChat} isDashboard={true} params={params}/>
        );
    };
    const subscriptionColumns: GridColDef[] = [
        {
            field: "image",
            headerName: "SLIKA",
            flex: 0.4,
            renderCell: (params) => getImage(params),
            sortable: false
        },
        {
            field: "name",
            headerName: "IME I PREZIME",
            flex: 1,
        },
        {
            field: "package",
            headerName: "NAZIV PAKETA",
            flex: 1,
        },
        {
            field: "dateOfExpiration",
            headerName: "DATUM",
            flex: 0.5,
            renderCell: (param) => {
                return <h4>{dateTransformer(param.row.dateOfExpiration)}</h4>;
            },
        },
        {
            field: "action",
            headerName: "AKCIJE",
            flex: 0.3,
            sortable: false,
            headerAlign: "left",
            renderCell: (params) => convertToActionMenu(params),
        },
    ];
    const demoSubscriptionColumns: GridColDef[] = [
        {
            field: "image",
            headerName: "SLIKA",
            flex: 0.4,
            renderCell: (params) => getImage(params),
            sortable: false
        },
        {
            field: "name",
            headerName: "IME I PREZIME",
            flex: 1,
        },
        {
            field: "dateOfExpiration",
            headerName: "DATUM",
            flex: 0.5,
            renderCell: (param) => {
                return <h4>{dateTransformer(param.row.dateOfExpiration)}</h4>;
            },
        },
        {
            field: "action",
            headerName: "AKCIJE",
            flex: 0.3,
            sortable: false,
            headerAlign: "left",
            renderCell: (params) => convertToActionMenu(params),
        },
    ];

    const getAllUsersWhoseSubscriptionExpires = async () => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
            const response = await getAllUsersWhoseSubscriptionExpiresRequest();
            setClientsWhoseSubscriptionExpires(
                fromClientResponseDTOtoClientInterface(response)
            );
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
        }
    };
    const getAllUsersWhoseDemoSubscriptionExpires = async () => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
            const response = await getAllUsersWhoseSubscriptionExpiresRequest("demo");
            setClientsWhoseDemoSubscriptionExpires(
                fromClientResponseDTOtoClientInterface(response)
            );
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
        }
    };

    const getAllClients = async () => {
        try {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, true);
            const clientsResponse = await getAllUsersRequest();
            const transformedClients =
                fromClientResponseDTOtoClientInterface(clientsResponse);
            setClients(transformedClients);
            setNumberOfClients(transformedClients.length);
            for (const client of transformedClients) {
                if (isWithinLastSevenDays(new Date(client.registrationDate!))) {
                    setNumberOfNewClients((prevState) => prevState + 1);
                }
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        } finally {
            loaderHandler(loadingCtx, LoadingTypes.COMMON, false);
        }
    };

    useEffect(() => {
        getAllClients();
        getAllUsersWhoseDemoSubscriptionExpires();
        getAllUsersWhoseSubscriptionExpires();
    }, []);
    return (
        <>
            <Container maxWidth={false} sx={{mt: 4, mb: 4, padding: 0}}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                            {clients && (
                                <div className={"basic-info-row"}>
                                    <div className={"basic-info-container"}>
                                        <img
                                            src={"/assets/hand.png"}
                                            className={"info-icon"}
                                            alt={""}
                                        />
                                        <div className={"info-text-container"}>
                                            <p className={"info-text"}>Ukupan broj korisnika</p>
                                            <h2>{numberOfClients}</h2>
                                        </div>
                                    </div>
                                    <div className={"basic-info-container"}>
                                        <img
                                            src={"/assets/star.png"}
                                            className={"info-icon"}
                                            alt={""}
                                        />
                                        <div className={"info-text-container"}>
                                            <p className={"info-text"}>Broj novih korisnika</p>
                                            <h2>{numberOfNewClients}</h2>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!loadingCtx.isLoading.common ? (<div className={"tables-container"}>
                                <div className={"clients-table-container"}>
                                    <div className={"title-container"}>
                                        <p className={"table-title"}>Korisnici kojima ističe</p>
                                        <h3 style={{color: configContext.userConfig.packageTitleColor}}>Pretplata na
                                            paket</h3>
                                    </div>
                                    {clientsWhoseSubscriptionExpires && (
                                        <div className={"data-grid-container"}>
                                            <DataGrid
                                                localeText={{
                                                    noRowsLabel: noRowsLabel
                                                }}
                                                columns={subscriptionColumns}
                                                rows={clientsWhoseSubscriptionExpires}
                                                disableSelectionOnClick={true}
                                                rowHeight={70}
                                                pageSize={20}
                                                checkboxSelection={false}
                                                disableColumnMenu={true}
                                                onRowClick={(row) => {
                                                    navigate("/clients", {
                                                        state: {clientId: row.id},
                                                    });
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className={"clients-table-container"}>
                                    <div className={"title-container"}>
                                        <p className={"table-title"}>Korisnici kojima ističe</p>
                                        <h3 style={{color: configContext.userConfig.packageTitleColor}}>Demo
                                            verzija</h3>
                                    </div>
                                    {clientsWhoseDemoSubscriptionExpires && (
                                        <div className={"data-grid-container"}>
                                            <DataGrid
                                                localeText={{
                                                    noRowsLabel: noRowsLabel
                                                }}
                                                columns={demoSubscriptionColumns}
                                                rows={clientsWhoseDemoSubscriptionExpires}
                                                disableSelectionOnClick={true}
                                                rowHeight={70}
                                                pageSize={20}
                                                checkboxSelection={false}
                                                disableColumnMenu={true}
                                                onRowClick={(row) => {
                                                    navigate("/clients", {
                                                        state: {clientId: row.id},
                                                    });
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>) : <Loader/>}
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};
export default Dashboard;
