import React, {Dispatch, SetStateAction} from "react";
import {createPortal} from "react-dom";
import '../../styles/ConfirmationModal.scss';

type ConfirmationModalProps = {
    dispatchFunction?: () => void;
    closeModal: Dispatch<SetStateAction<boolean>>;
    mode: string;
}

type BackdropProps = {
    onClick?: () => void
}
const Backdrop: React.FC<BackdropProps> = (props: BackdropProps) => {
    return <div className={'backdrop'} onClick={props.onClick}/>
};

const portalElement = document.getElementById('overlays')!;

const ConfirmationModal: React.FC<ConfirmationModalProps> = (props: ConfirmationModalProps) => {

    return <>
        {createPortal(<Backdrop onClick={() => props.closeModal(false)}/>, portalElement)}
            <div className={'modal confirmation-modal'}>
                <p>{`Da li ste sigurni da želite da ${props.mode === 'EDIT' ? 'izmenite' : 'obrišete'} izabranu stavku?`}</p>
                <div className={'modal-button-container'}>
                    <button className={"button-primary"} onClick={props.dispatchFunction}>Da</button>
                    <button className={"button-primary"} onClick={() => props.closeModal(false)}>Otkaži</button>
                </div>
            </div>
    </>
}

export default ConfirmationModal;