export enum RestCodes {
    Ok = 200,
    Success = 201,
    BadRequest = 400,
    NotAuthorized = 401,
    Forbidden = 403,
    NotFound = 404,
}

export enum NotificationType {
    NEW_MESSAGE = 'NEW_MESSAGE',
    NEW_FOOD_PLAN_ADDED = 'NEW_FOOD_PLAN_ADDED',
    REMINDER = 'REMINDER',
    USER_BIRTHDAY = 'USER_BIRTHDAY',
    SUBSCRIPTION_EXPIRATION = 'SUBSCRIPTION_EXPIRATION',
    DEMO_SUBSCRIPTION_EXPIRATION = 'DEMO_SUBSCRIPTION_EXPIRATION',
    USER_REGISTRATION = 'USER_REGISTRATION',
}

export enum UserRoles {
    ADMIN = "ADMIN",
    USER = "USER"
}

export enum LoadingTypes {
    CHAT = 'chat',
    COMMON = 'common',
    MESSAGE = 'message',
    UPLOAD = 'upload',
    CLIENT_PROGRESS = 'clientProgress',
    MOBILE_MESSAGES = 'mobileMessages',
    LOAD_MESSAGES = 'loadMessages'
}

export enum ConfirmationModalMode {
    EDIT = 'EDIT',
    DELETE = 'DELETE'
}